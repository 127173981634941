import { Button } from "@components/button/button";
import Heading from "@components/font/heading";
import { TextField } from "@components/form/textField";
import { theme } from "@styles/themes";
import React from "react";
import { useForm } from "react-hook-form";


interface ArchieveProfileModalProps {
  setArchieveProfileOpen: (open: boolean) => void;
  handleArchieveBtn?: () => void;
}

const ArchieveProfileModal = ({ setArchieveProfileOpen, handleArchieveBtn }: ArchieveProfileModalProps) => {
  return (
    <div>
      <Heading
        title="If you archive this profile all their bookies will be also archived. Do you want to continue? "
        fontSize="14px"
        fontWeight="400"
        color={theme.colors.white.normal}
      />

      <div style={{ textAlign: "right", marginTop: "24px" }}>
        <Button
          style={{
            borderRadius: "8px",
            fontWeight: "700",
            fontSize: "14px",
          }}
          outline
          onClick={() => setArchieveProfileOpen(false)}
          title="addEditUser.cancelButton"
          size="md"
        />
        <Button
          style={{
            borderRadius: "8px",
            fontWeight: "700",
            fontSize: "14px",
            marginLeft: "12px",
          }}
          onClick={() => handleArchieveBtn()}
          title="profile.archieve"
          size="md"
        />
      </div>
    </div>
  );
};

export default ArchieveProfileModal;
