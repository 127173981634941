import CountryCodePicker from "@components/form/phoneInput";
import { TextField } from "@components/form/textField";
import { theme } from "@styles/themes";
import { countryCodes } from "@utils/countries";
import React from "react";
import { Col, Row } from "react-bootstrap";

const StepOne = ({ control, setValue, errors }: any) => {
  return (
    <div>
      <Row className="g-2">
        <Col md={6} sm={12} xs={12}>
          <TextField
            control={control}
            name="firstName"
            bgColor={theme.colors.grey.dark}
            size="sm"
            placeholder="Name*"
            errors={errors.firstName}
            rules={{
              required: "This field is required",
              pattern: {
                value: /^[A-Za-zÁÉÍÓÚáéíóúÀÈÌÒÙàèìòùÄËÏÖÜäëïöüÇçÑñ]+(\s[A-Za-zÁÉÍÓÚáéíóúÀÈÌÒÙàèìòùÄËÏÖÜäëïöüÇçÑñ]+)*$/,
                message: "Must contain only Latin letters and single spaces between names"
              }
            }}
          />
        </Col>
        <Col md={6} sm={12} xs={12}>
          <TextField
            control={control}
            name="lastName"
            bgColor={theme.colors.grey.dark}
            size="sm"
            placeholder="Last name*"
            errors={errors.lastName}
            rules={{
              required: "This field is required",
              pattern: {
                value: /^[A-Za-zÁÉÍÓÚáéíóúÀÈÌÒÙàèìòùÄËÏÖÜäëïöüÇçÑñ]+(\s[A-Za-zÁÉÍÓÚáéíóúÀÈÌÒÙàèìòùÄËÏÖÜäëïöüÇçÑñ]+)*$/,
                message: "Must contain only Latin letters and single spaces between names"
              }
            }}
          />
        </Col>

        <Col md={12} sm={12} xs={12}>
          <TextField
            control={control}
            name="email"
            bgColor={theme.colors.grey.dark}
            size="sm"
            placeholder="Email*"
            errors={errors.email}
            rules={{
              required: "This field is required",
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: "Please enter a valid email address",
              },
            }}
          />
        </Col>
        <Col md={3} sm={3} xs={3} className="d-flex align-items-start">
          <CountryCodePicker
            bgColor={theme.colors.grey.dark}
            size="sm"
            options={countryCodes}
            placeholder="Prefix"
            control={control}
            name="phonePrefix"
            setValue={setValue}
            errors={errors.phonePrefix}
            rules={{ required: true }}
          />
        </Col>
        <Col md={9} sm={9} xs={9} >
          <TextField
            control={control}
            name="phoneNumber"
            bgColor={theme.colors.grey.dark}
            size="sm"
            placeholder="Phone number"
            errors={errors.phoneNumber}
            rules={{
              required: "This field is required",
              pattern: {
                value: /^[0-9]+$/, // Only allows digits
                message: "Phone number must contain only numbers",
              },
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default StepOne;
