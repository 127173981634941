import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { theme } from "@styles/themes";
import { Button } from "@components/button/button";
import Heading from "@components/font/heading";
import { TabContext } from "@context/TabContext";
import { usePayment } from "@context/PaymentContext";
import PaymentModal from "@components/modal/PaymentModal";
import DashLine from "@assets/payments/dashLine.svg";
import Secure3DModal from "@components/modal/Secure3DModal";
import { paymentMethods } from "@utils/payments";
import { useTranslation } from "react-i18next";
import { useGetPaymentSummary } from "@api/useGetPaymentSummary";

const StyledDiv = styled.div`
  background-color: ${theme.colors.grey.darker};
  border: 1px solid ${theme.colors.white.dark};
  padding: 40px 24px 24px 24px;
  border-radius: 8px;
  position: sticky;
  top: 90px;
  max-width: 300px;

  @media (max-width: 1200px) {
    display: none;
  }
`;

const HeadingContainer = styled.div`
  margin-bottom: 8px;
`;

const TotalContainer = styled.div`
  text-align: right;
  margin-bottom: 16px;
`;
const TotalContainerBottom = styled.div`
  text-align: left;
`;
const DashLineContainer = styled.div`
  margin: 16px 0;
  overflow: hidden;
`;

const ButtonStyled = styled(Button)`
  font-size: 14px;
  font-weight: 700;
  border-radius: 8px;
  width: 100%;
  max-width: 448px;
`;

const BottomStyledDiv = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 12.5px 16px;
  background-color: ${({ theme }) => theme.colors.grey.darker};
  border-top: 0.5px solid ${({ theme }) => theme.colors.white.dark};

  @media (max-width: 1200px) and (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 767px), (min-width: 1201px) {
    display: none;
  }
`;

const CreditDetailCard = ({ submitForm }: any) => {
  const navigate = useNavigate();
  const {
    startPayment,
    isPaymentFailed,
    isPaymentSuccess,
    resetPayment,
    redsysResponse,
  } = usePayment();
  const tabContext = useContext(TabContext);
  const { t } = useTranslation();
  const {
    credit,
    amount,
    convertToCredit,
    convertToCurrencyAmount,
    isLoading,
    paymentMethod: selectedPaymentMethod,
    challengeStatus,
    setChallengeStatus,
    resetRedsysResponse,
  } = usePayment();
  const { refetch } = useGetPaymentSummary();

  const { activeTab, leftPosition, setActiveTab } = tabContext;

  const handleClick = () => {
    switch (activeTab) {
      case 1:
        selectedPaymentMethod === paymentMethods.bankTransfer &&
          setActiveTab(2);
        break;
      case 2:
        startPayment();
        break;
      default:
        submitForm();
        break;
    }
  };

  const onClose = () => {
    resetPayment();
    refetch();
    setActiveTab(0);
    navigate("/payments");
  };

  const handleEditCreditCard = () => {
    resetPayment();
    setActiveTab(1);
  };

  const handleCompleteChallenge = () => {
    resetRedsysResponse();
    setChallengeStatus("success");
  };

  const handleFailedChallenge = () => {
    resetRedsysResponse();
    setChallengeStatus("error");
  };

  return (
    <>
      <StyledDiv>
        <div style={{ borderRadius: "8px" }}>
          <HeadingContainer>
            <Heading
              title="You get"
              fontSize="14px"
              fontWeight="400"
              color={theme.colors.white.normalActive}
            />
          </HeadingContainer>
          <HeadingContainer>
            <Heading
              title={`${credit} credits`}
              fontSize="20px"
              fontWeight="700"
              color={theme.colors.white.normal}
            />
          </HeadingContainer>
          <Heading
            title={`${convertToCurrencyAmount(1)} = ${convertToCredit(
              1
            )} credits`}
            fontSize="14px"
            fontWeight="400"
            color={theme.colors.white.normal}
          />
        </div>
        <DashLineContainer>
          <img src={DashLine} alt="Dashed line" />
        </DashLineContainer>
        <TotalContainer>
          <Heading
            title="Total"
            fontSize="12px"
            fontWeight="400"
            color={theme.colors.white.dark}
          />
          <Heading
            style={{ marginTop: "2px" }}
            title={convertToCurrencyAmount(amount)}
            fontSize="20px"
            fontWeight="700"
            color={theme.colors.white.normal}
          />
        </TotalContainer>
        <ButtonStyled
          title={activeTab == 2 ? "payments.pay" : "payments.continue"}
          disabled={
            activeTab == 1 &&
            selectedPaymentMethod === paymentMethods.creditCard
          }
          isLoading={isLoading}
          onClick={handleClick}
        />

        <PaymentModal
          open={
            ((isPaymentSuccess || isPaymentFailed) &&
              !redsysResponse?.data?.Ds_EMV3DS?.acsURL) ||
            challengeStatus !== null
          }
          type={
            challengeStatus === "success"
              ? "success"
              : challengeStatus === "error"
              ? "error"
              : isPaymentSuccess
              ? "success"
              : "error"
          }
          onActionClick={
            isPaymentSuccess
              ? onClose
              : challengeStatus === "success"
              ? onClose
              : handleEditCreditCard
          }
          onClose={onClose}
          actionBtnTitle={
            isPaymentSuccess
              ? t("payments.investInBookies")
              : challengeStatus === "success"
              ? t("payments.investInBookies")
              : t("payments.editCreditCard")
          }
        />

        <Secure3DModal
          open={!!redsysResponse?.data?.Ds_EMV3DS?.acsURL}
          onClose={resetRedsysResponse}
          onComplete={handleCompleteChallenge}
          onFailed={handleFailedChallenge}
          acsURL={redsysResponse?.data?.Ds_EMV3DS?.acsURL}
          creq={redsysResponse?.data?.Ds_EMV3DS?.creq}
        />
      </StyledDiv>
      <BottomStyledDiv style={{ left: leftPosition }}>
        <TotalContainerBottom>
          <Heading
            title="Total"
            fontSize="12px"
            fontWeight="400"
            color={theme.colors.white.dark}
          />
          <Heading
            style={{ marginTop: "2px" }}
            title={convertToCurrencyAmount(amount)}
            fontSize="20px"
            fontWeight="700"
            color={theme.colors.white.normal}
          />
        </TotalContainerBottom>
        <ButtonStyled
          title={activeTab == 2 ? "payments.pay" : "payments.continue"}
          onClick={handleClick}
          isLoading={isLoading}
          disabled={
            activeTab == 1 &&
            selectedPaymentMethod === paymentMethods.creditCard
          }
        />
      </BottomStyledDiv>
    </>
  );
};

export default CreditDetailCard;
