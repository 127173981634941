import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { theme } from "@styles/themes";
import Heading from "@components/font/heading";

const ToastContainer = styled.div<{ isExiting: boolean,isOpen:boolean }>`
  display: flex;
  position: fixed;
  bottom: 100px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  align-items: center;
  max-width: 348px;
  border-left: 3px solid ${theme.colors.green.dark};
  border-radius: 8px;
  background-color: ${theme.colors.green.lightActive};
  padding: 6px 12px;
  gap: 8px;
  z-index: 1000;
  
  transition: transform 0.3s ease-out;
  transform: translateX(${props => props.isExiting ? '150%' : props.isOpen ? '0' : '-150%'});
`;

const MessageText = styled.div`
  flex: 1;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  color: ${theme.colors.green.dark};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.8;
  }
`;

interface CustomToastProps {
  message: string;
  onClose: () => void;
  isOpen: boolean;
}

const CustomToast = ({ message, onClose, isOpen }: CustomToastProps) => {
  const [isExiting, setIsExiting] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsExiting(false); // Reset exit state when opening
      const timer = setTimeout(() => {
        setIsExiting(true);
        setTimeout(onClose, 300);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [isOpen, onClose]);

  return isOpen ? (
    <ToastContainer isExiting={isExiting} isOpen={isOpen}>
      <Icon
        icon="mdi:tick-circle-outline"
        color={theme.colors.green.dark}
        style={{ fontSize: "20px" }}
      />
      <MessageText>
        <Heading
          title={message}
          fontSize="14px"
          fontWeight="700"
          color={theme.colors.green.dark}
        />
      </MessageText>
      <CloseButton onClick={onClose}>
        <Icon icon="charm:cross" style={{ fontSize: "24px" }} />
      </CloseButton>
    </ToastContainer>
  ) : null;
};

export default CustomToast;
