// libraries
import { useMutation } from "react-query";
import { AxiosError } from "axios";

// misc
import request from "@utils/request";
import { AddProfileParams, ServerResponse } from "./types";
import { useErrorHandler } from "@hooks/useErrorHandler";



export type AddProfileResponse = ServerResponse<unknown>; // Update this type if you know the specific response structure

export const useAddProfile = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (res: unknown) => void; // Update this type if you know the specific success response structure
  onError?: (error: AxiosError<ServerResponse<unknown>>) => void;
}) => {
  // variables
  const { triggerError } = useErrorHandler();

  // mutation
  const mutation = useMutation<unknown, AxiosError<ServerResponse<unknown>>, AddProfileParams>(
    async (params: AddProfileParams) => {
      try {
        const response = await request<AddProfileResponse>({
          method: "post",
          url: "/profiles",
          data: params,
        });

        const data = response.data.data;
        onSuccess && onSuccess(data);
        return data;
      } catch (error) {
        onError && onError(error);
        triggerError({ error });
        throw error;
      }
    }
  );

  // return
  return mutation;
};
