/* eslint-disable @typescript-eslint/no-empty-function */
// libraries
import React, { createContext, useContext, useReducer } from "react";

// types

interface DefaultValue {
  toggleSidebar(): void;
  isSidebarOpen: boolean;
}

const defaultValue: DefaultValue = {
  toggleSidebar: () => null,
  isSidebarOpen: false,
};

// context
const SidebarContext = createContext(defaultValue);

export const SidebarProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  // Sidebar variable
  const [isSidebarOpen, toggleSidebar] = useReducer(
    (oldValue) => !oldValue,
    true
  );

  // Sidebar provider
  return (
    <SidebarContext.Provider
      value={{
        isSidebarOpen,
        toggleSidebar,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => useContext(SidebarContext);
