import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  border: 1px solid ${theme.colors.grey.normal};
  background-color: ${theme.colors.grey.darker};
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  @media (min-width: 767px) {
    border: none;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const DesktopBookieChartDetail = () => {
  return (
    <Container>
      <Row>
        <Heading
          title="profile.accounts"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
        <Heading
          title="2"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
      </Row>
      <Row>
        <Heading
          title="profile.totalProfit"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
        <Heading
          title="2"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
      </Row>
      <Row>
        <Heading
          title="profile.averageProfit"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
        <Heading
          title="2"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
      </Row>
      <Row>
        <Heading
          title="profile.bets"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
        <Heading
          title="2"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
      </Row>
      <Row>
        <Heading
          title="profile.roi"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
        <Heading
          title="2"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
      </Row>
    </Container>
  );
};

export default DesktopBookieChartDetail;
