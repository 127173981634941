import Heading from '@components/font/heading';
import { Icon } from '@iconify/react';
import { theme } from '@styles/themes';
import React from 'react'

const BookieChartFilter = () => {
  return (
    <div style={{display:"flex", alignItems:"center",justifyContent:"flex-end", gap:12}}>
      <Heading
        title="profile.cleanFilters"
        fontSize="14px"
        fontWeight="400"
        style={{cursor:"pointer",textDecoration:"underline"}}
        color={theme.colors.white.normal}
      />
      <Icon icon="charm:filter" color={theme.colors.white.normal} width={20} height={20}/>
    </div>
  );
}

export default BookieChartFilter
