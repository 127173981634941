// libraries
import { useMutation } from "react-query";
import { AxiosError } from "axios";

// misc
import request from "@utils/request";
import { ServerResponse, UpdateBookiesParams } from "./types";
import { useErrorHandler } from "@hooks/useErrorHandler";

interface Data {
  bookie: Bookie;
}
export interface Bookie {
  bookie: string;
  isEnabled: boolean;
  accountId: string;
}

export type BookieArray = Bookie[];
export type UpdateBookiesResponse = ServerResponse<Data>; // Updated to use Data type

export const useUpdateBookiesMultiple = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (res: Data) => void; // Updated to use Data type
  onError?: (error: AxiosError<ServerResponse<unknown>>) => void;
}) => {
  // variables
  const { triggerError } = useErrorHandler();

  // mutation
  const mutation = useMutation<Data, AxiosError<ServerResponse<unknown>>, BookieArray>(
    async (formData: BookieArray) => {
      try {
        const response = await request<UpdateBookiesResponse>({
          method: "patch",
          url: `/bookies/multiple`,
          data: formData,
        });

        const data = response.data as any; // Cast to Data
        onSuccess && onSuccess(data);
        return data;
      } catch (error) {
        onError && onError(error);
        triggerError({ error });
        throw error;
      }
    }
  );

  // return
  return mutation;
};
