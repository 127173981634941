import Heading from "@components/font/heading";
import LogOutModal from "@components/modal/LogOutModal";
import { useAuth } from "@context/AuthContext";
import { Icon } from "@iconify/react";
import { theme } from "@styles/themes";
import React from "react";
import { Button, Offcanvas } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const drawerMenu = [
  {
    title: "My Account",
    titleIcon: "heroicons-outline:user",
    rightArrow: "mingcute:right-line",
    link: "/profile/mydata",
  },
  {
    title: "Billing details",
    titleIcon: "heroicons-outline:document-text",
    rightArrow: "mingcute:right-line",
    link: "/profile/billingdetails",
  },
  {
    title: "Settings",
    titleIcon: "weui:setting-outlined",
    rightArrow: "mingcute:right-line",
    link: "/profile/setting",
  },
  {
    title: "Contact Us",
    titleIcon: "material-symbols:mail-outline",
    rightArrow: "mingcute:right-line",
    link: "/profile/contactus",
  },
  {
    title: "Log Out",
    titleIcon: "material-symbols:logout",
    rightArrow: "",
    link: "logOut",
  },
];
const DrawerMobile = ({ setShow, show }: any) => {
  const handleClose = () => setShow(false);
  const navigate=useNavigate();
  const { resetAuth } = useAuth();

  const [logOut, setLogOut] = React.useState<boolean>(false); // Track the selected option
  const handleClickLogout = () => {
    resetAuth();
  };
  const handleNavigate=(elem:string)=>{
    if(elem =='logOut'){
      setLogOut(true)
    }else{
      navigate(elem)
    }
  }
  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        style={{ background: theme.colors.grey.darker }}
      >
        <Offcanvas.Header
          className="pt-2 pb-2"
          style={{ borderBottom: `0.5px solid ${theme.colors.white.darker}` }}
        >
          <div className="d-flex justify-content-between align-items-center w-100">
            <div>
              <Heading
                fontSize="20px"
                title="dashboard.menu"
                fontWeight="700"
                color={theme.colors.white.normal}
              />
            </div>
            <div>
              <Icon
                onClick={handleClose}
                style={{ color: theme.colors.white.normal }}
                icon="bitcoin-icons:cross-filled"
                width="24"
                height="24"
              />
            </div>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {drawerMenu?.map((elem, index) => {
            const isLogout = elem?.title === "Log Out";
            return (
              <div
              onClick={()=>handleNavigate(elem.link)}
                className="d-flex align-items-center justify-content-between p-3"
                style={{
                  cursor:'pointer',
                  
                  borderBottom: `0.5px solid ${theme.colors.grey.normal}`,
                }}
              >
                <div className="d-flex align-items-center">
                  <Icon
                    style={{
                      color: isLogout
                        ? theme.colors.red.normal
                        : theme.colors.white.normal,
                    }}
                    icon={elem?.titleIcon}
                    width="24"
                    height="24"
                    className="me-2"
                  />
                  <Heading
                    fontSize="14px"
                    title={elem?.title}
                    fontWeight="700"
                    color={
                      isLogout
                        ? theme.colors.red.normal
                        : theme.colors.white.normal
                    }
                  />
                </div>
                <div>
                  <Icon
                    style={{ color: theme.colors.white.normal }}
                    icon={elem?.rightArrow}
                    width="24"
                    height="24"
                  />
                </div>
              </div>
            );
          })}
        </Offcanvas.Body>
        <LogOutModal
        open={logOut}
        onActionClick={() => handleClickLogout()}
        onClose={() => setLogOut(false)}
      />
      </Offcanvas>
    </>
  );
};

export default DrawerMobile;
