import { usePostContactUs } from "@api/usePostContactUs";
import { Button } from "@components/button/button";
import { TextField } from "@components/form/textField";
import { theme } from "@styles/themes";
import React from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

const FooterWrapper = styled.div`
  position: fixed;
  border-top: 0.5px solid ${(props) => props.theme.colors.white.dark};
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${(props) => props.theme.colors.grey.darker};
  text-align: center;
  padding: 14px 16px;
`;
interface ContactUsForm {
  fullName: string;
  email: string;
  message: string;
}

interface ContactUsModalProps {
  setContactOpen?: (open: boolean) => void; // Make this prop optional
}
const ContactUsModal: React.FC<ContactUsModalProps> = ({ setContactOpen }) => {
  const { mutate } = usePostContactUs();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<ContactUsForm>();
  const onSubmit = (data: ContactUsForm) => {
    mutate(data, {
      onSuccess: () => {
        if (location?.pathname == "/profile/contactus") {
          navigate(-1);
        }
        setContactOpen(false);
      },
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        control={control}
        name="fullName"
        style={{ margin: "16px 0" }}
        bgColor={theme.colors.grey.dark}
        size="sm"
        placeholder="addEditUser.nameLabel"
        errors={errors.fullName}
        rules={{ required: "This field is required" }}
      />
      <TextField
        control={control}
        style={{ marginBottom: "16px" }}
        name="email"
        bgColor={theme.colors.grey.dark}
        size="sm"
        placeholder="addEditUser.emailLabel"
        errors={errors.email}
        rules={{
          required: "This field is required",
          pattern: {
            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            message: "Please enter a valid email address",
          },
        }}
      />
      <TextField
        control={control}
        name="message"
        bgColor={theme.colors.grey.dark}
        size="sm"
        multiline={true}
        lines={6}
        placeholder="How can we help you? Type here your message*..."
        errors={errors.message}
        rules={{ required: "This field is required" }}
      />
      <div
        style={{ textAlign: "right", marginTop: "24px" }}
        className="d-none d-sm-block"
      >
        <Button
          style={{
            borderRadius: "8px",
            fontWeight: "700",
            fontSize: "14px",
          }}
          outline
          onClick={() => setContactOpen(false)}
          title="actions.close"
          size="md"
        />
        <Button
          style={{
            borderRadius: "8px",
            fontWeight: "700",
            fontSize: "14px",
            marginLeft: "12px",
          }}
          type="submit"
          title="actions.send"
          size="md"
        />
      </div>
      <FooterWrapper className="d-block d-sm-none">
        <Button
          style={{
            width: "100%",
            borderRadius: "8px",
            fontWeight: "700",
            fontSize: "14px",
          }}
          type="submit"
          title="actions.send"
          size="md"
        />
      </FooterWrapper>
    </form>
  );
};

export default ContactUsModal;
