import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import React from "react";

const AvailableCredit = ({
  credits,
  bgColor,
  title = "Available credits",
  titleSize='16px'
}: any) => {
  return (
    <div
      style={{ backgroundColor: bgColor, padding: "16px", borderRadius: "8px" }}
    >
      <div style={{ marginBottom: "8px" }}>
        <Heading
          title={title}
          fontSize={titleSize}
          fontWeight="400"
          color={theme.colors.white.normalActive}
        />
      </div>
      <Heading
        title={credits}
        fontSize="24px"
        fontWeight="700"
        color={theme.colors.white.normal}
      />
    </div>
  );
};

export default AvailableCredit;
