import Heading from "@components/font/heading";
import { Icon } from "@iconify/react";
import { theme } from "@styles/themes";
import { SwipeableListItem, Type } from "react-swipeable-list";
import { SwipeableList } from "react-swipeable-list";
import React from "react";
import { SwipeAction } from "react-swipeable-list";
import { TrailingActions } from "react-swipeable-list";
import styled from "styled-components";
import { useCleanNotification } from "@api/useCleanAllNotifications";

const Card = styled.div<{ isToday: boolean }>`
  background-color: ${({ isToday }) =>
    isToday ? theme.colors.grey.darkActive : theme.colors.grey.darker};
  border-bottom: 1px solid ${theme.colors.grey.dark};
  padding: 16px;
  width: 100%;
  position: relative;

  &:hover {
    .delete-icon {
      display: block;
    }
  }
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  margin-right: 10px;
  background-color: ${theme.colors.main.normal};
  border-radius: 50%;
`;

const DeleteIcon = styled.div`
  display: none;
  cursor: pointer;
`;

interface NotificationCardProps {
  title?: string;
  date?: string;
  content?: string;
  notifyId?: string;
  isIncidence?: string;
}

const NotificationCardDesktop: React.FC<NotificationCardProps> = ({
  title,
  date,
  content,
  notifyId,
  isIncidence,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const isContentLong = content?.split(" ")?.length > 10;

  const { mutate } = useCleanNotification();
  const handleDelete = (notifyId: string) => {
    mutate(notifyId);
  };
  const today = new Date();
  const formattedDate = today.toLocaleDateString("en-GB");
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const formattedYesterday = yesterday.toLocaleDateString("en-GB");
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card isToday={date == "Today" ? true : false}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <Dot />
          <Heading
            title={`${title} ${isIncidence ? "⚠️" : ""}`}
            fontSize="14px"
            fontWeight="700"
            color={theme.colors.white.normal}
          />
        </div>
        <Heading
          title={
            date === formattedDate
              ? "Today"
              : date === formattedYesterday
              ? "Yesterday"
              : date
          }
          fontSize="12px"
          fontWeight="400"
          color={theme.colors.white.dark}
        />
      </div>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <StyledHeading
            title={isExpanded ? content : `${content?.substring(0, 100)}...`}
            fontSize="14px"
            fontWeight="400"
            color={theme.colors.white.dark}
            isExpanded={isExpanded}
          />
          <DeleteIcon
            className="delete-icon"
            onClick={() => handleDelete(notifyId)}
          >
            <Icon
              width={18}
              height={18}
              style={{
                color: theme.colors.white.normal,
              }}
              icon="mingcute:delete-2-line"
            />
          </DeleteIcon>
        </div>

        {isContentLong && (
          <Heading
            title={isExpanded ? "See less" : "See more"}
            fontSize="12px"
            fontWeight="400"
            color={theme.colors.white.dark}
            style={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={toggleExpand}
          />
        )}
      </div>
    </Card>
  );
};

const StyledHeading = styled(Heading)<{ isExpanded: boolean }>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: ${({ isExpanded }) => (isExpanded ? "none" : "2")};
  line-height: 19px;
`;

export default NotificationCardDesktop;
