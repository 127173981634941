// RegistrationForm.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import inbetiaLogo from "../../assets/logo.svg";
import PasswordInput2 from "../../components/passwordInput2";

import "./RegisterClient.css";
import { useNavigate } from "react-router-dom";
import SignUpSection from "../../section/signUpSection";

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    firstName: "",
    lastName: "",
    termsConditions: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    console.log("Check token is still available");
  }, []);

  const handleChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const queryParameters = new URLSearchParams(window.location.search);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${queryParameters.get("token")}`;

    axios
      .post(
        "https://api.inbetia.com/v1/register",
        { formData },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        navigate("/");
      })
      .catch((error) => {
        console.error("Error:", error);
        return;
      });
  };
  return (
    <SignUpSection/>
    // <div className="registration">
    //   <div className="registration_inner">
    //     <div className="logoIconContainer">
    //       <img src={inbetiaLogo} alt="Logo" />
    //     </div>
    //     <h2>Welcome to INBETIA</h2>
    //     <form id="addUserForm" className="registration-form" onSubmit={handleSubmit}>
    //       <div className="input-group-container">
    //         <div className="input-container">
    //           <input
    //             type="text"
    //             name="user"
    //             value={formData.user}
    //             onChange={handleChange}
    //             placeholder="Username"
    //             required
    //           />
    //         </div>
    //         <div className="input-container">
    //           <div className="password-container">
    //             <PasswordInput2
    //               value={formData.password}
    //               onChange={handleChange}
    //             ></PasswordInput2>              </div>
    //         </div>
    //       </div>
    //       <div className="input-group-container">
    //         <div className="input-container-small">
    //           <input
    //             type="text"
    //             name="firstName"
    //             value={formData.firstName}
    //             onChange={handleChange}
    //             placeholder="First Name"
    //             required
    //           />
    //         </div>
    //         <div className="input-container-small">
    //           <input
    //             type="text"
    //             name="lastName"
    //             value={formData.lastName}
    //             onChange={handleChange}
    //             placeholder="Last Name"
    //             required
    //           />
    //         </div>
    //       </div>
    //       <div className="input-container-terms">
    //         <input
    //           type="checkbox"
    //           id="termsAndConditions"
    //           name="termsConditions"
    //           checked={formData.termsConditions}
    //           onChange={handleChange}
    //           required
    //         />
    //         <label htmlFor="termsAndConditions">
    //           By creating an account on our platform, you accept our{' '}
    //           <a href="https://www.inbetia.com/terms-and-conditions" target="_blank" rel="noopener noreferrer">
    //             terms and conditions
    //           </a>.
    //         </label>
    //       </div>
    //       <div className="input-group-container">
    //         <button type="submit">Submit</button>
    //       </div>
    //     </form>
    //   </div>
    // </div>
  );

  return (
    <div>
      <div className="registration">
        <div className="registration_inner">
          <div className="logoIconContainer">
            <img src={inbetiaLogo}></img>
          </div>
          <h2>Welcome to INBETIA</h2>
          <form id="addUserForm" onSubmit={handleSubmit}>
            <div className="input-group-container">
              <div className="input-container">
                <input
                  type="text"
                  name="user"
                  onChange={handleChange}
                  placeholder="Username"
                  required
                />
              </div>
              <div className="input-container">
                <PasswordInput2
                  value={formData.password}
                  onChange={handleChange}
                ></PasswordInput2>
              </div>
            </div>
            <div className="input-group-container">
              <div className="input-group-container">
                <div className="input-container-small">
                  <input
                    type="text"
                    name="firstName"
                    onChange={handleChange}
                    placeholder="FirstName"
                    required
                  />
                </div>
                <div className="input-container-small">
                  <input
                    type="text"
                    name="lastName"
                    onChange={handleChange}
                    placeholder="LastName"
                    required
                  />
                </div>
              </div>

              <div className="input-container-terms">
                <input
                  type="checkbox"
                  id="termsandConditions"
                  name="termsConditions"
                  style={{ width: "35px", marginRight: "15px" }}
                  onChange={handleChange}
                  required
                />
                <label for="termsconditions">
                  By creating an account on our platform, you are indicating
                  your acceptance of our{" "}
                  <a href="https://www.inbetia.com/terms-and-conditions">
                    terms and conditions.
                  </a>
                </label>
              </div>
            </div>
            <div className="input-group-container">
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;
