/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useMemo } from "react";
import moment from "moment";

// Types
type DateFormat = "date" | "datetime";
type CountryCode = "UK" | "ES" | "US";

interface DateFormatConfig {
  dateFormat: string;
  dateTimeFormat: string;
}

interface DefaultValue {
  formatTimestamp(timestamp: number, format?: DateFormat): string;
  country: CountryCode;
}

// Constants
const COUNTRY_DATE_FORMATS: Record<CountryCode, DateFormatConfig> = {
  UK: {
    dateFormat: "DD/MM/YYYY",
    dateTimeFormat: "DD/MM/YYYY HH:mm",
  },
  ES: {
    dateFormat: "DD/MM/YYYY",
    dateTimeFormat: "DD/MM/YYYY HH:mm",
  },
  US: {
    dateFormat: "MM/DD/YYYY",
    dateTimeFormat: "MM/DD/YYYY hh:mm A",
  },
};

const defaultValue: DefaultValue = {
  formatTimestamp: () => "",
  country: "UK",
};

// Context
const SettingsContext = createContext(defaultValue);

export const SettingsProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  // For now, we're keeping the country static
  const country: CountryCode = "ES";

  const formatTimestamp = (
    timestamp: number,
    format: DateFormat = "date"
  ): string => {
    const timestampInMs = timestamp * 1000;

    const dateConfig = COUNTRY_DATE_FORMATS[country];
    const formatString =
      format === "date" ? dateConfig.dateFormat : dateConfig.dateTimeFormat;

    return moment(timestampInMs).format(formatString);
  };

  const contextValue = useMemo(
    () => ({
      formatTimestamp,
      country,
    }),
    [country]
  );

  return (
    <SettingsContext.Provider value={contextValue}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => useContext(SettingsContext);
