// libraries
import React from "react";
import { useAuth } from "@context/AuthContext";
import { useRefreshToken } from "@api/useRefreshToken";

// components
import { ConfirmationModal } from "./ConfirmModal";

// misc

// types
export type RefreshModalProps = {};

export const RefreshModal: React.FC<RefreshModalProps> = ({}) => {
  // variables
  const { isRefreshTokenModalVisible, toggleRefreshTokenModal, resetAuth } =
    useAuth();

  const { mutate, isLoading } = useRefreshToken({
    onSuccess: () => {
      toggleRefreshTokenModal();
    },
    onError: () => {
      toggleRefreshTokenModal();
    },
  });

  // functions
  const onPressSubmit = () => {
    mutate();
  };

  const onClose = () => {
    resetAuth();
    toggleRefreshTokenModal();
  };

  // renders
  return (
    <ConfirmationModal
      isOpen={isRefreshTokenModalVisible}
      onClose={onClose}
      onSubmit={onPressSubmit}
      title="Do you want to continue with the website?"
      body="Your login session is going to expire, do you want to continue for next 15 minutes."
      submitText="Yes"
      isLoading={isLoading}
    />
  );
};
