import React, { useState } from "react";
import GraphDashboard from "./graphDashboard";
import GetCreditsCard from "./getCredits";
import ProfitTab from "./profitTab";
import ActiveBookiesProfile from "./activeBookiesProfile";
import { DashBoardDataResponse, useGetDashboard } from "@api/useGetDashboard";

const DashboardSection = () => {
  const { data } = useGetDashboard() as { data: DashBoardDataResponse };
  const [activeTab, setActiveTab] = useState(0);

  // GRAPH DATQA FILTERED
  const filteredData =
    activeTab === 0
      ? data?.profitsData.all
      : activeTab === 1
      ? data?.profitsData.oneDay
      : activeTab === 2
      ? data?.profitsData?.oneWeek
      : data?.profitsData?.oneMonth;

  // FILTER PROFIT
  const filteredProfit =
    activeTab === 0
      ? data?.profits.all
      : activeTab === 1
      ? data?.profits.oneDay
      : activeTab === 2
      ? data?.profits?.oneWeek
      : data?.profits?.oneMonth;

  // FILTER PROFIT DIFF
  const filteredProfitDif =
    activeTab === 0
      ? null
      : activeTab === 1
      ? data?.profitsDif.oneDay
      : activeTab === 2
      ? data?.profitsDif?.oneWeek
      : data?.profitsDif?.oneMonth;
      
  return (
    <div>
      <GetCreditsCard />
      <ProfitTab
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        filteredProfit={filteredProfit}
        filteredProfitDif={filteredProfitDif}
      />
      <GraphDashboard data={filteredData} />
      <ActiveBookiesProfile data={data} />
    </div>
  );
};

export default DashboardSection;
