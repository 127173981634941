import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { useForm } from "react-hook-form";

import Heading from "@components/font/heading";
import { TextField } from "@components/form/textField";
import { theme } from "@styles/themes";
import { SearchSelectBox } from "@components/form/searchSelect";
import { Button } from "@components/button/button";
import { TabContext } from "@context/TabContext";
import { Country, City, ICountry } from "country-state-city";
import CountryCodePicker from "@components/form/phoneInput";
import { RadioButton } from "@components/form/radioButton";
import { useGetAddresses } from "@api/useGetAddress";
import { AddAddressRequest } from "@api/useAddAddress";
import { Address } from "@api/types";
import { AddressItem } from "@components/AddressItem";
import { countryCodes } from "@utils/countries";
import { usePayment } from "@context/PaymentContext";
import { CheckboxField } from "@components/CheckboxField";
import FullScreenLoader from "@components/loader/Loader";
import { PaymentFooter } from "./components/paymentFooter";

interface CollapsibleSectionProps {
  isCollapsed: boolean;
  maxHeight: string;
}

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CollapsibleSection = styled.div<CollapsibleSectionProps>`
  overflow: hidden;
  max-height: ${(props) => props.maxHeight};
  transition: max-height 0.4s ease, opacity 0.4s ease;
  opacity: ${(props) => (props.isCollapsed ? 0 : 1)};
`;

const ResponsiveDiv = styled.div`
  max-width: 730px;

  @media (min-width: 1200px) {
    padding-right: 36px;
  }
  @media (max-width: 1199px) {
    margin-bottom: 90px !important;
    padding-right: 0;
    width: 100%;
    max-width: 1300px;
  }
`;

const countries = Country.getAllCountries();
const mainOptions = countries?.map((country: ICountry) => ({
  value: country.isoCode,
  label: country.name,
}));

type AddressForm = AddAddressRequest & {
  radio_select: string;
  phonePrefix: any;
};

const StepOne = ({ billingRef }) => {
  // variables
  const { t } = useTranslation();
  const tabContext = useContext(TabContext);
  const { data } = useGetAddresses();
  const { setBillingDetails } = usePayment();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const { setActiveTab } = tabContext;
  const isDesktop = useMediaQuery({ query: "(min-width: 600px)" });
  const contentRef = useRef<any>(null);
  const { amount, convertToCurrencyAmount } = usePayment();
  const {
    handleSubmit,
    watch,
    getValues,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm<AddressForm>();

  // Toggle collapse function
  const watchCountry = watch("country");
  const watchCity = watch("city");
  const watchIsCompany = watch("isCompany");
  const watchRadioSelect = watch("radio_select");

  const city = City.getCitiesOfCountry(getValues("country"));

  const mainCity = city?.map((citys: any) => ({
    value: citys.name,
    label: citys.name,
  }));

  const phoneCodes = countries?.map((country: any) => ({
    finding_code: country.isoCode,
    label: country.phonecode,
    value: country.phonecode,
  }));

  const match = phoneCodes?.find(
    (code) => code.finding_code == getValues("country")
  );

  const setBillingId = () => {
    localStorage.setItem("billingId", getValues("radio_select"));
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const onSubmit = async (values: AddressForm, e: React.FormEvent) => {
    e.preventDefault();

    const formDataObj: AddAddressRequest = {
      name: values?.name,
      surname: values?.surname,
      country: values?.country,
      city: values?.city,
      address: values?.address,
      addressExtra: values?.addressExtra,
      zipCode: values?.zipCode,
      phonePrefix: "+" + values?.phonePrefix?.value,
      phoneNumber: values?.phoneNumber,
      isCompany: values?.isCompany,
      isSaved: values.isSaved,
    };

    setBillingDetails(formDataObj);
    setActiveTab(1);
  };

  const processExistingAddress = () => {
    const filteredAddress = data?.find(
      (address: any) => address.billingId === watchRadioSelect
    );

    setBillingDetails(filteredAddress);
    setActiveTab(1);
  };

  useEffect(() => {
    setBillingId();

    if (watchRadioSelect !== null) setValue("isCompany", null);
  }, [watchRadioSelect]);

  useEffect(() => {
    if (watchIsCompany !== null) setValue("radio_select", null);
  }, [watchIsCompany]);

  useEffect(() => {
    setValue("phonePrefix", match);
  }, [match, setValue, watchCountry]);
  console.log("data", data);

  return (
    <ResponsiveDiv className="mb-5">
      {data?.length > 0 && (
        <div className="mb-3">
          <Heading
            fontSize="14px"
            title="profile.billingDetailsSaved"
            fontWeight="700"
            color={theme.colors.white.dark}
          />
        </div>
      )}
      <div>
        {data?.slice(0, 2)?.map((elem: Address) => {
          return (
            <AddressItem
              key={elem.billingId}
              item={elem}
              onClick={() => setValue("radio_select", elem?.billingId)}
              isChecked={watch("radio_select") === elem?.billingId}
              registerProps={control.register("radio_select") as any}
              hideDelete
            />
          );
        })}
        <div className="d-block d-md-none mb-3">
          <Button
            onClick={toggleCollapse}
            title="actions.addAnother"
            isLoading={isSubmitting}
            outline
            startIcon={<Icon icon="mdi:plus-box-outline" width={16} />}
            size="lg"
            style={{
              width: "100%",
              fontWeight: "700",
              fontSize: "14px",
              borderRadius: "8px",
              margin: 0,
            }}
          />
        </div>
        {data?.slice(2).length > 0 && (
          <>
            <div className="d-flex" onClick={toggleCollapse}>
              <Heading
                fontSize="14px"
                title="See more"
                fontWeight="700"
                className="mb-3"
                color={theme.colors.white.normal}
                style={{ cursor: "pointer", textDecoration: "underline" }} // Makes it look clickable
              />
              <Icon
                icon="fe:arrow-down"
                width="18"
                height="18"
                style={{ color: theme.colors.white.normal }}
              />
            </div>

            <CollapsibleSection
              ref={contentRef}
              isCollapsed={isCollapsed}
              maxHeight={isCollapsed ? "0px" : `100%`}
            >
              {data?.slice(2)?.map((elem: Address) => {
                return (
                  <AddressItem
                    key={elem.billingId}
                    item={elem}
                    onClick={() => setValue("radio_select", elem?.billingId)}
                    isChecked={watch("radio_select") === elem?.billingId}
                    registerProps={control.register("radio_select") as any}
                    hideDelete
                  />
                );
              })}
            </CollapsibleSection>
          </>
        )}

        <form
          onSubmit={
            watchRadioSelect === null
              ? handleSubmit(onSubmit)
              : processExistingAddress
          }
          ref={billingRef}
        >
          <div className="mb-3">
            <Heading
              fontSize="14px"
              title="Add another"
              fontWeight="700"
              color={theme.colors.white.dark}
            />
          </div>

          <div>
            <Row className="g-3">
              <Col md={6} sm={6} xs={6}>
                <div
                  className="d-flex align-items-start"
                  onClick={() => setValue("isCompany", false)}
                >
                  <RadioWrapper>
                    <RadioButton
                      name="isCompany"
                      value={false} // value for the "company" option
                      checked={watch("isCompany") == false} // ensuring that you're comparing properly
                      {...control.register("isCompany", {
                        required: false,
                      })}
                    />
                  </RadioWrapper>

                  <div className="mx-3" style={{ cursor: "pointer" }}>
                    <Heading
                      fontSize="14px"
                      title="I’m an individual"
                      fontWeight="400"
                      color={theme.colors.white.normal}
                    />
                  </div>
                </div>
              </Col>
              <Col md={6} sm={6} xs={6}>
                <div
                  className="d-flex align-items-start"
                  onClick={() => setValue("isCompany", true)}
                >
                  <RadioWrapper>
                    <RadioButton
                      name="isCompany"
                      value={true} // value for the "company" option
                      checked={watch("isCompany") == true} // ensuring that you're comparing properly
                      {...control.register("isCompany", {
                        required: false,
                      })}
                    />
                  </RadioWrapper>

                  <div className="mx-3 " style={{ cursor: "pointer" }}>
                    <Heading
                      fontSize="14px"
                      title="I'm a company"
                      fontWeight="400"
                      color={theme.colors.white.normal}
                    />
                  </div>
                </div>
              </Col>

              <Col md={6} sm={12} xs={12}>
                <TextField
                  control={control}
                  name="name"
                  bgColor={theme.colors.grey.dark}
                  size="sm"
                  placeholder="Name*"
                  errors={errors.name}
                  rules={{ required: "This field is required" }}
                  onChange={
                    watchIsCompany === null &&
                    (() => setValue("isCompany", false))
                  }
                />
              </Col>

              <Col md={6} sm={12} xs={12}>
                <TextField
                  control={control}
                  name="surname"
                  bgColor={theme.colors.grey.dark}
                  size="sm"
                  placeholder="Surname*"
                  errors={errors.surname}
                  rules={{ required: "This field is required" }}
                />
              </Col>

              <Col md={6} sm={6} xs={6}>
                <SearchSelectBox
                  bgColor={theme.colors.grey.dark}
                  size="sm"
                  selectValue="isoCode"
                  selectLabel="name"
                  options={mainOptions}
                  placeholder="Country*"
                  control={control}
                  name="country"
                  setValue={setValue}
                  errors={errors.country}
                  watch={watchCountry}
                  rules={{ required: "This field is required" }}
                />
              </Col>
              <Col md={6} sm={6} xs={6}>
                <SearchSelectBox
                  bgColor={theme.colors.grey.dark}
                  size="sm"
                  options={mainCity}
                  selectValue="stateCode"
                  selectLabel="name"
                  placeholder="City*"
                  control={control}
                  name="city"
                  setValue={setValue}
                  errors={errors.city}
                  watch={watchCity}
                  rules={{ required: "This field is required" }}
                />
              </Col>

              <Col md={12} sm={12} xs={12}>
                <TextField
                  control={control}
                  name="address"
                  bgColor={theme.colors.grey.dark}
                  size="sm"
                  placeholder="Address*"
                  errors={errors.address}
                  rules={{ required: "This field is required" }}
                />
              </Col>

              <Col md={12} sm={12} xs={12}>
                <TextField
                  control={control}
                  name="addressExtra"
                  bgColor={theme.colors.grey.dark}
                  size="sm"
                  placeholder="Additional address (optional)"
                  rules={{ required: false }}
                />
              </Col>

              <Col md={6} sm={6} xs={4}>
                <TextField
                  control={control}
                  name="zipCode"
                  bgColor={theme.colors.grey.dark}
                  size="sm"
                  placeholder="Zip Code*"
                  errors={errors.zipCode}
                  rules={{ required: "This field is required" }}
                />
              </Col>

              <Col md={6} sm={6} xs={8}>
                <TextField
                  control={control}
                  name={watchIsCompany ? "vat" : "nif"}
                  bgColor={theme.colors.grey.dark}
                  size="sm"
                  placeholder={watchIsCompany ? "VAT*" : "NIF*"}
                  errors={watchIsCompany ? errors.vat : errors.nif}
                  rules={{ required: "This field is required" }}
                />
              </Col>

              <Col md={4} sm={4} xs={4} className="d-flex align-items-start">
                <CountryCodePicker
                  bgColor={theme.colors.grey.dark}
                  size="sm"
                  options={countryCodes}
                  placeholder="Phone Prefix"
                  control={control}
                  name="phonePrefix"
                  setValue={setValue}
                  errors={errors.phonePrefix}
                  watch={watchCity}
                  rules={{ required: false }}
                />
              </Col>
              <Col md={8} sm={8} xs={8}>
                <TextField
                  control={control}
                  name="phoneNumber"
                  bgColor={theme.colors.grey.dark}
                  size="sm"
                  placeholder="Phone number"
                  errors={errors.phoneNumber}
                  rules={{ required: false }}
                />
              </Col>

              <Col md={12}>
                <CheckboxField
                  control={control}
                  name="isSaved"
                  label={t("payments.saveBillingDetails")}
                />
              </Col>
            </Row>
          </div>

          <PaymentFooter isSubmitting={isSubmitting} />
        </form>
      </div>
    </ResponsiveDiv>
  );
};

export default StepOne;
