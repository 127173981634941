// libraries
import { AddAddressRequest } from "@api/useAddAddress";
import { useBankTransfer } from "@api/useBankTransfer";
import {
  PaymentResponse,
  useCreditCardPayment,
} from "@api/useCreditCardPayment";
import {
  AppCurrency,
  convertToCurrencyAmount,
  currencyPaymentCode,
  currenyMultipliers,
} from "@utils/currency";
import {
  paymentMethods,
  paymentMethodText,
  PaymentMethodType,
} from "@utils/payments";
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";

// types
type ChallengeStatus = "success" | "error" | null;
interface DefaultValue {
  setToken: Dispatch<SetStateAction<string>>;
  token: string | null;
  setOrderId: Dispatch<SetStateAction<string>>;
  orderId: string | null;
  setAmount: Dispatch<SetStateAction<number>>;
  amount: number | null;
  setCurrency: Dispatch<SetStateAction<string>>;
  cardHolderName: string | null;
  setCardHolderName: Dispatch<SetStateAction<string>>;
  challengeStatus: ChallengeStatus;
  setChallengeStatus: Dispatch<SetStateAction<ChallengeStatus>>;
  currency: string | null;
  setBillingDetails: Dispatch<SetStateAction<AddAddressRequest>>;
  billingDetails: AddAddressRequest | null;
  startPayment(): void;
  isPaymentSuccess: boolean;
  isPaymentFailed: boolean;
  resetPayment(): void;
  convertToCredit(amount: number): number;
  convertToCurrencyAmount(amount: number): string;
  credit: number;
  redsysResponse: PaymentResponse;
  paymentMethod: keyof typeof paymentMethodText;
  setPaymentMethod: Dispatch<SetStateAction<PaymentMethodType>>;
  isLoading: boolean; // New loading state
  resetRedsysResponse: () => void;
}

const defaultValue: DefaultValue = {
  setToken: () => null,
  token: null,
  setOrderId: () => null,
  orderId: null,
  setBillingDetails: () => null,
  billingDetails: null,
  setAmount: () => null,
  amount: null,
  isPaymentFailed: null,
  resetPayment: () => null,
  currency: null,
  setCurrency: () => null,
  paymentMethod: null,
  setPaymentMethod: () => null,
  startPayment: () => null,
  convertToCredit: () => null,
  convertToCurrencyAmount: () => null,
  isPaymentSuccess: false,
  credit: 0,
  redsysResponse: null,
  cardHolderName: null,
  setCardHolderName: () => null,
  isLoading: false, // Initialize loading state
  challengeStatus: null,
  setChallengeStatus: () => null,
  resetRedsysResponse: () => null,
};

// context
const PaymentContext = createContext(defaultValue);

export const PaymentProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  // Payment variable
  const [orderId, setOrderId] = useState<string>(null);
  const [token, setToken] = useState<string>(null);
  const [currency, setCurrency] = useState<AppCurrency>(null);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodType>(null);
  const [amount, setAmount] = useState<number>(null);
  const [billingDetails, setBillingDetails] = useState<AddAddressRequest>(null);
  const [cardHolderName, setCardHolderName] = useState<string>(null);
  const [challengeStatus, setChallengeStatus] = useState<ChallengeStatus>(null);

  const {
    mutate: submitCreditCard,
    isLoading,
    isSuccess: isCreditCardPaySuccess,
    isError: isCreditCardPayFailed,
    data: redsysResponse,
    reset: resetCreditCard,
  } = useCreditCardPayment();

  const {
    mutate: submitBankTransfer,
    isLoading: isBankTransferLoading,
    isSuccess: isBankTransferSuccess,
    isError: isBankTransferFailed,
    reset: resetBankTransfer,
  } = useBankTransfer();

  const convertToCredit = (amount: number) => {
    const multiplier = currenyMultipliers[currency];
    return multiplier * amount;
  };

  const credit = useMemo(() => {
    return convertToCredit(amount);
  }, [currency, amount]);

  const startPayment = () => {
    switch (paymentMethod) {
      case paymentMethods.creditCard:
        submitCreditCard({
          paymentAmount: amount,
          currency: currencyPaymentCode[currency],
          idOperation: token,
          orderNumber: orderId,
          cardholderName: cardHolderName,
          billingDetails,
        });
        break;

      case paymentMethods.bankTransfer:
        submitBankTransfer({
          paymentAmount: amount,
          currency: currencyPaymentCode[currency],
          billingDetails,
        });
        break;

      default:
        return;
    }
  };

  const resetPayment = () => {
    setToken(null);
    setOrderId(null);
    setCardHolderName(null);
    setChallengeStatus(null);
    setPaymentMethod(null);

    switch (paymentMethod) {
      case paymentMethods.creditCard:
        resetCreditCard();
        break;

      case paymentMethods.bankTransfer:
        resetBankTransfer();
        break;

      default:
        return;
    }
  };

  // Payment provider
  return (
    <PaymentContext.Provider
      value={{
        orderId,
        setOrderId,
        token,
        setToken,
        amount,
        setAmount,
        billingDetails,
        setBillingDetails,
        currency,
        setCurrency,
        startPayment,
        isPaymentSuccess: isCreditCardPaySuccess || isBankTransferSuccess,
        credit,
        convertToCredit,
        convertToCurrencyAmount: (amount) =>
          convertToCurrencyAmount(amount, currency),
        isPaymentFailed: isCreditCardPayFailed || isBankTransferFailed,
        resetPayment,
        redsysResponse,
        setPaymentMethod,
        paymentMethod,
        cardHolderName,
        setCardHolderName,
        isLoading: isLoading || isBankTransferLoading, // Provide loading state
        challengeStatus,
        setChallengeStatus,
        resetRedsysResponse: resetCreditCard,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export const usePayment = () => useContext(PaymentContext);
