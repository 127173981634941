// libraries
import { useMutation } from "react-query";
import { AxiosError } from "axios";

// utils and hooks
import request from "@utils/request";
import { ServerResponse } from "./types";
import { useErrorHandler } from "@hooks/useErrorHandler";

// Types
export interface CheckPhoneNumberRequest {
  phoneNumber: string;
  phonePrefix: string;
}

export interface CheckPhoneNumberResponse {
  message: string;
  // Add other relevant fields from the API response if necessary
}

export type PhoneCheckServerResponse = ServerResponse<CheckPhoneNumberResponse>;

// Hook
export const useCheckPhoneNumber = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (res: PhoneCheckServerResponse) => void;
  onError?: (res: AxiosError<ServerResponse<unknown>>) => void;
} = {}) => {
  const { triggerError } = useErrorHandler();

  return useMutation<
    PhoneCheckServerResponse,
    ServerResponse<unknown> | undefined,
    CheckPhoneNumberRequest
  >(["checkPhoneNumber"], async (data: CheckPhoneNumberRequest) => {
    try {
      const response = await request<PhoneCheckServerResponse>({
        method: "post",
        url: "signup/phonecheck",
        data,
      });

      if (onSuccess) {
        onSuccess(response.data);
      }

      return response.data;
    } catch (error) {
      if (onError) {
        onError(error as AxiosError<ServerResponse<unknown>>);
      }
      triggerError({ error });
      throw error;
    }
  });
};
