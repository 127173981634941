// libraries
import { useMutation } from "react-query";
import { AxiosError } from "axios";

// utils and hooks
import request from "@utils/request";
import { ServerResponse } from "./types";
import { useErrorHandler } from "@hooks/useErrorHandler";

// Types
export interface CheckEmailRequest {
  email: string;
}

export interface CheckEmailResponse {
  message: string;
  // Add other relevant fields from the API response if necessary
}

export type EmailCheckServerResponse = ServerResponse<CheckEmailResponse>;

// Hook
export const useCheckEmail = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (res: EmailCheckServerResponse) => void;
  onError?: (res: AxiosError<ServerResponse<unknown>>) => void;
} = {}) => {
  const { triggerError } = useErrorHandler();

  return useMutation<
    EmailCheckServerResponse,
    ServerResponse<unknown> | undefined,
    CheckEmailRequest
  >(["checkEmail"], async (data: CheckEmailRequest) => {
    try {
      const response = await request<EmailCheckServerResponse>({
        method: "post",
        url: "signup/emailcheck",
        data,
      });

      if (onSuccess) {
        onSuccess(response.data);
      }

      return response.data;
    } catch (error) {
      if (onError) {
        onError(error as AxiosError<ServerResponse<unknown>>);
      }
      triggerError({ error });
      throw error;
    }
  });
};
