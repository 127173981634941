import Heading from "@components/font/heading";
import { Icon } from "@iconify/react";
import { theme } from "@styles/themes";
import React from "react";
import BookiesChart from "./bookiesChart";
import BookieChartDetail from "./bookieChartDetail";
import styled from "styled-components";
import DesktopChartBookie from "./desktopChartBookie";
import DesktopBookieChartDetail from "./desktopBookieChartDetail";
import DesktopChartBets from "./desktopChartBets";
import DesktopBetsChartDetail from "./desktopBetsChartDetail";

const ChartContainer = styled.div`
  padding: 24px;
  max-width: 823px;

  @media (max-width: 1200px) {
    display: none;
  }
`;

const DesktopChartSection = () => {
  return (
    <ChartContainer>
      <Heading
        title="profile.stats"
        fontSize="29px"
        fontWeight="700"
        color={theme.colors.white.normal}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 24,
          marginBottom: 24,
          paddingBottom: 8,
          borderBottom: `1px solid ${theme.colors.grey.dark}`,
        }}
      >
        <Heading
          title="profile.bookies"
          fontSize="17px"
          fontWeight="700"
          color={theme.colors.white.normal}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 12,
          }}
        >
          <Heading
            title="profile.cleanFilters"
            fontSize="14px"
            fontWeight="400"
            style={{ cursor: "pointer", textDecoration: "underline" }}
            color={theme.colors.white.normal}
          />
          <Icon
            icon="charm:filter"
            color={theme.colors.white.normal}
            width={20}
            height={20}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 24,
          padding: "16px",
          backgroundColor: theme.colors.grey.darkActive,
          borderRadius: 10,
        }}
      >
        <DesktopChartBookie />
        <DesktopBookieChartDetail />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 24,
          marginBottom: 24,
          paddingBottom: 8,
          borderBottom: `1px solid ${theme.colors.grey.dark}`,
        }}
      >
        <Heading
          title="profile.bets"
          fontSize="17px"
          fontWeight="700"
          color={theme.colors.white.normal}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 12,
          }}
        >
          
          <Icon
            icon="charm:filter"
            color={theme.colors.white.normal}
            width={20}
            height={20}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 24,
          padding: "16px",
          backgroundColor: theme.colors.grey.darkActive,
          borderRadius: 10,
        }}
      >
        <DesktopChartBets />
        <DesktopBetsChartDetail />
      </div>
    </ChartContainer>
  );
};

export default DesktopChartSection;
