import React, { useContext } from "react";
import MobileProfileCard from "./mobileProfileCard";
import { Container } from "react-bootstrap";
import DesktopProfileHead from "./desktopProfileHead";
import DesktopProfileCard from "./desktopProfileCard";
import CopyModal from "@components/modal/CopyModal";
import SettingModal from "./settingModal";
import AddProfileModal from "./addProfileModal";
import EditProfileModal from "./editProfileModal";
import ArchieveProfileModal from "./archieveProfileModal";
import BookieAddForm from "./profileBookie/bookieAdd";
import DrawerRecord from "./drawerRecord";
import { useDeactivateProfile } from "@api/useDeactivateProfile";
import { useGetProfiles } from "@api/useGetProfiles";
import { TabContext } from "@context/TabContext";
import CustomToast from "@components/toastBar/CustomToast";
import { useUpdateBookies } from "@api/useUpdateBookies";
import { UpdateBookiesParams } from "@api/types";
import BookieEditForm from "./profileBookie/bookieEdit";
import { useNavigate } from "react-router-dom";
import { ToastContext } from "@context/ToastContext";

const ProfileSection = () => {
  const navigate=useNavigate()
  const { setToastMessage, toastMessage, setToastVisible, toastVisible } =
    useContext(ToastContext);
  const [settingOpen, setSettingOpen] = React.useState<boolean>(false);
  const [addProfileOpen, setAddProfileOpen] = React.useState<boolean>(false);
  const [editProfileOpen, setEditProfileOpen] = React.useState<boolean>(false);
  const [addBookieOpen, setAddBookieOpen] = React.useState<boolean>(false);
  const [editBookieOpen, setEditBookieOpen] = React.useState<boolean>(false);
  const [pausePlay, setPausePlay] = React.useState<boolean>(false);
  const [drawerRecordOpen, setDrawerRecordOpen] =
    React.useState<boolean>(false);
  const [archieveProfileOpen, setArchieveProfileOpen] =
    React.useState<boolean>(false);
  const { mutate } = useDeactivateProfile({
    onSuccess: () => {
      refetch();
      setToastVisible(true);
    },
  });
  const { refetch } = useGetProfiles({});
  const { mutate: updateBookie, isLoading } = useUpdateBookies({
    onSuccess: (data) => {
      console.log("Bookie updated successfully:", data);
      refetch() ;
      setToastVisible(true);
    },
    onError: (error) => {
      console.error("Failed to update bookie:", error);
      setToastMessage("Failed to update bookie"); 
      setToastVisible(true);
    },
  });

  const { profileIdSelected } = useContext(TabContext);
  const handleArchieveDeactivateProfile = () => {
    if (archieveProfileOpen) {
      handleArchieveBtn();
    } else {
      setArchieveProfileOpen(true);
    }
  };
  const handleArchieveActivateProfile = async (profileId: string) => {
    try {
      await mutate({ params: { profileId: profileId }, isActive: true });
      await setToastMessage("Profile reactivated");

    } catch (error) {
      console.error("Error activating profile:", error);
    }
  };
  const handleArchieveBtn = async () => {
    try {
      await mutate({
        params: { profileId: profileIdSelected },
        isActive: false,
      });
      setArchieveProfileOpen(false);
      await setToastMessage("Profile archived");
    } catch (error) {
      console.error("Error deactivating profile:", error);
    }
  };
  const handleDropdownMenuClick = (id: number) => {
    if (id == 1) {
      setAddBookieOpen(true);
    } else if (id == 2) {
      setEditProfileOpen(true);
    } else if (id == 3) {
      setArchieveProfileOpen(true);
    }
  };
  const handleBookieDropdownMenuClick = (id: number,bookies: any) => {
    
    if (id == 1) {
      handleUpdateBookie(bookies)
    } else if (id == 2) {
      setDrawerRecordOpen(true);
    } else if (id == 3) {
      setEditBookieOpen(true);
      navigate(`/profile`, {
        state: {
          bookie: bookies?.bookie,
          isEnabled: bookies?.isEnabled,
          accountId: bookies?.accountId,
          id:'edit'
        },
      })
    } else if (id == 4) {
      // setArchieveProfileOpen(true);
    }
  };

  const handleUpdateBookie = (bookies: any) => {
    const updateData: UpdateBookiesParams = {
      bookieId: bookies?.accountId,
      isEnabled: !bookies?.isEnabled,
      bookie: bookies?.bookie,
      isRunning: true,
    };

    updateBookie(updateData);
    if (bookies?.isEnabled) {
      setToastMessage("Bookie paused");
    } else {
      setToastMessage("Bookie resumed");
    }
  };

  return (
    <div>
      <div className="d-block d-sm-none">
        <MobileProfileCard
          setAddProfileOpen={setAddProfileOpen}
          setEditProfileOpen={setEditProfileOpen}
          setArchieveProfileOpen={setArchieveProfileOpen}
          handleArchieveDeactivateProfile={handleArchieveDeactivateProfile}
          handleArchieveActivateProfile={handleArchieveActivateProfile}
        />
      </div>
      <div
        className="d-none d-sm-block"
        style={{ margin: "24px 12px 24px 24px" }}
      >
        <DesktopProfileHead setSettingOpen={setSettingOpen} />
        <DesktopProfileCard
          setAddProfileOpen={setAddProfileOpen}
          settingOpen={settingOpen}
          setAddBookieOpen={setAddBookieOpen}
          setEditBookieOpen={setEditBookieOpen}
          setDrawerRecordOpen={setDrawerRecordOpen}
          handleDropdownMenuClick={handleDropdownMenuClick}
          handleArchieveDeactivateProfile={handleArchieveDeactivateProfile}
          handleArchieveActivateProfile={handleArchieveActivateProfile}
          handleBookieDropdownMenuClick={handleBookieDropdownMenuClick}
          setPausePlay={setPausePlay}
        />
      </div>
      <CopyModal
        open={settingOpen}
        setOpen={setSettingOpen}
        heading="profile.setting"
        mainContentStyle={{ padding: "0" }}
        mainContent={<SettingModal />}
      />
      <CopyModal
        open={addProfileOpen}
        setOpen={setAddProfileOpen}
        heading="profile.addProfile"
        mainContentStyle={{ padding: "0" }}
        mainContent={<AddProfileModal setAddProfileOpen={setAddProfileOpen} setToastOpen={setToastVisible} setToastMessage={setToastMessage}/>}
      />
      <CopyModal
        open={editProfileOpen}
        setOpen={setEditProfileOpen}
        heading="profile.editProfile"
        mainContentStyle={{ padding: "0" }}
        mainContent={
          <EditProfileModal setEditProfileOpen={setEditProfileOpen} setToastOpen={setToastVisible} setToastMessage={setToastMessage}/>
        }
      />
      <CopyModal
        open={archieveProfileOpen}
        setOpen={setArchieveProfileOpen}
        heading="profile.archieveProfile"
        mainContentStyle={{ padding: "0" }}
        mainContent={
          <ArchieveProfileModal
            setArchieveProfileOpen={setArchieveProfileOpen}
            handleArchieveBtn={handleArchieveBtn}
          />
        }
      />
      <CopyModal
        open={addBookieOpen}
        setOpen={setAddBookieOpen}
        heading="profile.addBookie"
        mainContentStyle={{ padding: "0" }}
        mainContent={<BookieAddForm setAddBookieOpen={setAddBookieOpen} />}
      />
        <CopyModal
        open={editBookieOpen}
        setOpen={setEditBookieOpen}
        heading="profile.editBookie"
        mainContentStyle={{ padding: "0" }}
        mainContent={<BookieEditForm setEditBookieOpen={setEditBookieOpen} />}
      />
      <DrawerRecord setShow={setDrawerRecordOpen} show={drawerRecordOpen} />
      <CustomToast isOpen={toastVisible} message={toastMessage} onClose={() => setToastVisible(false)} />
    </div>
  );
};

export default ProfileSection;
