/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from "react";
import "./paymentForm.css";
import { useTheme } from "styled-components";
import { usePayment } from "@context/PaymentContext";
import { useTranslation } from "react-i18next";

const PaymentForm = ({ onPressContinue }) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const { token, setOrderId, setToken, orderId, setCardHolderName } =
    usePayment();
  const timeoutRef = useRef();
  const { colors } = useTheme();
  const inputStyle = `color: ${colors.white.normal};`;
  const { t } = useTranslation();
  const commonInputStyle = `padding: 10px 10px;border: 1px solid ${colors.grey.dark};border-radius: 8px;font-size: 16px;width: 100%;box-sizing: border-box;background: ${colors.grey.dark};${inputStyle}`;

  function handleMessage(event) {
    // eslint-disable-next-line no-undef
    storeIdOper(event, "token", "errorCode", merchantValidationExample);

    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setToken(document.querySelector("#token")?.value);
    }, 500);
  }

  function merchantValidationExample() {
    return true;
  }

  function generateOrderId() {
    return "order" + Math.floor(Math.random() * 10000 + 1);
  }

  useEffect(() => {
    if (token) {
      setToken(token);
      setTimeout(() => {
        onPressContinue();
      }, 1000);
    }
  }, [token]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://sis-t.redsys.es:25443/sis/NC/sandbox/redsysV3.js";
    script.async = false;
    script.onload = () => setScriptLoaded(true); // Mark the script as loaded

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []); // Dependency array is empty to ensure this runs only once

  useEffect(() => {
    if (scriptLoaded) {
      // Now the script is loaded and you can safely access the library here
      console.log("Redsys script loaded and available:");

      window.addEventListener("message", handleMessage);
      const generatedId = generateOrderId();
      setOrderId(generatedId);

      // Ensure those functions are called only once

      // eslint-disable-next-line no-undef
      getCardInput(
        "card-number",
        `padding: 5px;border: 1px solid ${colors.grey.dark};border-radius: 8px;width: 100%;box-sizing: border-box;background: ${colors.grey.dark};`,
        "Card Number",
        `padding: 5px 10px;font-size: 16px;width: 100%;${inputStyle}`
      );
      // eslint-disable-next-line no-undef
      getExpirationInput("card-expiration", commonInputStyle, "MM/YY");
      // eslint-disable-next-line no-undef
      getCVVInput("cvv", commonInputStyle, "CVV");

      // eslint-disable-next-line no-undef
      getPayButton(
        "boton",
        `background-color: ${colors.primary};color: black;border: none;border-radius: 4px;padding: 12px 16px; width: 100%;font-size: 16px;cursor: pointer;`,
        "Continue",
        "362199093",
        "1",
        generatedId
      );
    }

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [scriptLoaded]);

  return (
    <div className="mt-3">
      <div
        class="input-wrapper"
        style={{
          paddingLeft: "10px",
          paddingRight: "10px",
          marginBottom: "6px",
        }}
      >
        <input
          type="text"
          id="card-holder-name"
          style={{
            padding: "8px",
            border: "1px solid #383838",
            borderRadius: "8px",
            width: "100%",
            boxSizing: "border-box",
            background: "#383838",
            color: colors.white.normal,
          }}
          placeholder={t("payments.cardHolderName")}
          onChange={(e) => setCardHolderName(e.target.value)}
        />
      </div>
      <div class="cardinfo-card-number">
        <div class="input-wrapper" id="card-number"></div>
      </div>
      <div class="cardinfo-exp-cvv-wrapper">
        <div class="cardinfo-exp-date">
          <div class="input-wrapper" id="card-expiration"></div>
        </div>
        <div class="cardinfo-cvv">
          <div class="input-wrapper" id="cvv"></div>
        </div>
      </div>
      <div id="boton"></div>
      <form name="datos">
        <input type="hidden" id="token" />
        <input type="hidden" id="errorCode" />
      </form>
    </div>
  );
};

export default PaymentForm;
