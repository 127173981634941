import React from "react";
import {
  StyleContent,
  StyledChip,
  StyledContainer,
  StyledHeader,
  StyledImg,
  StyleFooter,
  TitleContainer,
} from "./styledComponent";
import football from "@assets/sportsIcon/football.svg";
import Heading from "@components/font/heading";
import { theme } from "@styles/themes";

const BookieCard = ({ type }) => {
  return (
    <StyledContainer>
      <StyledHeader>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <StyledImg src={football} />
            <div>
              <Heading
                title="Betis - R. Madrid"
                fontSize="14px"
                fontWeight="400"
                color={theme.colors.white.normal}
                style={{ lineHeight: "16px" }}
              />
              <Heading
                title="24/05/2024 - 21:00"
                fontSize="12px"
                fontWeight="400"
                color={theme.colors.white.dark}
                style={{ lineHeight: "16px" }}
              />
            </div>
          </div>

          <StyledChip>
            <Heading
              title={type}
              fontSize="12px"
              fontWeight="400"
              color={
                type == "Win"
                  ? theme.colors.green.normal
                  : type == "Lost"
                  ? theme.colors.red.normal
                  : type == "Void"
                  ? theme.colors.white.dark
                  : type == "Pending"
                  ? theme.colors.yellow.normal
                  : theme.colors.white.normal
              }
              style={{ lineHeight: "16px" }}
            />
          </StyledChip>
        </div>
      </StyledHeader>
      <StyleContent>
        <TitleContainer>
          <Heading
            title="Market :"
            fontSize="10px"
            fontWeight="400"
            color={theme.colors.white.dark}
            style={{ lineHeight: "16px" }}
          />
          <Heading
            title="More or less corners"
            fontSize="12px"
            fontWeight="400"
            color={theme.colors.white.normal}
            style={{ lineHeight: "16px" }}
          />
        </TitleContainer>
        <TitleContainer>
          <Heading
            title="Bet :"
            fontSize="10px"
            fontWeight="400"
            color={theme.colors.white.dark}
            style={{ lineHeight: "16px" }}
          />
          <Heading
            title="Less than 8,5 | 23/05/2024 - 17:00"
            fontSize="12px"
            fontWeight="400"
            color={theme.colors.white.normal}
            style={{ lineHeight: "16px" }}
          />
        </TitleContainer>
        <TitleContainer>
          <Heading
            title="ODD :"
            fontSize="10px"
            fontWeight="400"
            color={theme.colors.white.dark}
            style={{ lineHeight: "16px" }}
          />
          <Heading
            title="1.00"
            fontSize="12px"
            fontWeight="400"
            color={theme.colors.white.normal}
            style={{ lineHeight: "16px" }}
          />
        </TitleContainer>
      </StyleContent>
      <StyleFooter>
        <div className="d-flex align-items-center">
          <Heading
            title="Stake"
            fontSize="10px"
            fontWeight="400"
            color={theme.colors.white.dark}
            style={{ lineHeight: "16px", marginRight: "4px" }}
          />
          <Heading
            title="105,50€"
            fontSize="14px"
            fontWeight="700"
            color={theme.colors.white.normal}
            style={{ lineHeight: "19px" }}
          />
        </div>
        <div className="d-flex align-items-center">
          <Heading
            title="Payout"
            fontSize="10px"
            fontWeight="400"
            color={theme.colors.white.dark}
            style={{ lineHeight: "16px", marginRight: "4px" }}
          />
          <Heading
            title="200,05€"
            fontSize="14px"
            fontWeight="700"
            color={
              type == "Win"
                ? theme.colors.green.normal
                : theme.colors.white.normal
            }
            style={{ lineHeight: "19px" }}
          />
        </div>
      </StyleFooter>
    </StyledContainer>
  );
};

export default BookieCard;
