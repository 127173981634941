import "./Login.css";

// libraries
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";

// misc
import logo from "@assets/logo.svg";
import userLogo from "@assets/icons/user.svg";
import passLogo from "@assets/icons/key.svg";
import telegramLogo from "@assets/icons/telegram.svg";
import emailLogo from "@assets/icons/envelope.svg";
import Loader from "@assets/animations/loader.svg";
import { LoginRequest, useLogin } from "@api/useLogin";
import { useAuth } from "@context/AuthContext";
import { Button } from "@components/button/button";
import { TextField } from "@components/form/textField";
import { SelectBox } from "@components/form/selectBox";
import { SearchSelectBox } from "@components/form/searchSelect";
import NewLogin from "./newLogin";

const Login = () => {
  // variables
  const navigate = useNavigate(); // Get the history object from react-router-dom
  const { t } = useTranslation();
  const { setAuth } = useAuth();

  const [searchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<LoginRequest>({ mode: "all" });

  const { mutate, isLoading } = useLogin({
    onError: (error) => {
      if (error.code === "ERR_NETWORK") {
        toast.error(t("errors.badconnection"));
      } else if (error.response.status === 401) {
        toast.error(t("login.errorsLogin.baduserpass"));
      } else {
        toast.error(t("errors.unknowError"));
      }
    },
  });

  // functions
  const onSubmit: SubmitHandler<LoginRequest> = (data) => mutate(data);

  // returns
  return (
    <NewLogin />
    // <div className="App-header">
    //   <img src={logo} id="inbetia-logo" alt="logo" />

    //   <form onSubmit={handleSubmit(onSubmit)}>
    //     <div id="loginTitle">{t("login.loginMainText")}</div>
    //     <div className="login-inputs">
    //       <div className="login-input-container">
    //         <div
    //           className="inputContainer"
    //           onClick={() => {
    //             document.getElementById("user").focus();
    //           }}
    //         >
    //           <img src={userLogo} alt="userButton"></img>
    //           <input
    //             type="text"
    //             id="user"
    //             placeholder="Username"
    //             {...register("username", { required: true })}
    //           />
    //         </div>
    //         <div
    //           className="inputContainer"
    //           onClick={() => {
    //             document.getElementById("pass").focus();
    //           }}
    //         >
    //           <img src={passLogo} alt="userButton"></img>
    //           <input
    //             type="password"
    //             id="pass"
    //             placeholder="Password"
    //             {...register("password", { required: true })}
    //           />
    //         </div>
    //       </div>

    //       <div className="buttonContainer">
    //         <button
    //           type="submit"
    //           className="okButton"
    //           id="login-button"
    //           disabled={isLoading || !isValid}
    //         >
    //           {!isLoading ? (
    //             t("login.loginButton")
    //           ) : (
    //             <img src={Loader} className="spinner" alt="spinner" />
    //           )}
    //         </button>
    //       </div>
    //     </div>
    //   </form>

    //   <footer id="loginFooter">
    //     <p>{t("login.footerText")}</p>
    //     <div className="social-icons">
    //       <a
    //         href="https://t.me/inbetia"
    //         target="_blank"
    //         rel="noreferrer"
    //         className="icon-link"
    //       >
    //         <img id="telegramLogo" src={telegramLogo} alt="Telegram Logo" />
    //       </a>
    //       <a
    //         href="mailto:support@inbetia.com"
    //         rel="noreferrer"
    //         className="icon-link"
    //       >
    //         <img id="emailLogo" src={emailLogo} alt="Email Logo" />
    //       </a>
    //     </div>
    //   </footer>
    // </div>
  );
};

export default Login;
