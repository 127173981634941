import { useEditSetting } from "@api/useEditSetting";
import { Button } from "@components/button/button";
import { SelectBox } from "@components/form/selectBox";
import { useAuth } from "@context/AuthContext";
import { usePayment } from "@context/PaymentContext";
import { theme } from "@styles/themes";
import { languages } from "@utils/countries";
import { currencies } from "@utils/currency";
import { years } from "@utils/dateMonthTime";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const FooterWrapper = styled.div`
  position: fixed;
  border-top: 0.5px solid ${(props) => props.theme.colors.white.dark};
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${(props) => props.theme.colors.grey.darker};
  text-align: center;
  padding: 14px 16px;
`;
const SettingModal = () => {
  const { accountSetting, setSetting } = useAuth();
  const { t } = useTranslation();
  const { mutate } = useEditSetting({
    onSuccess: (res) => {
      setSetting(res?.data?.settings);
    },
  });
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      language: accountSetting?.language,
      currency: accountSetting?.currency,
      timeZone: accountSetting?.timeZone,
      dateFormat: accountSetting?.dateFormat,
    },
  });
  const onSubmit = async (values: any) => {
    const formData = {
      language: values?.language,
      currency: values?.currency,
      timeZone: values?.timeZone,
      dateFormat: values?.dateFormat,
    };
    mutate(formData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="language"
        control={control}
        defaultValue={accountSetting.language}
        render={({ field }) => (
          <SelectBox
            {...field}
            style={{ margin: "33px 0" }}
            bgColor={theme.colors.grey.dark}
            defaultValue={accountSetting.language}
            size="sm"
            options={Object.values(languages)}
            placeholder={t("profile.language")}
            onChange={(value) => field.onChange(value)}
          />
        )}
      />

      <Controller
        name="currency"
        control={control}
        defaultValue={accountSetting.language}
        render={({ field }) => (
          <SelectBox
            {...field}
            style={{ margin: "33px 0" }}
            bgColor={theme.colors.grey.dark}
            defaultValue={accountSetting?.currency}
            size="sm"
            options={Object.values(currencies)}
            placeholder={t("profile.defaultCurrency")}
            onChange={(value) => field.onChange(value)}
          />
        )}
      />

      <Controller
        name="timeZone"
        control={control}
        defaultValue={accountSetting.language}
        render={({ field }) => (
          <SelectBox
            {...field}
            style={{ margin: "33px 0" }}
            bgColor={theme.colors.grey.dark}
            defaultValue={accountSetting?.language}
            size="sm"
            options={Object.values(languages)}
            placeholder={t("profile.timeZone")}
            onChange={(value) => field.onChange(value)}
          />
        )}
      />

      <Controller
        name="dateFormat"
        control={control}
        defaultValue={accountSetting.language}
        render={({ field }) => (
          <SelectBox
            {...field}
            style={{ margin: "33px 0" }}
            bgColor={theme.colors.grey.dark}
            defaultValue={accountSetting?.language}
            size="sm"
            options={Object.values(languages)}
            placeholder={t("profile.dateFormat")}
            onChange={(value) => field.onChange(value)}
          />
        )}
      />

      <div
        style={{ textAlign: "right", marginTop: "26px" }}
        className="d-none d-sm-block"
      >
        <Button
          style={{
            borderRadius: "8px",
            fontWeight: "700",
            fontSize: "14px",
          }}
          type="submit"
          title="profile.saveChanges"
          size="md"
        />
      </div>
      <FooterWrapper className="d-block d-sm-none">
        <Button
          style={{
            width: "100%",
            borderRadius: "8px",
            fontWeight: "700",
            fontSize: "14px",
          }}
          type="submit"
          title="profile.saveChanges"
          size="md"
        />
      </FooterWrapper>
    </form>
  );
};

export default SettingModal;
