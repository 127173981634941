import React from "react";
import { Card, Placeholder } from "react-bootstrap";

const SkeletonLoader = ({ height }: { height?: string }) => {
  return (
    <>
      {[...Array(5)].map((elem, index) => {
        return (
          <Placeholder
            xs={12}
            key={index}
            style={{
              height: height || "45px",
              borderRadius: "8px",
              margin: "8px 0",
            }}
          />
        );
      })}
    </>
  );
};

export default SkeletonLoader;
