import i18n from "@translations/i18n";

export type PaymentMethodType =
  (typeof paymentMethods)[keyof typeof paymentMethods];

export const paymentMethods = {
  creditCard: 1,
  bankTransfer: 2,
  crypto: 3,
} as const;

export const paymentMethodText = {
  1: i18n.t("payments.creditCard"),
  2: i18n.t("payments.bankTransfer"),
  3: "Crypto",
};

export const paymentStatusText = {
  0: i18n.t("payments.pending"),
  1: i18n.t("payments.accepted"),
  2: i18n.t("payments.canceled"),
};
