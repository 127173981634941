import Heading from "@components/font/heading";
import { Icon } from "@iconify/react";
import { theme } from "@styles/themes";
import React from "react";
import { Offcanvas } from "react-bootstrap";
import BookieRecordSection from "./profileBookie/bookieRecord";

const DrawerRecord = ({ setShow, show }: any) => {
  const handleClose = () => setShow(false);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        style={{ background: theme.colors.grey.darker }}
      >
        <Offcanvas.Header
          className="pt-2 pb-2"
          style={{ borderBottom: `0.5px solid ${theme.colors.white.darker}` }}
        >
          <div
            className="d-flex justify-content-between align-items-center w-100"
            style={{ padding: "6px 8px" }}
          >
            <div>
              <Heading
                fontSize="20px"
                title="Bookie name..."
                fontWeight="700"
                color={theme.colors.white.normal}
              />
            </div>
            <div>
              <Icon
                onClick={handleClose}
                style={{ color: theme.colors.white.normal }}
                icon="bitcoin-icons:cross-filled"
                width="24"
                height="24"
              />
            </div>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <BookieRecordSection />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default DrawerRecord;
