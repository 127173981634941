import React from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button } from "@components/button/button";
import Heading from "@components/font/heading";
import CountryCodePicker from "@components/form/phoneInput";
import { TextField } from "@components/form/textField";
import { theme } from "@styles/themes";
import SignUpStepTwo from "./signUpStepTwo";
import request from "@utils/request";
import { countryCodes } from "@utils/countries";
import { useCheckNickname } from "@api/useCheckNickname";
import { useCheckEmail } from "@api/useCheckEmail";
import { useSignUp } from "@api/useSignUp";
import AlertModal from "@components/modal/AlertModal";
import FullScreenLoader from "@components/loader/Loader";

const SignUpForm = () => {
  // variables
  const { t } = useTranslation();
  const [step, setStep] = React.useState<any>(1);
  const navigate = useNavigate();
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phonePrefix: "",
      phoneNumber: "",
      nickname: "",
      telegramUser: "",
      password: "",
      confirmPassword: "",
      termsConditions: false,
    },
  });
// console.log(isValid,'isValid');

  const {
    mutate: startSignUp,
    isLoading: isSignUpLoading,
    isError: isSignUpError,
    isSuccess: isSignUpSuccess,
    reset: resetSignUp,
  } = useSignUp();

  // Nickname Check Hook
  const checkNickname = useCheckNickname({
    onSuccess: (res) => {
      if (res.success) {
        clearErrors("nickname");
      }
    },
    onError: (err) => {
      if (err.response?.status === 409) {
        setError("nickname", {
          type: "manual",
          message: t("signUp.usernameAlreadyExist"), // Replace with `t` if using i18n
        });
      }
    },
  });

  // Email Check Hook
  const checkEmail = useCheckEmail({
    onSuccess: (res) => {
      if (res.success) {
        clearErrors("email");
      }
    },
    onError: (err) => {
      if (err.response?.status === 409) {
        setError("email", {
          type: "manual",
          message: t("signUp.emailAlreadyRegistered"), // Replace with `t` if using i18n
        });
      }
    },
  });

  // functions
  const onSubmit = async (values: any) => {
    const formData = {
      nickname: values?.nickname,
      email: values?.email,
      password: values?.password,
      firstName: values?.firstName,
      lastName: values?.lastName,
      phoneNumber: values?.phoneNumber,
      phonePrefix: values?.phonePrefix?.value,
      telegramUser: values?.telegramUser,
      termsConditions: values?.termsConditions,
    };
    
    startSignUp(formData);
  };

  const onContinue = (data: any) => {
    if (step === 1) {
      setStep(2);
    } else {
      handleSubmit(onSubmit)();
    }
  };

  const handleSignUpSuccess = () => {
    resetSignUp();
    navigate("/login");
  };

  // returns
  return (
    <div className="mb-3">
      <Heading
        title="signIn.signUpButton"
        fontSize="29px"
        fontWeight="700"
        color={theme.colors.white.normal}
        className="mb-4"
      />
      <Heading
        title={`${t("step")} ${step} of 2`}
        fontSize="14px"
        fontWeight="400"
        className="mb-1"
        color={theme.colors.white.dark}
      />
      <Heading
        title={
          step == 1
            ? "Tell us a bit about yourself"
            : "Finally, please take a moment to read our Terms and Conditions"
        }
        fontSize="20px"
        fontWeight="700"
        color={theme.colors.white.normal}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        {step == 1 ? (
          <Row className="g-2">
            <Col md={6} sm={12} xs={12}>
              <TextField
                control={control}
                name="firstName"
                bgColor={theme.colors.grey.dark}
                size="sm"
                placeholder="Name*"
                errors={errors.firstName}
                rules={{
                  required: "This field is required",
                  pattern: {
                    value: /^[A-Za-zÁÉÍÓÚáéíóúÀÈÌÒÙàèìòùÄËÏÖÜäëïöüÇçÑñ]+(\s[A-Za-zÁÉÍÓÚáéíóúÀÈÌÒÙàèìòùÄËÏÖÜäëïöüÇçÑñ]+)*$/,
                    message: "Must contain only Latin letters and single spaces between names"
                  }
                }}
              />
            </Col>
            <Col md={6} sm={12} xs={12}>
              <TextField
                control={control}
                name="lastName"
                bgColor={theme.colors.grey.dark}
                size="sm"
                placeholder="Last name*"
                errors={errors.lastName}
                rules={{
                  required: "This field is required",
                  pattern: {
                    value: /^[A-Za-zÁÉÍÓÚáéíóúÀÈÌÒÙàèìòùÄËÏÖÜäëïöüÇçÑñ]+(\s[A-Za-zÁÉÍÓÚáéíóúÀÈÌÒÙàèìòùÄËÏÖÜäëïöüÇçÑñ]+)*$/,
                    message: "Must contain only Latin letters and single spaces between names"
                  }
                }}
              />
            </Col>

            <Col md={12} sm={12} xs={12}>
              <TextField
                control={control}
                name="email"
                bgColor={theme.colors.grey.dark}
                size="sm"
                placeholder="Email*"
                errors={errors.email}
                onBlur={() => checkEmail.mutate({ email: watch("email") })}
                rules={{
                  required: "This field is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Please enter a valid email address",
                  },
                }}
              />
            </Col>

            <Col md={3} sm={3} xs={6} className="d-flex align-items-start">
              <CountryCodePicker
                bgColor={theme.colors.grey.dark}
                size="sm"
                options={countryCodes}
                placeholder="Prefix"
                control={control}
                name="phonePrefix"
                setValue={setValue}
                errors={errors.phonePrefix}
                rules={{ required: true }}
              />
            </Col>

            <Col md={9} sm={9} xs={6} >
              <TextField
                control={control}
                name="phoneNumber"
                bgColor={theme.colors.grey.dark}
                size="sm"
                placeholder="Phone number"
                errors={errors.phoneNumber}
                rules={{
                  required: "This field is required",
                  pattern: {
                    value: /^[0-9]+$/, // Only allows digits
                    message: "Phone number must contain only numbers",
                  },
                }}
              />
            </Col>
            <Col md={12} sm={12} xs={12}>
              <TextField
                control={control}
                name="nickname"
                bgColor={theme.colors.grey.dark}
                size="sm"
                placeholder="Username*"
                errors={errors.nickname}
                onBlur={() =>
                  checkNickname.mutate({ nickname: watch("nickname") })
                }
                rules={{
                  required: "This field is required",
                  pattern: {
                    value: /^[a-zA-Z0-9_.-]+$/,
                    message:
                      "Usernames can only use letters, numbers, underscores, dashes, and periods, and cannot contain spaces.",
                  },
                }}
              />
            </Col>
            <Col md={12} sm={12} xs={12}>
              <TextField
                control={control}
                name="telegramUser"
                bgColor={theme.colors.grey.dark}
                size="sm"
                placeholder="@Telegram"
                errors={errors.telegramUser}
                rules={{ required: false }}
              />
            </Col>
            <Col md={12} sm={12} xs={12}>
              <TextField
                control={control}
                name="password"
                bgColor={theme.colors.grey.dark}
                size="sm"
                type="password"
                placeholder="Password*"
                errors={errors.password}
                rules={{
                  required: "This field is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters long",
                  },
                  pattern: {
                    value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/,
                    message:
                      "Password must be at least 6 characters and contain at least one letter and one number",
                  },
                }}
              />
            </Col>
            <Col md={12} sm={12} xs={12}>
              <TextField
                control={control}
                name="confirmPassword"
                bgColor={theme.colors.grey.dark}
                size="sm"
                type="password"
                placeholder="Confirm Password*"
                errors={errors.confirmPassword}
                rules={{
                  required: "This field is required",
                  validate: (value: any) =>
                    value === watch("password") || "Passwords do not match",
                }}
              />
            </Col>
          </Row>
        ) : (
          <SignUpStepTwo control={control} errors={errors} />
        )}
        <Button
          title="payments.continue"
          style={{
            fontSize: "14px",
            fontWeight: "700",
            borderRadius: "8px",
            minWidth: "12em",
            marginTop: "16px",
          }}
          // disabled={errors == {}?false:false}
          onClick={handleSubmit(onContinue)}
        />
      </form>

      <AlertModal
        open={isSignUpError}
        type="error"
        title={"signUp.somethingWrong"}
        description={"signUp.somethingWrongDescription"}
        onActionClick={resetSignUp}
      />
      
      <AlertModal
        open={isSignUpSuccess}
        type="success"
        title={"signUp.success"}
        description={"signUp.successDescription"}
        onActionClick={handleSignUpSuccess}
      />
      {isSignUpLoading && <FullScreenLoader />}
    </div>
  );
};

export default SignUpForm;
