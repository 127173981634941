import Heading from "@components/font/heading";
import { TabContext } from "@context/TabContext";
import { theme } from "@styles/themes";
import React, { useContext } from "react";
import styled from "styled-components";
import NotificationCardDesktop from "../notificationSection/notificationCardDesktop";
import { useGetNotifications } from "@api/useGetNotifications";
import { useCleanNotification } from "@api/useCleanAllNotifications";
import SkeletonLoader from "@components/loader/skeleton";
import NotificationEmpty from "@assets/notifications/emptyNotification.svg";
const NotificationCardContainer = styled.div`
  background-color: ${theme.colors.grey.darker};
  border-radius: 8px;
  position: absolute;
  top: 40px;
  max-width: 408px;
  width: 408px;
  max-height: 469px;
  overflow: hidden;
  height: 469px;
  z-index: 999;
  right: 0px;
  border: 1px solid ${theme.colors.grey.normal};
`;

const NotificatiuonComponentDesk = () => {
  const { notificationCardOpen, setNotificationCardOpen } =
    useContext(TabContext);
  const { data: notificationData, isLoading } = useGetNotifications();
  const { mutate } = useCleanNotification();

  return (
    <NotificationCardContainer
      style={{ display: notificationCardOpen ? "block" : "none" }}
    >
      <div
        className="d-flex justify-content-between align-items-center"
        style={{
          padding: "16px",
          borderBottom: `1px solid ${theme.colors.grey.normal}`,
        }}
      >
        <Heading
          title="profile.notifications"
          fontSize="17px"
          fontWeight="700"
          color={theme.colors.white.normal}
        />
        <Heading
          title="profile.cleanAll"
          fontSize="14px"
          fontWeight="400"
          onClick={() => mutate("cleanAll")}
          color={theme.colors.white.normal}
          style={{
            textDecoration: "underline",
            textAlign: "right",
            cursor: "pointer",
          }}
        />
      </div>

      <div style={{ overflow: "scroll", maxHeight: "410px", height: "410px" }}>
        {isLoading ? (
          <SkeletonLoader height="77px" />
        ) : notificationData?.notifications?.length <= 0 ? (
          <div className="d-flex justify-content-center align-items-center" style={{height:'100%'}}>
            <img
              src={NotificationEmpty}
              style={{ minWidth:'210px'  }}
              alt="Notify Empty"
            />
          </div>
        ) : (
          notificationData?.notifications?.map((notify: any, index: number) => {
            const date = new Date(notify?.date * 1000);
            const formattedDate = date.toLocaleDateString("en-GB");
            return (
              <NotificationCardDesktop
                notifyId={notify?.notificationId}
                key={index}
                isIncidence={notify?.isIncidence}
                title={notify?.title}
                date={formattedDate}
                content={notify?.description}
              />
            );
          })
        )}
      </div>
    </NotificationCardContainer>
  );
};

export default NotificatiuonComponentDesk;
