import { Button } from "@components/button/button";
import Heading from "@components/font/heading";
import { Icon } from "@iconify/react";
import { theme } from "@styles/themes";
import React, { useContext } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import Flag from "@assets/flags/ea.svg";
import avatar from "@assets/avatar.svg";
import Chip from "@components/chip";
import styled from "styled-components";
import { TabContext } from "@context/TabContext";
import { convertToCurrencyAmount } from "@utils/currency";
import { useNavigate } from "react-router-dom";

const HoverDiv = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 12px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${theme.colors.grey
      .darker}; // Change background color on hover
  }
`;

const dropDownMenu = [
  { title: "profile.pause", icon: "heroicons-outline:pause", id: 1 },
  { title: "profile.record", icon: "mage:book", id: 2 },
  { title: "profile.edit", icon: "fluent:edit-28-regular", id: 3 },
  { title: "profile.archieve", icon: "fluent:archive-16-regular", id: 4 },
];
const outerDropDownMenu = [
  { title: "profile.addBookie", icon: "f7:plus-app", id: 1 },
  { title: "profile.edit", icon: "fluent:edit-28-regular", id: 2 },
  { title: "profile.archieve", icon: "fluent:archive-16-regular", id: 3 },
];
const HiddenBelowLgCol = styled(Col)`
  @media (max-width: 1199px) {
    display: none;
  }
`;
const HiddenAboveLgCol = styled(Col)`
  @media (min-width: 1199px) {
    display: none;
  }
`;
const HiddenBelowLgDiv = styled.div`
  @media (max-width: 1199px) {
    display: none;
  }
`;
const HiddenAboveLgDiv = styled.div`
  @media (min-width: 1199px) {
    display: none;
  }
`;
const AccordianProfile = ({
  elem,
  index,
  setAddBookieOpen,
  setEditBookieOpen,
  setDrawerRecordOpen,
  handleDropdownMenuClick,
  handleArchieveDeactivateProfile,
  handleArchieveActivateProfile,
  handleBookieDropdownMenuClick,
  setPausePlay,
}: any) => {
  const [activeKey, setActiveKey] = React.useState<any>(null);
  const [dropDown, setDropDown] = React.useState<any>(null);
  const [outerDropDown, setOuterDropDown] = React.useState<any>(null);
  const { setProfileIdSelected, setProfileCountry } = useContext(TabContext);
  const handleToggle = (key: number, profileId: string, country: string) => {
    setActiveKey(activeKey === key ? null : key);
    setProfileIdSelected(profileId);
    setProfileCountry(country);
  };
  const handleDropDown = (i: number) => {
    setDropDown((prev: number) => (prev === i ? null : i));
  };
  const handleOuterDropDown = (i: number) => {
    setOuterDropDown((prev: number) => (prev === i ? null : i));
  };
const navigate = useNavigate()
  return (
    <>
      {elem?.isActive == true ? (
        <Accordion
          activeKey={activeKey}
          style={{ marginBottom: "18px", marginTop: "12px" }}
        >
          <div
            style={{
              backgroundColor: theme.colors.grey.dark,
              borderRadius: "8px",
              padding: "10px 14px",

              cursor: "pointer",
            }}
            onClick={() => handleToggle(index, elem?.profileId, elem?.country)}
          >
            <Row className="align-items-center">
              <Col lg={3} md={3} sm={3}>
                <div className="d-flex">
                  <Icon
                    icon={activeKey === index ? "fe:arrow-up" : "fe:arrow-down"}
                    width="18"
                    className="me-3"
                    style={{ color: theme.colors.white.normal }}
                  />
                  <div className="d-flex align-items-center">
                    <img
                      src={`https://flagcdn.com/${elem?.country?.toLowerCase()}.svg`}
                      width={24}
                      className="me-2"
                    />
                    <Heading
                      title={elem?.profileName}
                      fontSize="14px"
                      fontWeight="700"
                      color={theme.colors.white.normal}
                    />
                  </div>
                </div>
              </Col>
              <Col lg={8} md={8} sm={8} xs={8}>
                <Heading
                  title={convertToCurrencyAmount(340000, "eur")}
                  fontSize="14px"
                  fontWeight="700"
                  className="me-1"
                  color={theme.colors.white.normal}
                />
              </Col>

              <Col
                lg={1}
                md={1}
                sm={1}
                style={{ textAlign: "center", position: "relative" }}
              >
                <Icon
                  onClick={() => handleOuterDropDown(index)}
                  icon="iconamoon:menu-kebab-vertical-bold"
                  width="24"
                  height="24"
                  style={{ color: theme.colors.white.normal }}
                />
                <div
                  style={{
                    backgroundColor: theme.colors.grey.dark,
                    borderRadius: "8px",
                    overflow: "hidden",
                    position: "absolute",
                    zIndex: 10,
                    display: outerDropDown == index ? "block" : "none",
                    top: 36,
                    right: 12,
                    width: "max-content",
                    minWidth: "197px",
                    boxShadow: "0px 0px 3px 0px rgba(255, 255, 255, 0.5)",
                  }}
                >
                  {outerDropDownMenu?.map((outerDropDown, outerDropIndex) => {
                    return (
                      <HoverDiv
                        key={outerDropIndex}
                        onClick={() =>
                          handleDropdownMenuClick(outerDropDown?.id)
                        }
                      >
                        <Icon
                          icon={outerDropDown?.icon}
                          width="16"
                          height="16"
                          style={{
                            color: theme.colors.white.normal,
                            marginRight: "6px",
                          }}
                        />
                        <Heading
                          title={outerDropDown?.title}
                          fontSize="14px"
                          fontWeight="400"
                          color={theme.colors.white.normal}
                        />
                      </HoverDiv>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </div>

          <Accordion.Collapse eventKey={index}>
            <div className="mb-3">
              {elem?.bookies?.map((subElem: any, i: any) => {
                return (
                  <div
                    key={i}
                    style={{
                      borderBottom: `0.5px solid ${theme.colors.grey.dark}`,
                    }}
                  >
                    <Row
                      className="align-items-center"
                      style={{ padding: "18.5px 16px" }}
                    >
                      <Col lg={3} md={3} sm={3}>
                        <div className="d-flex align-items-center">
                          <img
                            src={avatar}
                            style={{
                              width: "40px",
                              height: "40px",
                              marginRight: "10px",
                            }}
                          />
                          <div>
                            <Heading
                              title={subElem?.username}
                              fontSize="14px"
                              fontWeight="700"
                              color={theme.colors.white.normal}
                            />
                            <HiddenBelowLgDiv>
                              <Heading
                                title={`Credit limit: ${subElem?.creditLimit}`}
                                fontSize="12px"
                                fontWeight="400"
                                color={theme.colors.white.dark}
                              />
                            </HiddenBelowLgDiv>
                            <HiddenAboveLgDiv>
                              <Chip
                                title={subElem?.error ? "Error" : "Active"}
                                color={
                                  subElem?.error
                                    ? theme.colors.red.normal
                                    : theme.colors.green.normal
                                }
                                bgColor={theme.colors.grey.dark}
                              />
                            </HiddenAboveLgDiv>
                          </div>
                        </div>
                      </Col>
                      <Col lg md sm xs>
                        <Heading
                          title={convertToCurrencyAmount(
                            subElem?.profit || 0,
                            "eur"
                          )}
                          fontSize="14px"
                          fontWeight="700"
                          className="me-1"
                          color={theme.colors.white.normal}
                        />
                      </Col>
                      <HiddenBelowLgCol>
                        <Chip
                          title={subElem?.error ? "Error" : "Active"}
                          color={
                            subElem?.error
                              ? theme.colors.red.normal
                              : theme.colors.green.normal
                          }
                          bgColor={theme.colors.grey.dark}
                        />
                      </HiddenBelowLgCol>
                      <HiddenBelowLgCol lg md sm xs>
                        <Heading
                          title={convertToCurrencyAmount(
                            subElem?.last_bank || 0,
                            "eur"
                          )}
                          fontSize="14px"
                          fontWeight="700"
                          color={theme.colors.white.normal}
                        />
                      </HiddenBelowLgCol>
                      <HiddenBelowLgCol lg md sm xs>
                        <Heading
                          title={convertToCurrencyAmount(
                            subElem?.total_bank || 0,
                            "eur"
                          )}
                          fontSize="14px"
                          fontWeight="700"
                          color={theme.colors.white.normal}
                        />
                      </HiddenBelowLgCol>
                      <HiddenBelowLgCol lg md sm xs>
                        <Heading
                          title={subElem?.currentOB_number}
                          fontSize="14px"
                          fontWeight="700"
                          color={theme.colors.white.normal}
                        />
                      </HiddenBelowLgCol>
                      <Col
                        lg={1}
                        md={1}
                        sm={1}
                        style={{ textAlign: "center", position: "relative" }}
                      >
                        <Icon
                          onClick={() => handleDropDown(i)}
                          icon="iconamoon:menu-kebab-vertical-bold"
                          width="24"
                          height="24"
                          style={{
                            color: theme.colors.white.normal,
                            cursor: "pointer",
                          }}
                        />
                        <div
                          style={{
                            backgroundColor: theme.colors.grey.dark,
                            borderRadius: "8px",
                            overflow: "hidden",
                            position: "absolute",
                            zIndex: 10,
                            display: dropDown == i ? "block" : "none",
                            top: 36,
                            right: 12,
                            width: "max-content",
                            minWidth: "197px",
                            boxShadow:
                              "0px 0px 3px 0px rgba(255, 255, 255, 0.5)",
                          }}
                        >
                          {dropDownMenu?.map((dropDown, dropIndex) => {
                            return (
                              <HoverDiv
                                key={dropIndex}
                                onClick={() =>
                                  handleBookieDropdownMenuClick(
                                    dropDown?.id,
                                    subElem
                                  )
                                }
                              >
                                {dropDown?.id != 1 && (
                                  <Icon
                                    icon={dropDown?.icon}
                                    width="16"
                                    height="16"
                                    style={{
                                      color: theme.colors.white.normal,
                                      marginRight: "6px",
                                    }}
                                  />
                                )}
                                {dropDown?.id === 1 ? (
                                  subElem?.isEnabled ? (
                                    <Icon
                                      width="16"
                                      height="16"
                                      style={{
                                        color: theme.colors.white.normal,
                                        marginRight: "6px",
                                      }}
                                      icon="heroicons-outline:pause"
                                    />
                                  ) : (
                                    <Icon
                                      width="16"
                                      height="16"
                                      style={{
                                        color: theme.colors.white.normal,
                                        marginRight: "6px",
                                      }}
                                      icon="icon-park-outline:play"
                                    />
                                  )
                                ) : null}
                                 {dropDown?.id === 1 ? (
                                  subElem?.isEnabled ? (
                                    <Heading
                                  title='profile.pause'
                                  fontSize="14px"
                                  fontWeight="400"
                                  color={theme.colors.white.normal}
                                />
                                  ) : (
                                    <Heading
                                    title='profile.play'
                                    fontSize="14px"
                                    fontWeight="400"
                                    color={theme.colors.white.normal}
                                  />
                                  )
                                ) : null}
                                  {dropDown?.id != 1 && (
                                   <Heading
                                   title={dropDown?.title}
                                   fontSize="14px"
                                   fontWeight="400"
                                   color={theme.colors.white.normal}
                                 />
                                )}
                              
                              </HoverDiv>
                            );
                          })}
                        </div>
                      </Col>
                      <HiddenAboveLgCol lg={12} md={12} sm={12} xs={12}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div style={{ display: "flex", marginBottom: "4px" }}>
                            <Heading
                              title="profile.bank"
                              fontSize="14px"
                              fontWeight="400"
                              color={theme.colors.white.normal}
                              style={{ minWidth: "150px" }}
                            />
                            <Heading
                              title={subElem?.last_bank}
                              fontSize="14px"
                              fontWeight="400"
                              color={theme.colors.white.normal}
                            />
                          </div>
                          <div style={{ display: "flex", marginBottom: "4px" }}>
                            <Heading
                              title="profile.totalBank"
                              fontSize="14px"
                              fontWeight="400"
                              color={theme.colors.white.normal}
                              style={{ minWidth: "150px" }}
                            />
                            <Heading
                              title={subElem?.total_bank}
                              fontSize="14px"
                              fontWeight="400"
                              color={theme.colors.white.normal}
                            />
                          </div>
                          <div style={{ display: "flex", marginBottom: "4px" }}>
                            <Heading
                              title="profile.openBets"
                              fontSize="14px"
                              fontWeight="400"
                              color={theme.colors.white.normal}
                              style={{ minWidth: "150px" }}
                            />
                            <Heading
                              title={subElem?.currentOB_number}
                              fontSize="14px"
                              fontWeight="400"
                              color={theme.colors.white.normal}
                            />
                          </div>
                          <div style={{ display: "flex" }}>
                            <Heading
                              title="profile.creditLimit"
                              fontSize="14px"
                              fontWeight="400"
                              color={theme.colors.white.normal}
                              style={{ minWidth: "150px" }}
                            />
                            <Heading
                              title={subElem?.creditLimit}
                              fontSize="14px"
                              fontWeight="400"
                              color={theme.colors.white.normal}
                            />
                          </div>
                        </div>
                      </HiddenAboveLgCol>
                    </Row>
                    {subElem?.error && (
                      <div
                        style={{ paddingBottom: "16px", paddingLeft: "24px" }}
                      >
                        <Heading
                          title={subElem?.errorMessage}
                          fontSize="12px"
                          fontWeight="400"
                          color={theme.colors.red.normal}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </Accordion.Collapse>
        </Accordion>
      ) : (
        <div style={{ marginBottom: "18px", marginTop: "12px" }}>
          <div
            style={{
              backgroundColor: elem?.is_Active
                ? theme.colors.grey.dark
                : theme.colors.grey.normal,
              borderRadius: "8px",
              padding: "10px 14px",
            }}
          >
            <Row className="align-items-center">
              <Col lg={3} md={3} sm={3}>
                <div className="d-flex">
                  <div className="d-flex align-items-center">
                    <img
                      src={`https://flagcdn.com/${elem?.country?.toLowerCase()}.svg`}
                      width={24}
                      className="me-2"
                      style={{ filter: "grayscale(100%)" }}
                    />
                    <Heading
                      title={elem?.profileName}
                      fontSize="14px"
                      fontWeight="700"
                      color={theme.colors.white.normal}
                    />
                  </div>
                </div>
              </Col>
              <Col lg={8} md={8} sm={8} xs={8}>
                <Heading
                  title={convertToCurrencyAmount(340000, "eur")}
                  fontSize="14px"
                  fontWeight="700"
                  className="me-1"
                  color={theme.colors.white.normal}
                />
              </Col>
              <Col
                lg={1}
                md={1}
                sm={1}
                style={{ textAlign: "center", position: "relative" }}
              >
                <Icon
                  onClick={() => handleArchieveActivateProfile(elem?.profileId)}
                  icon="mage:reload"
                  width="24"
                  height="24"
                  style={{ color: theme.colors.white.normal }}
                />
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

export default AccordianProfile;
