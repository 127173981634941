// libraries
import { useMutation } from "react-query";
import { AxiosError } from "axios";

// utils and hooks
import request from "@utils/request";
import { ServerResponse } from "./types";
import { useErrorHandler } from "@hooks/useErrorHandler";

// Types
export interface CheckNicknameRequest {
  nickname: string;
}

export interface CheckNicknameResponse {
  message: string;
  // Add other relevant fields from the API response if necessary
}

export type NicknameCheckServerResponse = ServerResponse<CheckNicknameResponse>;

// Hook
export const useCheckNickname = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (res: NicknameCheckServerResponse) => void;
  onError?: (res: AxiosError<ServerResponse<unknown>>) => void;
} = {}) => {
  const { triggerError } = useErrorHandler();

  return useMutation<
    NicknameCheckServerResponse,
    ServerResponse<unknown> | undefined,
    CheckNicknameRequest
  >(["checkNickname"], async (data: CheckNicknameRequest) => {
    try {
      const response = await request<NicknameCheckServerResponse>({
        method: "post",
        url: "signup/nickcheck",
        data,
      });

      if (onSuccess) {
        onSuccess(response.data);
      }

      return response.data;
    } catch (error) {
      if (onError) {
        onError(error as AxiosError<ServerResponse<unknown>>);
      }
      triggerError({ error });
      throw error;
    }
  });
};
