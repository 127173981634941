// libraries
import { useQuery } from "react-query";
import { AxiosError } from "axios";

// misc
import request from "@utils/request";
import { ServerResponse, Address } from "./types";
import { useErrorHandler } from "@hooks/useErrorHandler";

export type GetAddressesResponse = ServerResponse<Address[]>;

export const getAddressesQKey = "addresses";

export const useGetAddresses = ({
  onError,
  onSuccess,
}: {
  onSuccess?: (res: Address[]) => void;
  onError?: (res: AxiosError<ServerResponse<unknown>>) => void;
} = {}) => {
  // variables
  const { triggerError } = useErrorHandler();

  // request
  const query = useQuery<Address[], ServerResponse<unknown> | undefined>(
    [getAddressesQKey],
    async () => {
      try {
        const addressesData = await request<GetAddressesResponse>({
          method: "get",
          url: `/billing/addresses`,
        });

        const data = addressesData.data.data;

        onSuccess && onSuccess(data);
        return data;
      } catch (error) {
        onError && onError(error);
        triggerError({ error });
        throw error;
      }
    }
  );

  // return
  return query;
};
