import React from "react";
import AlertModal from "./AlertModal";

interface PaymentModalProps {
  open: boolean;
  onClose(): void;
  closeBtnTitle?: string;
  actionBtnTitle?: string;
  onActionClick(): void;
  type?: "success" | "error";
}

const PaymentModal: React.FC<PaymentModalProps> = ({
  open,
  onClose,
  closeBtnTitle,
  actionBtnTitle,
  onActionClick,
  type,
}) => {
  // renders
  return (
    <AlertModal
      open={open}
      onClose={onClose}
      title={
        type === "success" ? "payments.payAccepted" : "payments.somethingWrong"
      }
      description={
        type === "success"
          ? "payments.transactionComplete"
          : "payments.transactionFailed"
      }
      closeBtnTitle={closeBtnTitle}
      actionBtnTitle={actionBtnTitle}
      onActionClick={onActionClick}
      type={type}
    />
  );
};

export default PaymentModal;
