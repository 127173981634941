import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Background from "@assets/Frame.svg";
import MobileBackground from "@assets/signupbg.svg";
import Logo from "@assets/newLogo.svg";
import LoginSection from "../../section/loginSection";
import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
const NewLogin = () => {
  return (
    <Container fluid className="p-0">
      <Row className="g-0 justify-content-center">
        <Col
          className="d-none d-lg-block"
          xl={7}
          lg={7}
          md={6}
          sm={6}
          xs={0}
          style={{
            backgroundImage: `url(${Background})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
            minHeight: "100vh",
            position: "relative",
          }}
        >
          <div style={{ position: "absolute", top: 100, left: 100 }}>
            <img src={Logo} width={300} height="auto" />
            <Heading
              title="Ready for the new era of betting?"
              fontSize="24px"
              fontWeight="700"
              style={{ width: "40%", marginTop: "24px", lineHeight: "1.2em" }}
              color={theme.colors.white.normal}
            />
          </div>
        </Col>
        <Col xl={5} lg={5} md={10} sm={10} xs={12}>
         
          <div
            className="d-block d-lg-none"
            style={{
              backgroundImage: `url(${MobileBackground})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top",
              minHeight: "13em",
              position: "absolute",
              left: 0,
              width: "100%",
            }}
          />
          <div
            style={{ position: "absolute", top: 50, left: 30 }}
            className="d-block d-lg-none"
          >
            <img src={Logo} width={180} height="auto" />
            <Heading
              title="Ready for the new era of betting?"
              fontSize="24px"
              className="d-block d-sm-none"
              fontWeight="700"
              style={{ width: "100%", marginTop: "24px", lineHeight: "1.2em" }}
              color={theme.colors.white.normal}
            />
          </div>
          <LoginSection />
        </Col>
      </Row>
    </Container>
  );
};

export default NewLogin;
