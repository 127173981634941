import { useMutation } from "react-query";
import { AxiosError } from "axios";
import { toast } from "react-toastify";

// misc
import request from "@utils/request";
import { ServerResponse } from "./types";
import { useErrorHandler } from "@hooks/useErrorHandler";
import { useTranslation } from "react-i18next";
import { MyAccountData } from "./types";

export const useEditMyAccount = ({
  onError,
  onSuccess,
}: {
  onSuccess?: (res: ServerResponse<any>) => void;
  onError?: (res: AxiosError<ServerResponse<unknown>>) => void;
} = {}) => {
  // variables
  const { triggerError } = useErrorHandler();
  const { t } = useTranslation();

  // request
  const query = useMutation<
    ServerResponse<unknown>,
    ServerResponse<unknown> | undefined,
    MyAccountData
  >(
    ["contact"],
    async (data: MyAccountData) => {
      try {
        const res = await request<ServerResponse<unknown>>({
          method: "patch",
          url: `/myaccount`,
          data,
        });

        toast.success(t("success.myAccount"));
        onSuccess && onSuccess(res.data);
        return res.data;
      } catch (error) {
        onError && onError(error);
        triggerError({ error });
      }
    }
  );

  // return
  return query;
};
