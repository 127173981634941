import CountryCodePicker from "@components/form/phoneInput";
import { TextField } from "@components/form/textField";
import { theme } from "@styles/themes";
import { Country } from "country-state-city";
import React from "react";
import { Col, Row } from "react-bootstrap";

const StepTwo = ({ control, setValue, errors }: any) => {
  return (
    <div>
      <Row className="g-2">
        <Col md={6} sm={12} xs={12}>
          <TextField
            control={control}
            name="nickname"
            bgColor={theme.colors.grey.dark}
            size="sm"
            placeholder="Username*"
            errors={errors.nickname}
            rules={{
              required: "This field is required",
              pattern: {
                value: /^[a-zA-Z0-9_.-]+$/,
                message:
                  "Usernames can only use letters, numbers, underscores, dashes, and periods, and cannot contain spaces.",
              },
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default StepTwo;
