import { PaymentsRecord } from "@api/types";
import { useGetPaymentSummary } from "@api/useGetPaymentSummary";
import { TextField } from "@components/form/textField";
import PaymentDetailInfo from "@components/paymentInfoCard";
import { Icon } from "@iconify/react";
import { theme } from "@styles/themes";
import { paymentMethodText } from "@utils/payments";
import React from "react";
import { useForm } from "react-hook-form";

const PaymentSeeMore = () => {
  const { data } = useGetPaymentSummary();
  const [visiblePayments, setVisiblePayments] = React.useState<
    PaymentsRecord[]
  >([]);
  const { control, watch } = useForm({
    defaultValues: {
      search: "",
    },
  });
  const watchSearch = watch("search");

  // effects
  React.useEffect(() => {
    let list = data.paymentsRecord;

    if (watchSearch) {
      list = list?.filter((item) =>
        item?.paymentAmount?.toString().includes(watchSearch)
      );
    }

    setVisiblePayments(list);
  }, [watchSearch, data]);
  return (
    <div style={{ marginBottom: "5em" }}>
      <div className="d-block d-md-none">
        <TextField
          placeholder="Search"
          name="search"
          size="md"
          control={control}
          endIcon={
            <Icon icon="majesticons:search-line" color="#BFBFBF" width={24} />
          }
        />
      </div>
      {visiblePayments
        ? visiblePayments?.map((elem, index) => (
            <PaymentDetailInfo
              chipBg={theme.colors.grey.dark}
              key={index}
              amount={elem.paymentAmount}
              paymentMethod={paymentMethodText[elem.method]}
              timestamp={elem.uploadTimestamp}
              status={elem.status}
              invoiceUrl={elem.invoiceUrl}
            />
          ))
        : null}
    </div>
  );
};

export default PaymentSeeMore;
