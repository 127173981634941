import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import React, { useContext, useEffect } from "react";
import StepOne from "./stepOne";
import StepTwo from "./stepTwo";
import StepThree from "./stepThree";
import { TabContext } from "@context/TabContext";
import { usePayment } from "@context/PaymentContext";
import { useNavigate } from "react-router-dom";

const Tabination = ({ billingRef, submitForm }) => {
  // variables
  const { currency, amount, startPayment } = usePayment();
  const tabContext = useContext(TabContext);
  const { activeTab } = tabContext;
  const navigate = useNavigate();

  const handleClick = () => {
    switch (activeTab) {
      case 2:
        startPayment();
        break;
      default:
        submitForm();
        break;
    }
  };

  // effects

  // returns
  return (
    <div className="mb-5">
      <div className="d-none d-md-block mb-3">
        <div className="mb-1 mt-4">
          <Heading
            title={`Step ${activeTab + 1} of 3`}
            color={theme.colors.white.dark}
            fontSize="14px"
            fontWeight="400"
          />
        </div>
        <Heading
          className="mb-4"
          title={
            activeTab == 0
              ? "Billing details"
              : activeTab == 1
              ? "Payment method"
              : "Summary"
          }
          color={theme.colors.white.normal}
          fontSize="24px"
          fontWeight="700"
        />
      </div>
      <div className="d-block d-md-none">
        <div className="d-flex align-items-center mb-3">
          {[...Array(3)].map((elem: any, index: number) => {
            return (
              <div
                // onClick={() => setSignUpTab(index)}
                key={index}
                style={{
                  margin: "0 10px",
                  width: "100%",
                  borderBottom: `3px solid ${
                    activeTab == index
                      ? theme.colors.main.normal
                      : theme.colors.grey.normal
                  }`,
                }}
              />
            );
          })}
        </div>
      </div>

      <div>
        {activeTab == 0 ? (
          <StepOne billingRef={billingRef} />
        ) : activeTab == 1 ? (
          <StepTwo />
        ) : (
          <StepThree handleClick={handleClick} />
        )}
      </div>
    </div>
  );
};

export default Tabination;
