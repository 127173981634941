// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from './locales/en/translation.json'
import translationES from "./locales/es/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
};

const userLanguage = navigator.language.split("-")[0]; // Get the user's language code (e.g., "en", "es")

i18n.use(initReactI18next).init({
  resources,
  lng: userLanguage, // Set the user's language as the default language
  fallbackLng: "en", // Fallback to English if translation not available for user's language
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
