// misc
import { layout } from "./layout";

/**
 * to add transparency to an hexa color, see this
 * https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
 */

export const theme = {

  typography: {
    fontFamily: {
      primary: "'Open Sans', sans-serif",
      secondary: "'Roboto', sans-serif",
      heading: "'Poppins', sans-serif",
    },
    fontSizes: {
      heading1: '29px',
      heading2: '24px',
      heading3: '20px',
      body1: '17px',
      body2: '14px',
      caption: '12px'
    },
    fontWeights: {
      regular: 400,
      medium: 500,
      bold: 700,
    },
    lineHeights: {
      normal: 1.5,
      heading: 1.2,
    },
    letterSpacings: {
      normal: 'normal',
      wide: '0.05em',
    },
  },

  colors: {
    // Grey
    grey: {
      light: '#ededed',
      lightHover: '#e4e4e4',
      lightActive: '#c7c7c7',
      normal: '#4a4a4a',
      normalHover: '#434343',
      normalActive: '#3b3b3b',
      dark: '#383838',
      darkHover: '#2c2c2c',
      darkActive: '#212121',
      darker: '#1A1A1A'
    },
    white: {
      light: '#ffffff',
      lightHover: '#ffffff',
      lightActive: '#ffffff',
      normal: '#ffffff',
      normalHover: '#e6e6e6',
      normalActive: '#cccccc',
      dark: '#bfbfbf',
      darkHover: '#999999',
      darkActive: '#737373',
      darker: '#595959'
    },
    main: {
      light: '#e6fefa',
      lightHover: '#d9fdf8',
      lightActive: '#b1fbf1',
      normal: '#04f1d1',
      normalHover: '#04d9bc',
      normalActive: '#03c1a7',
      dark: '#03b59d',
      darkHover: '#02917d',
      darkActive: '#026c5e',
      darker: '#015449'
    },
    green: {
      light: '#edf8e8',
      lightHover: '#e3f4dc',
      lightActive: '#c6e8b6',
      normal: '#47b615',
      normalHover: '#40a413',
      normalActive: '#399211',
      dark: '#358910',
      darkHover: '#2b6d0d',
      darkActive: '#205209',
      darker: '#194007'
    },
    blue: {
      light: '#e6e6ff',
      lightHover: '#d9d9ff',
      lightActive: '#b0b0ff',
      normal: '#0000ff',
      normalHover: '#0000e6',
      normalActive: '#0000cc',
      dark: '#0000bf',
      darkHover: '#000099',
      darkActive: '#000073',
      darker: '#000059'
    },
    yellow: {
      light: '#fffbeb',
      lightHover: '#fff9e1',
      lightActive: '#fff3c1',
      normal: '#ffd936',
      normalHover: '#e6c331',
      normalActive: '#ccae2b',
      dark: '#bfa329',
      darkHover: '#998220',
      darkActive: '#736218',
      darker: '#594c13'
    },
    red: {
      light: '#ffe6e6',
      lightHover: '#ffd9d9',
      lightActive: '#ffb0b0',
      normal: '#F54242',
      normalHover: '#e60000',
      normalActive: '#cc0000',
      dark: '#bf0000',
      darkHover: '#990000',
      darkActive: '#730000',
      darker: '#590000'
    },


    // main
    primary: "#11F3D2",
    get primary50() {
      return this.primary + 80;
    },
    secondary: "#1F1F27",
    primaryBackground: "#3D3D3D",
    success: "#78D64B",
    error: "#F9423A",
    warning: "#FF9E1C",
    gray: "#808080",
    silver: "#A5A5A5",
    // white: "#FFFFFF",
    alto: "#D6D6D6",

    // other
    text: "#ffffff",
    get text50() {
      return this.text + 80;
    },
    transparent: "transparent",
  },
  layout: { ...layout },
};
