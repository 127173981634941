// libraries
import { useMutation } from "react-query";
import { AxiosError } from "axios";

// misc
import request from "@utils/request";
import { ServerResponse, UpdateBookiesParams } from "./types";
import { useErrorHandler } from "@hooks/useErrorHandler";

interface Data {
  bookie: Bookie;
}
interface Bookie {
  accountId: string;
  birthday: number;
  bookie: string;
  closingDate: number;
  creditLimit: string;
  error: boolean;
  errorType: number;
  isEnabled: boolean;
  isRunning: boolean;
  password: string;
  profileId: string;
  stake: number;
  username: string;
}
export type UpdateBookiesResponse = ServerResponse<Data>; // Updated to use Data type

export const useUpdateBookies = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (res: Data) => void; // Updated to use Data type
  onError?: (error: AxiosError<ServerResponse<unknown>>) => void;
}) => {
  // variables
  const { triggerError } = useErrorHandler();

  // mutation
  const mutation = useMutation<Data, AxiosError<ServerResponse<unknown>>, UpdateBookiesParams>(
    async (params: UpdateBookiesParams) => {
      const { bookieId, ...updateData } = params;
      try {
        const response = await request<UpdateBookiesResponse>({
          method: "patch",
          url: `/bookies/${bookieId}`,
          data: updateData,
        });

        const data = response.data.data as Data; // Cast to Data
        onSuccess && onSuccess(data);
        return data;
      } catch (error) {
        onError && onError(error);
        triggerError({ error });
        throw error;
      }
    }
  );

  // return
  return mutation;
};
