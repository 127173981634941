import React from 'react'
import BookieAddForm from '../../../section/profileSection/profileBookie/bookieAdd'

const ProfileBookieAdd = () => {
  return (
    <div>
      <BookieAddForm/>
    </div>
  )
}

export default ProfileBookieAdd
