// libraries
import { Address } from "@api/types";
import { Icon } from "@iconify/react";
import { theme } from "@styles/themes";
import React from "react";
import styled from "styled-components";
import Heading from "./font/heading";
import { useDeleteAddress } from "@api/useDeleteAddress";
import { UseFormRegister } from "react-hook-form";
import { RadioButton } from "./form/radioButton";

// misc

// types
export type AddressItemProps = {
  onClick: () => void;
  onDetete?: () => void;
  item: Address;
  registerProps: UseFormRegister<any>;
  isChecked?: boolean;
  hideDelete?: boolean;
};

export const AddressItem: React.FC<AddressItemProps> = ({
  onClick,
  item,
  registerProps,
  isChecked,
  hideDelete,
}) => {
  // variables
  const { mutate: deleteAddress } = useDeleteAddress();

  // functions

  // renders
  return (
    <StyledDivBox onClick={onClick}>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-start">
          <RadioWrapper>
            <RadioButton
              name="billingAddress"
              value={item?.billingId}
              checked={isChecked}
              {...registerProps}
            />
          </RadioWrapper>
          <div className="mx-3" style={{ overflowWrap: "anywhere" }}>
            <Heading
              fontSize="14px"
              title={`${item?.name} ${item?.surname}` || "N/A"}
              fontWeight="700"
              color={theme.colors.white.normal}
            />
            <Heading
              className="mt-1"
              fontSize="14px"
              title={`${item?.address} ${item?.addressExtra},${item?.city} , ( ${item?.country} )`}
              fontWeight="400"
              color={theme.colors.white.dark}
            />
          </div>
        </div>
        {!hideDelete && (
          <div>
            <Icon
              onClick={() => deleteAddress({ id: item?.billingId })}
              icon="material-symbols-light:delete-outline"
              color="red"
              width="24px"
              height="24px"
            />
          </div>
        )}
      </div>
    </StyledDivBox>
  );
};

const StyledDivBox = styled.div`
  border: 1px solid ${theme.colors.white.dark};
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  cursor: pointer;
`;

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
`;
