import Heading from "@components/font/heading";
import { SearchSelectBox } from "@components/form/searchSelect";
import { SelectBox } from "@components/form/selectBox";
import { TextField } from "@components/form/textField";
import { Icon } from "@iconify/react";
import { theme } from "@styles/themes";
import { languages } from "@utils/countries";
import { hours, minutes, monthDays, years } from "@utils/dateMonthTime";
import { Country, ICountry } from "country-state-city";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Colon from "@assets/icons/colon.svg";
import { Button } from "@components/button/button";
import { useCreateBookies } from "@api/useCreateBookies";
import { bookies } from "@utils/variables";
import { TabContext } from "@context/TabContext";
import { useGetProfiles } from "@api/useGetProfiles";
import CustomToast from "@components/toastBar/CustomToast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useUpdateBookies } from "@api/useUpdateBookies";
import { UpdateBookiesParams } from "@api/types";
import { ToastContext } from "@context/ToastContext";
import styled from "styled-components";

type Scheduler = {
  start_hour: string;
  start_min: string;
  end_hour: string;
  end_min: string;
};

type AddressForm = {
  username: string;
  password: string;
  bookie: string;
  credit_limit: string;
  month: string;
  date: string;
  year: string;
  closing_month: string;
  closing_date: string;
  closing_year: string;
  stake: number;
  start_hour: string;
  start_min: string;
  end_hour: string;
  end_min: string;
  scheduler: Scheduler[];
};

const StyledForm = styled.form`
  margin-bottom: 88px;

  @media (min-width: 576px) {
    margin-bottom: 0; // Remove margin-bottom above 586px
  }
`;

const BookieAddForm = ({ setAddBookieOpen }: any) => {
  const { profileIdSelected, profileCountry } = useContext(TabContext);
  const { setToastMessage,toastMessage,setToastVisible,toastVisible } = useContext(ToastContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { bookie, accountId, isEnabled } = location.state || {}; 
  const { refetch } = useGetProfiles({});
  const [toastOpen, setToastOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false); 
  const { mutate } = useCreateBookies({
    onSuccess: (data) => {
      setAddBookieOpen(false);
      refetch();
      setToastOpen(true);
    },
    onError: (error) => {
      console.error("Error creating bookies:", error);
    },
  });
  const { mutate: updateBookieMutate, data: editBookieData } = useUpdateBookies(
    {
      onSuccess: (data) => {
        if (isSubmitted) { 
          refetch();
          setToastMessage("Bookie updated");
          setToastVisible(true);
        }
      },
      onError: (error) => {
        console.error("Error updating bookie:", error);
      },
    }
  );
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<AddressForm>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "scheduler",
  });

  const onSubmit = async (data) => {
    const formData = {
      username: data.username,
      password: data.password,
      bookie: data.bookie,
      birthday: new Date(
        `${data.year}-${data.month}-${data.date.padStart(2, "0")}`
      ).getTime(),
      closingDate: new Date(
        `${data.closing_year}-${
          data.closing_month
        }-${data?.closing_date?.padStart(2, "0")}`
      ).getTime(),
      profileId: profileIdSelected,
      isEnabled: true,
      isRunning: true,
      country: profileCountry,
      creditLimit: data?.credit_limit,
      stake: Number(data?.stake),
      scheduler: data?.scheduler?.map((field: any) => ({
        turnOnTime:
          parseInt(field.start_hour) * 3600 + parseInt(field.start_min) * 60,
        turnOffTime:
          parseInt(field.end_hour) * 3600 + parseInt(field.end_min) * 60,
      })),
    };
   
    try {
      if (id != "edit") {
        await mutate(formData);
        await setToastMessage("Bookie added");
      } else {
       await setIsSubmitted(true);

        const params: UpdateBookiesParams = {
          bookieId: accountId, 
          ...formData,
        };
        await updateBookieMutate(params);
        await setToastMessage("Bookie Updated");
      }
      // Only navigate if screen width is less than 575px
      if (window?.innerWidth < 575) {
        await navigate(-1);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const watchCountry = watch("bookie");
  const getDatesForMonth = (monthValue) => {
    const month = Object.values(monthDays).find((m) => m.value === monthValue);
    return month ? month.days : [];
  };

  // Use a default month if none is selected
  const selectedMonth = watch("month") || "01";
  const datesForSelectedMonth = getDatesForMonth(selectedMonth);

  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  useEffect(() => {
    if (id == "edit") {
      updateBookieMutate({
        bookieId: accountId,
        bookie: bookie,
        isEnabled: isEnabled,
      });
    }
  }, [id]);

  useEffect(() => {
    const bookiData = editBookieData;

    if (bookiData?.bookie) {
      const date = new Date(bookiData?.bookie?.birthday);
      const closingDate = new Date(bookiData?.bookie?.closingDate);

      // Extracting day, month, and year
      const day = date.getUTCDate().toString(); // Get the day of the month
      const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Get the month (0-indexed, so add 1) and ensure it's in double digits
      const year = date.getUTCFullYear().toString(); // Get the full year
      const closingDay = closingDate.getUTCDate().toString(); // Get the day of the month
      const closingMonth = (closingDate.getUTCMonth() + 1)
        .toString()
        .padStart(2, "0"); // Get the month (0-indexed, so add 1) and ensure it's in double digits
      const closingYear = closingDate.getUTCFullYear().toString(); // Get the full year
      setValue("username", bookiData?.bookie?.username);
      setValue("password", bookiData?.bookie?.password);
      setValue("bookie", bookiData?.bookie?.bookie);
      setValue("credit_limit", bookiData?.bookie?.creditLimit);
      setValue("stake", bookiData?.bookie?.stake);
      setValue("month", month);
      setValue("date", day);
      setValue("year", year);
      setValue("closing_month", closingMonth);
      setValue("closing_date", closingDay);
      setValue("closing_year", closingYear);
      // setValue("start_hour", Math.floor(startTime / 3600).toString().padStart(2, '0'));
      // setValue("start_min", Math.floor((startTime % 3600) / 60).toString().padStart(2, '0'));
      // setValue("end_hour", Math.floor(endTime / 3600).toString().padStart(2, '0'));
      // setValue("end_min", Math.floor((endTime % 3600) / 60).toString().padStart(2, '0'));
    }
  }, [editBookieData, setValue]);
  const bookiesEdit = getValues("bookie") || "";

  return (
    <>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-3 mt-1 mb-4">
          <Col lg={6} md={6} sm={6} xs={6}>
            <TextField
              control={control}
              name="username"
              bgColor={theme.colors.grey.dark}
              size="sm"
              placeholder="Username*"
              errors={errors.username}
              rules={{ required: "This field is required" }}
            />
          </Col>
          <Col lg={6} md={6} sm={6} xs={6}>
            <TextField
              control={control}
              name="password"
              bgColor={theme.colors.grey.dark}
              size="sm"
              placeholder="Password*"
              errors={errors.password}
              rules={{ required: "This field is required" }}
            />
          </Col>
          <Col lg={6} md={6} sm={6} xs={6}>
            <SearchSelectBox
              bgColor={theme.colors.grey.dark}
              size="sm"
              options={bookies}
              placeholder="Bookie*"
              control={control}
              name="bookie"
              setValue={setValue}
              errors={errors.bookie}
              watch={watchCountry}
              rules={{ required: "This field is required" }}
              defaultValue={id == "edit" ? bookiesEdit : ""} // Pass default value here
            />
          </Col>
          <Col lg={6} md={6} sm={6} xs={6}>
            <TextField
              control={control}
              name="credit_limit"
              bgColor={theme.colors.grey.dark}
              size="sm"
              placeholder="Credit Limit"
              errors={errors.credit_limit}
              rules={{ required: false }}
            />
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Heading
              fontSize="14px"
              title="Birth date*"
              fontWeight="400"
              color={theme.colors.white.dark}
            />
          </Col>
          <Col lg={4} md={4} sm={4} xs={4}>
            <Controller
              name="date"
              control={control}
              defaultValue={
                id == "edit"
                  ? getValues("date")
                  : datesForSelectedMonth[0]?.value || "0"
              }
              render={({ field }) => (
                <SelectBox
                  {...field}
                  bgColor={theme.colors.grey.dark}
                  defaultValue={
                    id == "edit"
                      ? getValues("date")
                      : datesForSelectedMonth[0]?.value || ""
                  }
                  size="sm"
                  options={datesForSelectedMonth}
                  placeholder={t("profile.date")}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Col>
          <Col lg={4} md={4} sm={4} xs={4}>
            <Controller
              name="month"
              control={control}
              defaultValue={monthDays[1].value}
              render={({ field }) => (
                <SelectBox
                  {...field}
                  bgColor={theme.colors.grey.dark}
                  defaultValue={
                    id == "edit" ? getValues("month") : monthDays[1].value
                  }
                  size="sm"
                  options={Object.values(monthDays)}
                  placeholder={t("profile.month")}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Col>
          <Col lg={4} md={4} sm={4} xs={4}>
            <Controller
              name="year"
              control={control}
              defaultValue={monthDays[1].value}
              render={({ field }) => (
                <SelectBox
                  {...field}
                  bgColor={theme.colors.grey.dark}
                  defaultValue={id == "edit" ? getValues("year") : "2024"}
                  size="sm"
                  options={Object.values(years)}
                  placeholder={t("profile.year")}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Heading
              fontSize="14px"
              title="Closing date"
              fontWeight="400"
              color={theme.colors.white.dark}
            />
          </Col>
          <Col lg={4} md={4} sm={4} xs={4}>
            <Controller
              name="closing_date"
              control={control}
              defaultValue={datesForSelectedMonth[0]?.value || "1"}
              render={({ field }) => (
                <SelectBox
                  {...field}
                  bgColor={theme.colors.grey.dark}
                  defaultValue={
                    id == "edit"
                      ? getValues("closing_date")
                      : datesForSelectedMonth[0]?.value || "1"
                  }
                  size="sm"
                  options={datesForSelectedMonth}
                  placeholder={t("profile.date")}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Col>
          <Col lg={4} md={4} sm={4} xs={4}>
            <Controller
              name="closing_month"
              control={control}
              defaultValue={monthDays[1].value}
              render={({ field }) => (
                <SelectBox
                  {...field}
                  bgColor={theme.colors.grey.dark}
                  defaultValue={
                    id == "edit"
                      ? getValues("closing_month")
                      : monthDays[1].value
                  }
                  size="sm"
                  options={Object.values(monthDays)}
                  placeholder={t("profile.month")}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Col>
          <Col lg={4} md={4} sm={4} xs={4}>
            <Controller
              name="closing_year"
              control={control}
              defaultValue={monthDays[1].value}
              render={({ field }) => (
                <SelectBox
                  {...field}
                  bgColor={theme.colors.grey.dark}
                  defaultValue={
                    id == "edit" ? getValues("closing_year") : "2024"
                  }
                  size="sm"
                  options={Object.values(years)}
                  placeholder={t("profile.year")}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <div
              className="d-flex align-items-center"
              style={{
                borderBottom: "1px solid #fff",
                width: "max-content",
                cursor: "pointer",
              }}
              onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
            >
              <Heading
                fontSize="14px"
                title="Advanced options"
                fontWeight="400"
                color={theme.colors.white.normal}
              />
              <Icon
                icon="solar:alt-arrow-down-line-duotone"
                color="#fff"
                width={20}
                style={{
                  marginLeft: "4px",
                  transform: showAdvancedOptions
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                  transition: "transform 0.3s ease",
                }}
              />
            </div>
          </Col>
          {showAdvancedOptions && (
            <>
              <Col lg={12} md={12} sm={12} xs={12}>
                <TextField
                  control={control}
                  name="stake"
                  bgColor={theme.colors.grey.dark}
                  size="sm"
                  placeholder="Stake"
                  errors={errors.stake}
                  rules={{ required: "This field is required" }}
                />
              </Col>
              {fields?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <Heading
                        fontSize="14px"
                        title={`Time slot ${index + 1}`}
                        fontWeight="400"
                        color={theme.colors.white.dark}
                      />
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      sm={4}
                      xs={4}
                      className="align-items-center d-flex"
                    >
                      <Heading
                        fontSize="14px"
                        title="Start"
                        fontWeight="400"
                        color={theme.colors.white.dark}
                      />
                    </Col>
                    <Col lg={8} md={8} sm={8} xs={8}>
                      <div className="d-flex justify-content-between align-items-center">
                        <Controller
                          name={`scheduler.${index}.start_hour`}
                          control={control}
                          defaultValue={hours[1].value}
                          render={({ field }) => (
                            <SelectBox
                              style={{ width: "100%" }}
                              {...field}
                              bgColor={theme.colors.grey.dark}
                              defaultValue="00"
                              size="sm"
                              options={Object.values(hours)}
                              placeholder={t("profile.hour")}
                              onChange={(value) => field.onChange(value)}
                            />
                          )}
                        />
                        <img src={Colon} style={{ margin: "0px 20px" }} />
                        <Controller
                          name={`scheduler.${index}.start_min`}
                          control={control}
                          defaultValue={minutes[1].value}
                          render={({ field }) => (
                            <SelectBox
                              {...field}
                              style={{ width: "100%" }}
                              bgColor={theme.colors.grey.dark}
                              defaultValue="00"
                              size="sm"
                              options={Object.values(minutes)}
                              placeholder={t("profile.minute")}
                              onChange={(value) => field.onChange(value)}
                            />
                          )}
                        />
                      </div>
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      sm={4}
                      xs={4}
                      className="align-items-center d-flex"
                    >
                      <Heading
                        fontSize="14px"
                        title="End"
                        fontWeight="400"
                        color={theme.colors.white.dark}
                      />
                    </Col>
                    <Col lg={8} md={8} sm={8} xs={8}>
                      <div className="d-flex justify-content-between align-items-center">
                        <Controller
                          name={`scheduler.${index}.end_hour`}
                          control={control}
                          defaultValue={hours[1].value}
                          render={({ field }) => (
                            <SelectBox
                              style={{ width: "100%" }}
                              {...field}
                              bgColor={theme.colors.grey.dark}
                              defaultValue="00"
                              size="sm"
                              options={Object.values(hours)}
                              placeholder={t("profile.hour")}
                              onChange={(value) => field.onChange(value)}
                            />
                          )}
                        />
                        <img src={Colon} style={{ margin: "0px 20px" }} />
                        <Controller
                          name={`scheduler.${index}.end_min`}
                          control={control}
                          defaultValue={minutes[1].value}
                          render={({ field }) => (
                            <SelectBox
                              {...field}
                              style={{ width: "100%" }}
                              bgColor={theme.colors.grey.dark}
                              defaultValue="00"
                              size="sm"
                              options={Object.values(minutes)}
                              placeholder={t("profile.minute")}
                              onChange={(value) => field.onChange(value)}
                            />
                          )}
                        />
                      </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <Button title="Remove" onClick={() => remove(index)} />
                    </Col>
                  </React.Fragment>
                );
              })}
            </>
          )}

          {showAdvancedOptions && (
            <Col lg={12} md={12} sm={12} xs={12}>
              <Button
                title="Add Scheduler"
                type="button"
                onClick={() =>
                  append({
                    start_hour: "00",
                    start_min: "00",
                    end_hour: "00",
                    end_min: "00",
                  })
                }
                outline={true}
                style={{ fontSize: "14px", fontWeight: "700" }}
              />
            </Col>
          )}
        </Row>
        <div className="d-none d-sm-block" style={{ textAlign: "right" }}>
          <Button
            title="profile.add"
            outline={false}
            style={{ fontSize: "14px", fontWeight: "700" }}
          />
        </div>
        <div
          style={{ position: "fixed", right: 0, left: 0, bottom: 0 }}
          className="d-block d-sm-none"
        >
          <div
            className="d-flex align-items-center"
            style={{
              padding: "16px 24px",
              backgroundColor: theme.colors.grey.darker,
              borderTop: `0.5px solid ${theme.colors.grey.lightActive}`,
            }}
          >
            {id != "edit" && (
              <Button
                title="addEditUser.cancelButton"
                outline={true}
                style={{
                  fontSize: "14px",
                  fontWeight: "700",
                  width: "100%",
                  marginRight: "16px",
                }}
              />
            )}
            <Button
              title={id == "edit" ? "profile.edit" : "profile.add"}
              outline={false}
              style={{ fontSize: "14px", fontWeight: "700", width: "100%" }}
            />
          </div>
        </div>
      </StyledForm>
      <CustomToast
        isOpen={toastOpen}
        message={toastMessage}
        onClose={() => setToastOpen(false)}
      />
    </>
  );
};

export default BookieAddForm;
