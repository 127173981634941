import React from 'react'
import Head from '../myDataSection/head'
import { StyledDiv } from '../billingDetailsSection'
import AddForm from './form'

const BillingDetailsAddSection = () => {
  return (
    <StyledDiv>
      <Head title="profile.billingDetails"/>
      <AddForm/>
    </StyledDiv>
  )
}

export default BillingDetailsAddSection
