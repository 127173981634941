import React from "react";
import Header from "./header";
import { Col, Container, Row } from "react-bootstrap";
import Background from "@assets/signupbg.svg";
import Logo from "@assets/newLogo.svg";
import SignUpForm from "./signUpForm";
import Stepper from "./stepper";
const SignUpSection = () => {
  return (
    <Container fluid className="p-0">
      <div
        className="d-none d-sm-block"
        style={{
          backgroundImage: `url(${Background})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
          minHeight: "10em",
          position: "relative",
        }}
      />
      <img
        className="d-none d-sm-block"
        src={Logo}
        width={140}
        height="auto"
        style={{ position: "absolute", top: 40, left: 70 }}
      />
      <Row className="g-0 justify-content-center align-items-center">
        <Col sm={12} xs={12} className="d-block d-sm-none">
          <Stepper />
        </Col>
        <Col
          xl={6}
          lg={6}
          md={10}
          sm={10}
          xs={10}
          className="d-none d-sm-block"
        >
          <SignUpForm />
        </Col>
      </Row>
    </Container>
  );
};

export default SignUpSection;
