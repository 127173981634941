import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { Icon } from "@iconify/react";
import logo from "@assets/newLogo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "@context/AuthContext";
import PaymentModal from "@components/modal/PaymentModal";
import LogOutModal from "@components/modal/LogOutModal";
import { useTranslation } from "react-i18next";
import CopyModal from "@components/modal/CopyModal";
import ContactUsModal from "../../section/profileSection/contactUsModal";
import { theme } from "@styles/themes";
const Paths = [
  { name: "Dashboard", link: "/profile" },
  { name: "Stats", link: "/stats" },
  { name: "Credits", link: "/payments" },
];
const bottomPaths = [
  { name: "Contact us", link: "/stats" },
  { name: "Log out", link: "" },
];
const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location?.pathname;
  const [selected, setSelected] = useState(null); // Track the selected option
  const [logOut, setLogOut] = useState<boolean>(false); // Track the selected option
  const [contactOpen, setContactOpen] = useState<boolean>(false); // Track the selected option
  const { resetAuth } = useAuth();
  const handleSelect = async (index: number) => {
    await navigate("/payments");
    await setSelected(index); // Update the selected option
  };
  const handleClickLogout = () => {
    resetAuth();
  };
  return (
    <div
      style={{
        height: "100vh",
        padding: "20px",
        borderRight: "0.2px solid #c7c7c7",
        position: "sticky",
        top: 0,
      }}
    >
      <img src={logo} style={{ width: "8rem" }} alt="logo" />
      <Nav className="flex-column mt-4">
        {Paths?.map((option, index) => (
          <Nav.Item
            key={index}
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: "-6px",
                width: "4px",
                height: "100%",
                background:
                  pathname == option.link &&
                  "linear-gradient(153.43deg, #015449 0%, #04F1D1 83.33%)",

                borderRadius: "0px 8px 8px 0px",
              }}
            />
            <div
              style={{
                backgroundColor: pathname.includes(option.link)
                  ? theme.colors.grey.darkActive
                  : "transparent",
                display: "flex",
                borderRadius: "8px",
                alignItems: "center",
                marginLeft: "10px",
                padding: "0px 10px",
                width: "100%",
              }}
            >
              <Icon
                icon={
                  index === 0
                    ? "majesticons:home-line"
                    : index === 1
                    ? "heroicons-outline:chart-bar"
                    : "heroicons:circle-stack"
                }
                color={pathname.includes(option.link) ? "#04F1D1" : "#FFFFFF"}
                width={32}
                style={{ marginRight: "2px" }}
              />
              {/* Nav.Link with conditional styles for selected option */}
              <Nav.Link
                onClick={() => navigate(option.link)}
                style={{
                  color: pathname.includes(option.link) ? "#04F1D1" : "#FFFFFF",
                  paddingLeft: "0",
                  // Highlight selected option
                  borderRadius: "8px",
                  padding: "10px 15px",
                  zIndex: 1,
                  position: "relative",
                  width: "100%",
                  transition: "background-color 0.3s ease", // Smooth transition
                }}
              >
                {option.name}
              </Nav.Link>
            </div>
          </Nav.Item>
        ))}
      </Nav>
      <Nav
        className="flex-column mt-4"
        style={{ position: "absolute", bottom: 10 }}
      >
        {bottomPaths?.map((option, index) => (
          <Nav.Item
            key={index}
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                backgroundColor: "transparent",
                display: "flex",
                borderRadius: "8px",
                alignItems: "center",
                marginLeft: "10px",
                padding: "0px 10px",
                width: "100%",
              }}
            >
              <Icon
                icon={
                  index === 0
                    ? "material-symbols:mail-outline"
                    : "iconamoon:exit-fill"
                }
                color={index === 1 ? "#F54242" : "#FFFFFF"}
                width={24}
                style={{ marginRight: "2px" }}
              />
              {/* Nav.Link with conditional styles for selected option */}
              <Nav.Link
                onClick={() => {
                  option.name == "Log out"
                    ? setLogOut(true)
                    : setContactOpen(true);
                }}
                style={{
                  color: index === 1 ? "#F54242" : "#FFFFFF",
                  paddingLeft: "0",
                  fontSize: "14px",
                  fontWeight: "400",
                  borderRadius: "8px",
                  padding: "10px 15px",
                  zIndex: 1,
                  position: "relative",
                  width: "100%",
                  transition: "background-color 0.3s ease", // Smooth transition
                }}
              >
                {option.name}
              </Nav.Link>
            </div>
          </Nav.Item>
        ))}
      </Nav>
      <LogOutModal
        open={logOut}
        onActionClick={() => handleClickLogout()}
        onClose={() => setLogOut(false)}
      />
      <CopyModal
        open={contactOpen}
        setOpen={setContactOpen}
        heading="profile.contactUs"
        mainContentStyle={{ padding: "0" }}
        mainContent={<ContactUsModal setContactOpen={setContactOpen} />}
      />
    </div>
  );
};

export default SideBar;
