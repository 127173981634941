import { theme } from "@styles/themes";
import styled from "styled-components";
import React from "react";
// Define the type for the props, ensuring isChecked is available for StyledRadio and Dot
interface RadioProps {
  isChecked: boolean;
}

const RadioWrapper = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

const HiddenRadio = styled.input.attrs({ type: "radio" })`
  appearance: none;
  position: absolute;
  opacity: 0;
  pointer-events: none;
`;

const StyledRadio = styled.div<RadioProps>`
  width: 16px;
  height: 16px;
  border: ${(props) =>
    props.isChecked
      ? `2px solid ${theme.colors.main.normal}`
      : "2px solid white"};
  border-radius: 50%; /* Makes it circular */
  background-color: ${(props) =>
    props.isChecked ? theme.colors.grey.darker : "transparent"};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Dot = styled.div<RadioProps>`
  width: 6px;
  height: 6px;
  background-color: ${theme.colors.main.normal}; /* The dot in the middle */
  border-radius: 50%;
  visibility: ${(props) => (props.isChecked ? "visible" : "hidden")};
`;

export const RadioButton = ({ checked, ...props }: any) => {
  return (
    <RadioWrapper>
      <HiddenRadio checked={checked} {...props} />
      <StyledRadio isChecked={checked}>
        <Dot isChecked={checked} />
      </StyledRadio>
    </RadioWrapper>
  );
};
