import { useMutation } from "react-query";
import { AxiosError } from "axios";

// misc
import request from "@utils/request";
import { ServerResponse } from "./types";
import { useErrorHandler } from "@hooks/useErrorHandler";

export const useConfirmSignup = ({
  onError,
  onSuccess,
}: {
  onSuccess?: (res: ServerResponse<null>) => void;
  onError?: (res: AxiosError<ServerResponse<unknown>>) => void;
} = {}) => {
  const { triggerError } = useErrorHandler();

  const query = useMutation<
    ServerResponse<null>,
    ServerResponse<unknown> | undefined,
    string // token parameter type
  >(["confirmSignup"], async (token: string) => {
    try {
      const res = await request<ServerResponse<null>>({
        method: "post",
        url: `/signup/confirm/${token}`,
      });

      onSuccess && onSuccess(res.data);
      return res.data;
    } catch (error) {
      onError && onError(error);
      triggerError({ error });
      throw error;
    }
  });

  return query;
};
