import React from "react";
import BookieCard from "./bookieCard";

const BookieRecordSection = () => {
  return (
    <div>
      {["Win", "Lost", "Void", "Pending"].map((elem, index) => {
        return <BookieCard key={index} type={elem} />;
      })}
    </div>
  );
};

export default BookieRecordSection;
