// libraries
import { useQuery } from "react-query";
import { AxiosError } from "axios";

// misc
import request from "@utils/request";
import { ServerResponse, User } from "./types";
import { useErrorHandler } from "@hooks/useErrorHandler";


export const useGetNotifications = ({
  onError,
  onSuccess,
}: {
  onSuccess?: (res: any) => void;
  onError?: (res: AxiosError<ServerResponse<unknown>>) => void;
} = {}) => {
  // variables
  const { triggerError } = useErrorHandler();

  // request
  const query = useQuery<
    any,
    ServerResponse<unknown> | undefined
  >(["notificationData"], async () => {
    try {
      const notificationData = await request<any>({
        method: "get",
        url: `/notifications`,
      });
      const data = notificationData.data.data;

      onSuccess && onSuccess(data);
      return data;
    } catch (error) {
      onError && onError(error);
      triggerError({ error });
    }
  });

  // return
  return query;
};
