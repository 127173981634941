import React, { ReactNode } from "react";
import Button, { LoadingButton } from "@atlaskit/button";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  body: ReactNode;
  submitText: string;
  onSubmit: () => void;
  isLoading?: boolean;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  title,
  body,
  submitText,
  onSubmit,
  isLoading,
}) => (
  <ModalTransition>
    {isOpen && (
      <Modal onClose={onClose}>
        <ModalHeader>
          <ModalTitle appearance="warning">{title}</ModalTitle>
        </ModalHeader>
        <ModalBody>{body}</ModalBody>
        <ModalFooter>
          <Button appearance="subtle" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            appearance="primary"
            onClick={onSubmit}
            isLoading={isLoading}
          >
            {submitText}
          </LoadingButton>
        </ModalFooter>
      </Modal>
    )}
  </ModalTransition>
);
