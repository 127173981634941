import { AddAddressRequest } from "@api/useAddAddress";
import Heading from "@components/font/heading";
import CountryCodePicker from "@components/form/phoneInput";
import { RadioButton } from "@components/form/radioButton";
import { SearchSelectBox } from "@components/form/searchSelect";
import { TextField } from "@components/form/textField";
import { theme } from "@styles/themes";
import { countryCodes } from "@utils/countries";
import { City, Country, ICountry } from "country-state-city";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { Checkbox, CheckboxWrapper, Label } from "../../signUpSection/stepFive";
import { useTranslation } from "react-i18next";
import { Button } from "@components/button/button";
import { useNavigate } from "react-router-dom";

const StyledContainer = styled.div`
  border: 1px solid ${theme.colors.white.dark};
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  max-width: 600px;
  @media (max-width: 768px) {
    min-width: 100%; /* Full width on mobile */
  }
`;
const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCol = styled(Col)`
  @media (max-width: 768px) {
    display: none;
  }
`;
const StyledButton = styled(Button)`
  font-size: 14px;
  font-weight: 700;
  border-radius: 8px;
  min-width: 3em;
  width: 10em;
  margin-top: 16px;
  margin: 0;

  @media (max-width: 768px) {
    display: none;
  }
`;
const StyledAddDetailBtn = styled(Button)`
  font-size: 14px;
  font-weight: 700;
  border-radius: 8px;
  min-width: 4em;
  width: 12em;
  margin-top: 16px;
  margin: 0;

  @media (max-width: 768px) {
    display: none;
  }
`;
type AddressForm = AddAddressRequest & {
  radio_select: string;
  phonePrefix: any;
};

const countries = Country.getAllCountries();
const mainOptions = countries?.map((country: ICountry) => ({
  value: country.isoCode,
  label: country.name,
}));
const BillingAddressForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<AddressForm>();
  const onSubmit = (data) => {
    console.log(data); // Handle form submission
  };

  const watchCountry = watch("country");
  const watchCity = watch("city");
  const watchIsCompany = watch("isCompany");
  const watchRadioSelect = watch("radio_select");

  const city = City.getCitiesOfCountry(getValues("country"));

  const mainCity = city?.map((citys: any) => ({
    value: citys.stateCode,
    label: citys.name,
  }));

  const phoneCodes = countries?.map((country: any) => ({
    finding_code: country.isoCode,
    label: country.phonecode,
    value: country.phonecode,
  }));

  const match = phoneCodes?.find(
    (code) => code.finding_code == getValues("country")
  );
  return (
    <div>
      {[...Array(1)].map((elem, index) => {
        return (
          <StyledContainer key={index}>
            <div className="d-flex align-items-start">
              <RadioWrapper>
                <Controller
                  name="radio_select"
                  control={control}
                  defaultValue={null} // Set default value if needed
                  render={({ field, value }: any) => (
                    <RadioButton
                      {...field}
                      value={value} // Replace with actual value
                    />
                  )}
                />
              </RadioWrapper>
              <div
                className="mx-3"
                style={{ overflowWrap: "anywhere", marginBottom: "8px" }}
              >
                <Heading
                  fontSize="14px"
                  title={"N/A"}
                  fontWeight="700"
                  color={theme.colors.white.normal}
                />
                <Heading
                  className="mt-1"
                  fontSize="14px"
                  title={`N/A`}
                  fontWeight="400"
                  color={theme.colors.white.dark}
                />
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row className="g-3">
                <Col md={12} sm={12} xs={12}>
                  <TextField
                    control={control}
                    name="name"
                    bgColor={theme.colors.grey.dark}
                    size="sm"
                    placeholder="Name"
                    errors={errors.name}
                    rules={{ required: "This field is required" }}
                    onChange={
                      watchIsCompany === null &&
                      (() => setValue("isCompany", false))
                    }
                  />
                </Col>

                <Col md={12} sm={12} xs={12}>
                  <TextField
                    control={control}
                    name="surname"
                    bgColor={theme.colors.grey.dark}
                    size="sm"
                    placeholder="Surname"
                    errors={errors.surname}
                    rules={{ required: "This field is required" }}
                  />
                </Col>

                <Col md={6} sm={6} xs={6}>
                  <SearchSelectBox
                    bgColor={theme.colors.grey.dark}
                    size="sm"
                    selectValue="isoCode"
                    selectLabel="name"
                    options={mainOptions}
                    placeholder="Country"
                    control={control}
                    name="country"
                    setValue={setValue}
                    errors={errors.country}
                    watch={watchCountry}
                    rules={{ required: "This field is required" }}
                  />
                </Col>
                <Col md={6} sm={6} xs={6}>
                  <SearchSelectBox
                    bgColor={theme.colors.grey.dark}
                    size="sm"
                    options={mainCity}
                    selectValue="stateCode"
                    selectLabel="name"
                    placeholder="City"
                    control={control}
                    name="city"
                    setValue={setValue}
                    errors={errors.city}
                    watch={watchCity}
                    rules={{ required: "This field is required" }}
                  />
                </Col>
                <Col md={12} sm={12} xs={12}>
                  <TextField
                    control={control}
                    name="address"
                    bgColor={theme.colors.grey.dark}
                    size="sm"
                    placeholder="Address*"
                    errors={errors.address}
                    rules={{ required: "This field is required" }}
                  />
                </Col>

                <Col md={12} sm={12} xs={12}>
                  <TextField
                    control={control}
                    name="addressExtra"
                    bgColor={theme.colors.grey.dark}
                    size="sm"
                    placeholder="Additional address (optional)"
                    rules={{ required: false }}
                  />
                </Col>

                <Col md={3} sm={3} xs={5}>
                  <TextField
                    control={control}
                    name="zipCode"
                    bgColor={theme.colors.grey.dark}
                    size="sm"
                    placeholder="Zip Code*"
                    errors={errors.zipCode}
                    rules={{ required: "This field is required" }}
                  />
                </Col>

                <Col md={9} sm={9} xs={7}>
                  <TextField
                    control={control}
                    name={watchIsCompany ? "vat" : "nif"}
                    bgColor={theme.colors.grey.dark}
                    size="sm"
                    placeholder={watchIsCompany ? "VAT*" : "NIF*"}
                    errors={watchIsCompany ? errors.vat : errors.nif}
                    rules={{ required: "This field is required" }}
                  />
                </Col>

                <Col md={3} sm={3} xs={5} className="d-flex align-items-start">
                  <CountryCodePicker
                    bgColor={theme.colors.grey.dark}
                    size="sm"
                    options={countryCodes}
                    placeholder="Phone Prefix"
                    control={control}
                    name="phonePrefix"
                    setValue={setValue}
                    errors={errors.phonePrefix}
                    watch={watchCity}
                    rules={{ required: false }}
                  />
                </Col>
                <Col md={9} sm={9} xs={7}>
                  <TextField
                    control={control}
                    name="phoneNumber"
                    bgColor={theme.colors.grey.dark}
                    size="sm"
                    placeholder="Phone number"
                    errors={errors.phoneNumber}
                    rules={{ required: false }}
                  />
                </Col>
                <Col md={12}>
                  <CheckboxWrapper>
                    <Checkbox
                      {...control.register("isSaved", {
                        required: false,
                      })}
                    />
                    <Label htmlFor="save_address">
                      {t("payments.setDefault")}
                    </Label>
                    {/* {errors.save_address && (
                    <span>{errors.save_address.message}</span>
                  )} */}
                  </CheckboxWrapper>
                </Col>
                <StyledCol md={12}>
                  <StyledButton title="profile.saveChanges" outline />
                </StyledCol>
              </Row>
            </form>
          </StyledContainer>
        );
      })}
      <div>
        <StyledAddDetailBtn
          title="profile.addBillingDetails"
          onClick={() => navigate("add")}
        />
      </div>
    </div>
  );
};

export default BillingAddressForm;
