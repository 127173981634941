import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import MobileAccordian from "./mobileAccordian";
import { useGetProfiles } from "@api/useGetProfiles";
import { TabContext } from "@context/TabContext";
import SkeletonLoader from "@components/loader/skeleton";
const MobileAccordianCard = () => {
  const { data,isLoading } = useGetProfiles({});
  const { profileIdSelected } = useContext(TabContext);

  return (
    <Row className="mt-3" style={{ marginBottom: "80px" }}>
      <Col lg={6} md={6} sm={6} xs={6}>
        <Heading
          title="profile.bookie"
          fontSize="14px"
          fontWeight="400"
          className="mb-2"
          style={{ marginLeft: "12px" }}
          color={theme.colors.white.dark}
        />
      </Col>
      <Col lg={6} md={6} sm={6} xs={6}>
        <div className="d-flex align-items-center justify-content-center">
          <Heading
            title="profile.profit"
            fontSize="14px"
            fontWeight="400"
            color={theme.colors.white.dark}
          />
        </div>
      </Col>
      {isLoading ? (
        <Col lg={12} md={12} sm={12} xs={12}>
          <SkeletonLoader height="70px"/>
        </Col>
      ) : 
        data?.profiles
          ?.filter((elem) => elem.profileId == profileIdSelected)
          .map((profile) => 
            profile.bookies?.map((bookie, index) => (
              <Col lg={12} md={12} sm={12} xs={12} key={index}>
                <MobileAccordian index={index} bookies={bookie} />
              </Col>
            ))
          )}
    </Row>
  );
};

export default MobileAccordianCard;
