// libraries
import { useQuery } from "react-query";
import { AxiosError } from "axios";

// misc
import request from "@utils/request";
import { ServerResponse, PaymentSummaryData } from "./types";
import { useErrorHandler } from "@hooks/useErrorHandler";

export type GetPaymentSummaryResponse = ServerResponse<PaymentSummaryData>;

export const getPaymentSummaryQKey = "getPaymentSummary";

export const useGetPaymentSummary = ({
  onError,
  onSuccess,
}: {
  onSuccess?: (res: PaymentSummaryData) => void;
  onError?: (res: AxiosError<ServerResponse<unknown>>) => void;
} = {}) => {
  // variables
  const { triggerError } = useErrorHandler();

  // request
  const query = useQuery<
    PaymentSummaryData,
    ServerResponse<unknown> | undefined
  >(
    [getPaymentSummaryQKey],
    async () => {
      try {
        const res = await request<GetPaymentSummaryResponse>({
          method: "get",
          url: `/payments/summary`,
        });

        const data = res.data.data;

        onSuccess && onSuccess(data);
        return data;
      } catch (error) {
        onError && onError(error);
        triggerError({ error });
        throw error;
      }
    },
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchInterval: 10000,
      refetchOnReconnect: true,
    }
  );

  // return
  return query;
};
