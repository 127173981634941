import React, { useContext, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import Heading from "@components/font/heading";
import { RadioButton } from "@components/form/radioButton";
import { TabContext } from "@context/TabContext";
import PaymentForm from "@pages/PaymentForm";
import { theme } from "@styles/themes";
import { Button } from "@components/button/button";
import { usePayment } from "@context/PaymentContext";
import { paymentMethods, PaymentMethodType } from "@utils/payments";
import { PaymentFooter } from "./components/paymentFooter";

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDivBox = styled.div`
  border: 1px solid ${theme.colors.white.dark};
  border-radius: 8px;
  cursor: pointer;
  padding: 16px;
`;

const ResponsiveDiv = styled.div`
max-width:730px;

  @media (min-width: 1200px) {
padding-right:36px
  }
  @media (max-width: 1199px) {
  padding-right:0
  width:100%;
  max-width:1300px;
  }
`;

const StepTwo = () => {
  // variables
  const tabContext = useContext(TabContext);
  const { setActiveTab, activeTab } = tabContext;
  const { amount, convertToCurrencyAmount, setPaymentMethod } = usePayment();

  const { control, setValue, handleSubmit, watch } = useForm<{
    paymentMethod: PaymentMethodType;
  }>({
    defaultValues: {
      paymentMethod: paymentMethods.creditCard,
    },
  });

  const watchPaymentMethod = watch("paymentMethod");

  // effects
  useEffect(() => {
    setPaymentMethod(watchPaymentMethod);
  }, [watchPaymentMethod]);

  // functions
  const onSubmit = async (values: any) => {
    setActiveTab(2);
  };

  // returns
  return (
    <ResponsiveDiv>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-3">
          <Col lg={12} md={12} sm={12} xs={12}>
            <StyledDivBox
              onClick={() =>
                setValue("paymentMethod", paymentMethods.creditCard)
              }
            >
              <div className="d-flex align-items-start">
                <RadioWrapper>
                  <RadioButton
                    name="paymentMethod"
                    value={paymentMethods.creditCard}
                    checked={
                      watch("paymentMethod") === paymentMethods.creditCard
                    }
                    {...control.register("paymentMethod", {
                      required: "Select a payment method",
                    })}
                  />
                </RadioWrapper>
                <div className="mx-3">
                  <Heading
                    fontSize="14px"
                    title="Credit Card"
                    fontWeight="700"
                    color={theme.colors.white.normal}
                  />
                </div>
              </div>

              <PaymentForm onPressContinue={onSubmit} />
            </StyledDivBox>
          </Col>

          <Col lg={12} md={12} sm={12} xs={12}>
            <StyledDivBox
              onClick={() =>
                setValue("paymentMethod", paymentMethods.bankTransfer)
              }
            >
              <div className="d-flex align-items-start">
                <RadioWrapper>
                  <RadioButton
                    name="paymentMethod"
                    value={paymentMethods.bankTransfer}
                    checked={
                      watch("paymentMethod") === paymentMethods.bankTransfer
                    }
                    {...control.register("paymentMethod", {
                      required: "Select a payment method",
                    })}
                  />
                </RadioWrapper>

                <div className="mx-3">
                  <Heading
                    fontSize="14px"
                    title="Bank Transfer"
                    fontWeight="700"
                    color={theme.colors.white.normal}
                  />
                </div>
              </div>
            </StyledDivBox>
          </Col>

          {/* <Col lg={12} md={12} sm={12} xs={12}>
            <StyledDivBox
              onClick={() => setValue("paymentMethod", paymentMethods.crypto)}
            >
              <div className="d-flex align-items-start">
                <RadioWrapper>
                  <RadioButton
                    name="paymentMethod"
                    value={paymentMethods.crypto}
                    checked={watch("paymentMethod") === paymentMethods.crypto}
                    {...control.register("paymentMethod", {
                      required: "Select a payment method",
                    })}
                  />
                </RadioWrapper>

                <div className="mx-3">
                  <Heading
                    fontSize="14px"
                    title="Crypto currency"
                    fontWeight="700"
                    color={theme.colors.white.normal}
                  />
                </div>
              </div>
            </StyledDivBox>
          </Col> */}
        </Row>

        <PaymentFooter />
      </form>
    </ResponsiveDiv>
  );
};

export default StepTwo;
