import { useEditProfile } from "@api/useEditProfile";
import { useGetProfiles } from "@api/useGetProfiles";
import { Button } from "@components/button/button";
import { TextField } from "@components/form/textField";
import { TabContext } from "@context/TabContext";
import { theme } from "@styles/themes";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";

interface EditForm {
  name: string;
}

const EditProfileModal = ({ setEditProfileOpen,setToastOpen,setToastMessage }) => {
  const { profileIdSelected } = useContext(TabContext);
  const { refetch } = useGetProfiles({});

  const { mutate: editProfile } = useEditProfile({
    onSuccess: () => {
      setEditProfileOpen(false);
      refetch();
      setToastOpen(true);
    },
  });
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<EditForm>();
  const onSubmit = async(data: EditForm) => {
    try {
      await editProfile({
        params: { profileId: profileIdSelected, data: { profileName: data?.name } },
      });
      await setToastMessage("Profile edited");
    } catch (error) {
      console.error("Error Editing profile:", error);
    }
  };


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        control={control}
        name="name"
        style={{ margin: "16px 0" }}
        bgColor={theme.colors.grey.dark}
        size="sm"
        placeholder="addEditUser.nameLabel"
        errors={errors.name}
        rules={{ required: "This field is required" }}
      />
      
      <div style={{ textAlign: "right", marginTop: "24px" }}>
        <Button
          style={{
            borderRadius: "8px",
            fontWeight: "700",
            fontSize: "14px",
          }}
          outline
          onClick={() => setEditProfileOpen(false)}
          title="addEditUser.cancelButton"
          size="md"
        />
        <Button
          style={{
            borderRadius: "8px",
            fontWeight: "700",
            fontSize: "14px",
            marginLeft: "12px",
          }}
          type="submit"
          title="profile.edit"
          size="md"
        />
      </div>
    </form>
  );
};

export default EditProfileModal;
