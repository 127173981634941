// libraries
import { useMutation } from "react-query";
import { AxiosError } from "axios";

// misc
import request from "@utils/request";
import { ServerResponse } from "./types";
import { useErrorHandler } from "@hooks/useErrorHandler";

export type EditProfileResponse = ServerResponse<unknown>; // Update this type if you know the specific response structure

export type EditProfileParams = {
  profileId: string;
  data: {
    profileName?: string;
  };
};

export const useEditProfile = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (res: unknown) => void; // Update this type if you know the specific success response structure
  onError?: (error: AxiosError<ServerResponse<unknown>>) => void;
}) => {
  // variables
  const { triggerError } = useErrorHandler();

  // mutation
  const mutation = useMutation<unknown, AxiosError<ServerResponse<unknown>>, { params: EditProfileParams }>(
    async ({ params }) => {
      const { profileId, data } = params;
      try {
        const response = await request<EditProfileResponse>({
          method: "patch",
          url: `/profiles/${profileId}`,
          data,
        });

        const responseData = response.data.data;
        onSuccess && onSuccess(responseData);
        return responseData;
      } catch (error) {
        onError && onError(error);
        triggerError({ error });
        throw error;
      }
    }
  );

  // return
  return mutation;
};
