// libraries
import { useMutation } from "react-query";
import { AxiosError } from "axios";

// misc
import request from "@utils/request";
import { AuthResponse, ServerResponse } from "./types";
import { useAuth } from "@context/AuthContext";
import { useErrorHandler } from "@hooks/useErrorHandler";

export type RefreshResponse = AuthResponse;

export const useRefreshToken = ({
  onError,
  onSuccess,
}: {
  onSuccess?: (res: RefreshResponse) => void;
  onError?: (res: AxiosError<ServerResponse<unknown>>) => void;
} = {}) => {
  // variables
  const { setAuth } = useAuth();
  const { triggerError } = useErrorHandler();

  // request
  const query = useMutation<
    RefreshResponse,
    ServerResponse<unknown> | undefined
  >(["refresh"], async () => {
    try {
      const refreshData = await request<AuthResponse>({
        method: "post",
        url: `/refresh`,
      });

      if (refreshData.data?.token) {
        setAuth(refreshData.data.token);
      }

      onSuccess && onSuccess(refreshData.data);
      return refreshData.data;
    } catch (error) {
      onError && onError(error);
      triggerError({ error });
    }
  });

  // return
  return query;
};
