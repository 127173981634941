import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import React, { useContext } from "react";
import bell from "@assets/icons/bell.svg";
import setting from "@assets/icons/setting.svg";
import { Icon } from "@iconify/react";
import AvailableCredit from "@components/creditCardBox/availableCredit";
import { Button } from "@components/button/button";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useGetPaymentSummary } from "@api/useGetPaymentSummary";
import { useGetProfiles } from "@api/useGetProfiles";
import { usePayment } from "@context/PaymentContext";
import { convertToCurrencyAmount } from "@utils/currency";
import { useState } from "react";
import NotificationCard from "../notificationSection/notificationCard";
import NotificationCardDesktop from "../notificationSection/notificationCardDesktop";
import { TabContext } from "@context/TabContext";
import NotificatiuonComponentDesk from "./notificationComponentDesk";

const HoverDiv = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 8px 8px 0 0;
  padding: 12px;
  border-bottom: 1px solid ${theme.colors.grey.darker};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${theme.colors.grey
      .darker}; // Change background color on hover
  }
`;
const HoverDivTwo = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 0 0 8px 8px;
  padding: 12px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${theme.colors.grey
      .darker}; // Change background color on hover
  }
`;

const TabContainer = styled.div`
  display: flex;
  gap: 6px;
  background-color: ${theme.colors.grey.darker};
  border-radius: 30px;
  padding: 4px;
`;

const Tab = styled.div<{ active: boolean }>`
  padding: 0px 10px;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: ${(props) =>
    props.active ? theme.colors.grey.dark : "transparent"};
  color: ${(props) =>
    props.active ? theme.colors.white.normal : theme.colors.white.normalActive};
  font-size: 12px;
`;

const DesktopProfileHead = ({ setSettingOpen }) => {
  const navigate = useNavigate();
  const { notificationCardOpen, setNotificationCardOpen } =
    useContext(TabContext);
  const [dropDown, setDropDown] = React.useState<boolean>(false);
  const handleDropDown = () => {
    setDropDown(!dropDown);
  };
  const { data: paymentSummary } = useGetPaymentSummary();
  const credits = paymentSummary?.credits;

  const { data: profiles } = useGetProfiles();
  const profit = profiles?.profits;
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="mb-4">
      <div className="d-flex justify-content-between align-items-center">
        <Heading
          title="profile.profile"
          fontSize="29px"
          fontWeight="700"
          className="mb-2"
          color={theme.colors.white.normal}
        />
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ position: "relative" }}
        >
          <div>
            <div style={{ position: "relative" }}>
              <img
                src={bell}
                style={{ width: "1.2rem", marginRight: "15px" }}
                alt="bell icon"
                onClick={() => setNotificationCardOpen(!notificationCardOpen)}
              />
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  right: 12,
                  borderRadius: "50%",
                  backgroundColor: theme.colors.red.normal,
                  position: "absolute",
                  top: 0,
                }}
              />
            </div>
            <NotificatiuonComponentDesk />
          </div>

          <img
            src={setting}
            style={{ width: "1.2rem", marginRight: "20px", cursor: "pointer" }}
            alt="bell icon"
            onClick={() => setSettingOpen(true)}
          />
          <div
            className="d-flex justify-content-between align-items-start"
            style={{ cursor: "pointer", position: "relative" }}
            onClick={handleDropDown}
          >
            <Heading
              title="Pedro MGV"
              fontSize="17px"
              fontWeight="400"
              className="me-1"
              color={theme.colors.white.normal}
            />
            <Icon
              icon="fe:arrow-down"
              width="18"
              height="18"
              style={{ color: theme.colors.white.normal }}
            />
            <div
              style={{
                backgroundColor: theme.colors.grey.dark,
                borderRadius: "8px",
                position: "absolute",
                display: dropDown ? "block" : "none",
                top: 36,
                right: 12,
                width: "max-content",
                minWidth: "11em",
                boxShadow: "0px 0px 3px 0px rgba(255, 255, 255, 0.5)",
              }}
            >
              <HoverDiv onClick={() => navigate("mydata")}>
                <Icon
                  icon="heroicons-outline:user"
                  width="16"
                  height="16"
                  style={{
                    color: theme.colors.white.normal,
                    marginRight: "6px",
                  }}
                />
                <Heading
                  title="profile.myData"
                  fontSize="14px"
                  fontWeight="400"
                  color={theme.colors.white.normal}
                />
              </HoverDiv>

              <HoverDivTwo onClick={() => navigate("billingdetails")}>
                <Icon
                  icon="heroicons-outline:document-text"
                  width="16"
                  height="16"
                  style={{
                    color: theme.colors.white.normal,
                    marginRight: "6px",
                  }}
                />
                <Heading
                  title="profile.billingDetails"
                  fontSize="14px"
                  fontWeight="400"
                  color={theme.colors.white.normal}
                />
              </HoverDivTwo>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex  align-items-center  flex-wrap">
        <div
          className="d-flex justify-content-between align-items-center me-4 mt-3"
          style={{
            backgroundColor: theme.colors.grey.dark,
            borderRadius: "8px",
            padding: "10px 16px",
            width: "max-content",
            minWidth: "348px",
            height: "96px",
          }}
        >
          <div>
            <div style={{ marginBottom: "8px" }}>
              <Heading
                title="profile.availableCredit"
                fontSize="16px"
                fontWeight="400"
                color={theme.colors.white.normalActive}
              />
            </div>
            <Heading
              title={credits?.toString()}
              fontSize="24px"
              fontWeight="700"
              color={theme.colors.white.normal}
            />
          </div>
          <Button
            title="payments.getCredits"
            style={{
              fontWeight: "700",
              fontSize: "14px",
              borderRadius: "8px",
              color: theme.colors.grey.darker,
            }}
            onClick={() => navigate("/payments")}
          />
        </div>
        <div
          className="d-flex justify-content-between align-items-center mt-3"
          style={{
            backgroundColor: theme.colors.grey.dark,
            borderRadius: "8px",
            padding: "10px 16px",
            width: "max-content",
            minWidth: "348px",
            height: "96px",
          }}
        >
          <div>
            <div
              style={{ marginBottom: "8px" }}
              className="d-flex align-items-center justify-content-between"
            >
              <div style={{ marginRight: "33px" }}>
                <Heading
                  title="profile.profit"
                  fontSize="16px"
                  fontWeight="400"
                  color={theme.colors.white.normalActive}
                />
              </div>

              <TabContainer>
                {["All", "24 hours", "7 days", "1 month"].map((tab, index) => (
                  <Tab
                    key={tab}
                    active={activeTab === index}
                    onClick={() => setActiveTab(index)}
                  >
                    <Heading
                      title={tab}
                      fontSize="12px"
                      fontWeight="400"
                      color={theme.colors.white.normal}
                    />
                  </Tab>
                ))}
              </TabContainer>
            </div>
            <Heading
              title={convertToCurrencyAmount(
                activeTab == 0
                  ? profit
                  : activeTab == 3
                  ? profit - profiles?.profitsDif?.oneMonth
                  : activeTab == 1
                  ? profit - profiles?.profitsDif?.oneDay
                  : activeTab == 2
                  ? profit - profiles?.profitsDif?.oneWeek
                  : profit || 0,
                "eur"
              )}
              fontSize="24px"
              fontWeight="700"
              color={theme.colors.white.normal}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopProfileHead;
