import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import Flag from "@assets/flags/ea.svg";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
  Type,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";
import { useGetProfiles } from "@api/useGetProfiles";
import { TabContext } from "@context/TabContext";
import SkeletonLoader from "@components/loader/skeleton";
import { convertToCurrencyAmount } from "@utils/currency";

const LeftActions = ({
  elem,
  handleArchieveDeactivateProfile,
  setEditProfileOpen,
  setArchieveProfileOpen,
  setProfileIdSelected,
}) => (
  <TrailingActions>
    <SwipeAction
      destructive={false}
      onClick={() => setProfileIdSelected(elem?.profileId)}
    >
      <div className="d-flex align-items-center">
        <Icon
          style={{
            fontSize: "24px",
            color: theme.colors.white.normal,
            marginLeft: "18px",
          }}
          icon="fluent:edit-28-regular"
          onClick={() => setEditProfileOpen(true)}
        />
        <Icon
          style={{
            fontSize: "24px",
            color: theme.colors.white.normal,
            margin: "0 18px",
          }}
          icon="fluent:archive-16-regular"
          onClick={() => handleArchieveDeactivateProfile()}
        />
      </div>
    </SwipeAction>
  </TrailingActions>
);
const MobileProfileCard = ({
  handleArchieveDeactivateProfile,
  handleArchieveActivateProfile,
  setAddProfileOpen,
  setEditProfileOpen,
  setArchieveProfileOpen,
}) => {
  const navigate = useNavigate();
  const { data, isLoading } = useGetProfiles({});
  const { setProfileIdSelected, setProfileCountry } = useContext(TabContext);
  const handleListClick = (profileId: string, country: string) => {
    setProfileIdSelected(profileId);
    setProfileCountry(country);
    navigate("/profile/bookie");
  };

  return (
    <Row className="mt-3" style={{ marginBottom: "86px" }}>
      <Col lg={5} md={5} sm={5} xs={5}>
        <Heading
          title="profile.profile"
          fontSize="14px"
          fontWeight="400"
          className="mb-2"
          style={{ marginLeft: "12px" }}
          color={theme.colors.white.dark}
        />
      </Col>
      <Col lg={7} md={7} sm={7} xs={7}>
        <div className="d-flex align-items-center justify-content-between">
          <Heading
            title="profile.profit"
            fontSize="14px"
            fontWeight="400"
            color={theme.colors.white.dark}
          />
          <div className="d-flex align-items-center">
            <Icon
              onClick={() => setAddProfileOpen(true)}
              style={{
                fontSize: "24px",
                marginRight: "12px",
                color: theme.colors.white.normal,
              }}
              icon="heroicons-outline:user-add"
            />
          </div>
        </div>
      </Col>

      {isLoading ? (
        <Col lg={12} md={12} sm={12} xs={12}>
          <SkeletonLoader />
        </Col>
      ) : (
        data?.profiles?.map((elem, index) => {
          return (
            <Col lg={12} md={12} sm={12} xs={12} key={index}>
              {elem?.isActive === false ? (
                <div
                  style={{
                    margin: "6px 0",
                    borderRadius: "8px",
                    padding: "16px 10px",
                    width: "100%",
                    backgroundColor: theme.colors.grey.normal,
                  }}
                >
                  <Row>
                    <Col
                      lg={5}
                      md={5}
                      sm={5}
                      xs={5}
                      className="d-flex align-items-center"
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src={`https://flagcdn.com/${elem?.country?.toLowerCase()}.svg`}
                          width={24}
                          className="me-2"
                          style={{ filter: "grayscale(100%)" }}
                        />
                        <Heading
                          title={elem.profileName}
                          fontSize="14px"
                          fontWeight="700"
                          color={theme.colors.white.normal}
                        />
                      </div>
                    </Col>
                    <Col lg={7} md={7} sm={7} xs={7}>
                      <div className="d-flex align-items-center justify-content-between">
                        <Heading
                          title={convertToCurrencyAmount(340000, "eur")}
                          fontSize="14px"
                          fontWeight="700"
                          className="me-1"
                          color={theme.colors.white.normal}
                        />
                        <div className="d-flex align-items-center">
                          <Icon
                            style={{
                              fontSize: "24px",
                              color: theme.colors.white.normal,
                            }}
                            onClick={() =>
                              handleArchieveActivateProfile(elem?.profileId)
                            }
                            icon="mage:reload"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : (
                <SwipeableList fullSwipe={false} type={Type.IOS}>
                  <SwipeableListItem
                    listType={Type.IOS}
                    fullSwipe={false}
                    trailingActions={
                      <LeftActions
                        elem={elem}
                        handleArchieveDeactivateProfile={
                          handleArchieveDeactivateProfile
                        }
                        setEditProfileOpen={setEditProfileOpen}
                        setArchieveProfileOpen={setArchieveProfileOpen}
                        setProfileIdSelected={setProfileIdSelected}
                      />
                    }
                  >
                    <div
                      onClick={() =>
                        handleListClick(elem?.profileId, elem?.country)
                      }
                      style={{
                        margin: "6px 0",
                        borderRadius: "8px",
                        padding: "16px 10px",
                        width: "100%",
                        backgroundColor: theme.colors.grey.dark,
                      }}
                    >
                      <Row>
                        <Col
                          lg={5}
                          md={5}
                          sm={5}
                          xs={5}
                          className="d-flex align-items-center"
                        >
                          <div className="d-flex align-items-center">
                            <img
                              src={`https://flagcdn.com/${elem?.country?.toLowerCase()}.svg`}
                              width={24}
                              className="me-2"
                            />
                            <Heading
                              title={elem.profileName}
                              fontSize="14px"
                              fontWeight="700"
                              color={theme.colors.white.normal}
                            />
                          </div>
                        </Col>
                        <Col lg={7} md={7} sm={7} xs={7}>
                          <div className="d-flex align-items-center justify-content-between">
                            <Heading
                              title={convertToCurrencyAmount(340000, "eur")}
                              fontSize="14px"
                              fontWeight="700"
                              className="me-1"
                              color={theme.colors.white.normal}
                            />
                            <div className="d-flex align-items-center">
                              <Icon
                                style={{
                                  fontSize: "24px",
                                  color: theme.colors.white.normal,
                                }}
                                icon="iconamoon:arrow-right-2"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </SwipeableListItem>
                </SwipeableList>
              )}
            </Col>
          );
        })
      )}
    </Row>
  );
};

export default MobileProfileCard;
