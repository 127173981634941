import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import AccordianChip from "../AccordianChip";
import { theme } from "@styles/themes";
import Heading from "@components/font/heading";
import avatar from "@assets/avatar.svg";
import { Col, Row } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { Button } from "@components/button/button";
import CopyModal from "@components/modal/CopyModal";
import ArchieveProfileModal from "../../archieveProfileModal";
import SkeletonLoader from "@components/loader/skeleton";
import { TabContext } from "@context/TabContext";
import { useGetProfiles } from "@api/useGetProfiles";
import { convertToCurrencyAmount } from "@utils/currency";
import { useForm } from "react-hook-form";
import { useFieldArray } from "react-hook-form";
import { useUpdateBookiesMultiple } from "@api/useUpdateBookiesMultiple";

const ArchiveContainer = styled.div<{ isChecked: boolean }>`
  margin: 16px 0px;
  font-family: Arial, sans-serif;
  border: 1px solid
    ${(props) => (props.isChecked ? theme.colors.white.normal : "#383838")};
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
  background-color: ${(props) =>
    props.isChecked ? theme.colors.grey.darkActive : theme.colors.grey.dark};
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  color: white;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 0;
`;
export const Checkbox = styled.input.attrs({ type: "checkbox" })`
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid white;
  position: relative;
  cursor: pointer;
  margin-top: 6px;

  &:checked {
    background-color: ${theme.colors.main.normal}; /* Custom check color */
    border: 1px solid black;
  }

  &:checked::after {
    content: "";
    position: absolute;
    top: 1px;
    left: 6px;
    width: 7px;
    height: 12px;
    border: solid black;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;
const FooterWrapper = styled.div`
  position: fixed;
  border-top: 0.5px solid ${(props) => props.theme.colors.white.dark};
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${(props) => props.theme.colors.grey.darker};
  text-align: center;
  padding: 14px 16px;
`;
const BookieArchieveSection = () => {
  const { data, isLoading } = useGetProfiles({});
  const { profileIdSelected } = useContext(TabContext);
  const { mutate } = useUpdateBookiesMultiple({
    onSuccess: (data) => {
      console.log("Bookie updated successfully:", data);
      // refetch() ;
      // setToastVisible(true);
    },
    onError: (error) => {
      console.error("Failed to update bookie:", error);
      // setToastMessage("Failed to update bookie"); 
      // setToastVisible(true);
    },
  });
  // Initialize checkedItems as an array to store the bookie data
  const [bookies, setBookies] = useState([]);
  console.log(bookies, "bookies");

  const handleCheckboxChange = (accountId: any, enabled: boolean) => {
    const updatedBookies = bookies.map((bookie) => {
      if (bookie.accountId === accountId) {
        return { ...bookie, isEnabled: !enabled };
      }
      return bookie;
    });
    setBookies(updatedBookies);
  };

  useEffect(() => {
    const selectedProfile = data?.profiles?.find(
      (profile) => profile.profileId === profileIdSelected
    );

    if (selectedProfile?.bookies) {
      setBookies(selectedProfile.bookies);
    }
  }, [data, profileIdSelected]);
  const [archieveProfileOpen, setArchieveProfileOpen] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  // Function to submit form data
  const onSubmit = (data: any) => {
    const formatered = bookies.map((item) => ({
      bookie: item.bookie,
      isEnabled: item.isEnabled,
      accountId: item.accountId,
    }));
    mutate(formatered)
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        {isLoading ? (
          <Col lg={12} md={12} sm={12} xs={12}>
            <SkeletonLoader height="70px" />
          </Col>
        ) : (
          bookies?.map((bookie: any, index: number) => (
            <Col lg={12} md={12} sm={12} xs={12} key={bookie?.accountId}>
              <ArchiveContainer isChecked={!bookie?.isEnabled}>
                <CheckboxLabel>
                  <Row className="align-items-center" style={{ width: "100%" }}>
                    <Col xs={1}>
                      <Checkbox
                        type="checkbox"
                        name={bookie?.accountId}
                        checked={!bookie?.isEnabled}
                        onChange={() =>
                          handleCheckboxChange(
                            bookie?.accountId,
                            bookie?.isEnabled
                          )
                        }
                        onClick={(e) => e.stopPropagation()}
                      />
                    </Col>
                    <Col xs={2}>
                      <img
                        src={avatar}
                        alt="Avatar"
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </Col>
                    <Col xs={5}>
                      <Heading
                        title={bookie?.username}
                        fontSize="14px"
                        fontWeight="400"
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                        color={theme.colors.white.normal}
                      />
                    </Col>
                    <Col xs={4} className="text-end">
                      <Heading
                        title={convertToCurrencyAmount(
                          bookie?.profit || 0,
                          "eur"
                        )}
                        fontSize="14px"
                        fontWeight="700"
                        color={theme.colors.white.normal}
                      />
                    </Col>
                  </Row>
                </CheckboxLabel>
              </ArchiveContainer>
            </Col>
          ))
        )}
      </Row>

      <FooterWrapper className="d-block d-sm-none">
        <Button
          style={{
            width: "100%",
            borderRadius: "8px",
            fontWeight: "700",
            fontSize: "14px",
          }}
          type="submit"
          title="profile.archieveBookie"
          size="md"
        />
      </FooterWrapper>

      <CopyModal
        open={archieveProfileOpen}
        setOpen={setArchieveProfileOpen}
        heading="profile.archieveProfile"
        mainContentStyle={{ padding: "0" }}
        mainContent={
          <ArchieveProfileModal
            setArchieveProfileOpen={setArchieveProfileOpen}
          />
        }
      />
    </form>
  );
};

export default BookieArchieveSection;
