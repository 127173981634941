// libraries
import { useMutation } from "react-query";

// misc
import request from "@utils/request";
import { AuthResponse, ServerResponse } from "./types";
import { AxiosError } from "axios";
import { useAuth } from "@context/AuthContext";

export type LoginResponse = ServerResponse<AuthResponse>;

export interface LoginRequest {
  username: string;
  password: string;
}

export const useLogin = ({
  onError,
  onSuccess,
}: {
  onSuccess?: (res: LoginResponse) => void;
  onError?: (res: AxiosError<ServerResponse<unknown>>) => void;
} = {}) => {
  // variables
  const { setAuth,setAccount,setSetting } = useAuth();

  // request
  const query = useMutation<
    LoginResponse,
    ServerResponse<unknown> | undefined,
    LoginRequest
  >(["login"], async ({ username, password }: LoginRequest) => {
    try {
      const loginData = await request<LoginResponse>({
        method: "post",
        url: `/authenticate`,
        data: { username, password },
      });
      if (loginData.data?.data.token) {
        setAuth(loginData.data.data.token);
        setAccount(loginData?.data?.data?.myAccount)
        setSetting(loginData?.data?.data?.settings)        
      }
      onSuccess && onSuccess(loginData.data);
      return loginData.data;
    } catch (error) {
      onError && onError(error);
    }
  });

  // return
  return query;
};
