import { useMutation } from "react-query";
import { AxiosError } from "axios";
import { toast } from "react-toastify";

// misc
import request from "@utils/request";
import { ServerResponse } from "./types";
import { useErrorHandler } from "@hooks/useErrorHandler";
import { useTranslation } from "react-i18next";

export const usePostContactUs = ({
  onError,
  onSuccess,
}: {
  onSuccess?: (res: ServerResponse<unknown>) => void;
  onError?: (res: AxiosError<ServerResponse<unknown>>) => void;
} = {}) => {
  // variables
  const { triggerError } = useErrorHandler();
  const { t } = useTranslation();

  // request
  const query = useMutation<
    ServerResponse<unknown>,
    ServerResponse<unknown> | undefined,
    { message: string; email: string;fullName:string }
  >(["contact"], async (data: { message: string; email: string; fullName:string }) => {
    try {
      const res = await request<ServerResponse<unknown>>({
        method: "post",
        url: `/contact`,
        data,
      });

      toast.success(t("success.contactUs"));
      onSuccess && onSuccess(res.data);
      return res.data;
    } catch (error) {
      onError && onError(error);
      triggerError({ error });
    }
  });

  // return
  return query;
};