import CountryCodePicker from "@components/form/phoneInput";
import { TextField } from "@components/form/textField";
import { theme } from "@styles/themes";
import { Country } from "country-state-city";
import React from "react";
import { Col, Row } from "react-bootstrap";

const StepThree = ({ control, setValue, errors,watch }: any) => {
  return (
    <div>
      <Row className="g-2">
        <Col md={12} sm={12} xs={12}>
          <TextField
            control={control}
            name="password"
            bgColor={theme.colors.grey.dark}
            size="sm"
            type="password"
            placeholder="Password"
            errors={errors.password}
            rules={{
              required: "This field is required",
              minLength: {
                value: 6,
                message: "Password must be at least 6 characters long",
              },
              pattern: {
                value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/,
                message:
                  "Password must be at least 6 characters and contain at least one letter and one number",
              },
            }}
          />
        </Col>
        <Col md={12} sm={12} xs={12}>
          <TextField
            control={control}
            name="confirmPassword"
            bgColor={theme.colors.grey.dark}
            size="sm"
            type="password"
            placeholder="Confirm Password"
            errors={errors.confirmPassword}
            rules={{
              required: "This field is required",
              validate: (value: any) =>
                value === watch("password") || "Passwords do not match",
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default StepThree;
