import { AddProfileParams } from "@api/types";
import { useAddProfile } from "@api/useAddProfile";
import { useGetProfiles } from "@api/useGetProfiles";
import { Button } from "@components/button/button";
import { SearchSelectBox } from "@components/form/searchSelect";
import { TextField } from "@components/form/textField";
import { theme } from "@styles/themes";
import { Country, ICountry } from "country-state-city";
import React from "react";
import { useForm } from "react-hook-form";



const AddProfileModal = ({ setToastOpen,setAddProfileOpen,setToastMessage }) => {
    const { refetch } = useGetProfiles({}); 
    const { mutate, isLoading, isError, error } = useAddProfile({
      onSuccess: () => {
        refetch();
        setAddProfileOpen(false)
        setToastOpen(true);
      }
    });
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<AddProfileParams>();
  const onSubmit = async (data: AddProfileParams) => {
   await mutate(data);
  await  setToastMessage("Profile added");

  };

  const countries = Country.getAllCountries();
  const mainOptions = countries?.map((country: ICountry) => ({
    value: country.isoCode,
    label: country.name,
  }));
  const watchCountry = watch("country");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        control={control}
        name="profileName"
        style={{ margin: "16px 0" }}
        bgColor={theme.colors.grey.dark}
        size="sm"
        placeholder="addEditUser.nameLabel"
        errors={errors.profileName}
        rules={{ required: "This field is required" }}
      />
      <SearchSelectBox
        bgColor={theme.colors.grey.dark}
        size="sm"
        selectValue="isoCode"
        selectLabel="name"
        options={mainOptions}
        placeholder="Country"
        control={control}
        name="country"
        setValue={setValue}
        errors={errors.country}
        watch={watchCountry}
        rules={{ required: "This field is required" }}
      />
      <div style={{ textAlign: "right", marginTop: "24px" }}>
        <Button
          style={{
            borderRadius: "8px",
            fontWeight: "700",
            fontSize: "14px",
          }}
          outline
          onClick={() => setAddProfileOpen(false)}
          title="actions.close"
          size="md"
        />
        <Button
          style={{
            borderRadius: "8px",
            fontWeight: "700",
            fontSize: "14px",
            marginLeft: "12px",
          }}
          type="submit"
          title="actions.send"
          size="md"
        />
      </div>
    </form>
  );
};

export default AddProfileModal;
