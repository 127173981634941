import React from "react";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import { theme } from "@styles/themes";
import styled from "styled-components";
import Heading from "@components/font/heading";

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const Container = styled.div`
  border: 1px solid ${theme.colors.grey.normal};
  border-radius: 8px;
  max-width: 450px;
  padding: 0 16px;
  margin: 18px 0;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  gap: 24px;
  align-items: center;
 
 
`;

const ChartContainer = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  flex-shrink: 0;
`;

const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding: 24px 0;
  flex-grow: 1;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LegendDot = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${props => props.color};
`;

const LegendText = styled.span`
  color: ${theme.colors.white.normal};
  font-size: 14px;
`;

const BookiesChart = () => {
  const chartData = [
    { label: "Bookie 1", value: 35, color: "#FF7300" },
    { label: "Bookie 2", value: 25, color: theme.colors.yellow.normal },
    { label: "Bookie 3", value: 20, color: theme.colors.red.normal },
    { label: "Bookie 4", value: 20, color: theme.colors.green.normal },
    { label: "Bookie 5", value: 15, color: "#FF00F7" },
    { label: "Bookie 6", value: 10, color: "#00FBFF" },
    { label: "Bookie 7", value: 18, color: theme.colors.blue.normal },
    { label: "Other", value: 7, color: theme.colors.grey.normal },
  ];

  const data: ChartData<"doughnut"> = {
    labels: [],  // Remove labels from chart
    datasets: [{
      data: chartData.map(item => item.value),
      backgroundColor: chartData.map(item => item.color),
      borderColor: chartData.map(item => item.color),
      borderWidth: 1,
    }],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide default legend
      },
    },
    cutout: "70%",
  };

  return (
    <Container>
      <ChartContainer>
        <Pie data={data as any} options={options} />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
          }}
        >
          <Heading
            title="profile.profit"
            fontSize="12px"
            fontWeight="400"
            color={theme.colors.white.dark}
          />
          <Heading
            title="34.5847,87€"
            fontSize="14px"
            fontWeight="700"
            color={theme.colors.white.normal}
          />
          <Heading
            title="100%"
            fontSize="12px"
            fontWeight="400"
            color={theme.colors.white.normal}
          />
        </div>
      </ChartContainer>

      <LegendContainer>
        {chartData.map((item, index) => (
          <LegendItem key={index}>
            <LegendDot color={item.color} />
            <LegendText>{item.label} </LegendText>
          </LegendItem>
        ))}
      </LegendContainer>
    </Container>
  );
};

export default BookiesChart;
