import { Country } from "country-state-city";

const countries = Country.getAllCountries();
export const countryCodes = countries?.map((country: any) => ({
  label: country.phonecode,
  value: country.phonecode,
  flag: country.isoCode,
}));


export const languages = {
  eng: {
    value: "EN",
    label: "English",
  },
  esp: {
    value: "ES",
    label: "Español",
  },
};