// libraries
import { AxiosError } from "axios";
import { toast } from "react-toastify";

// misc
import { useTranslation } from "react-i18next";
import { ServerResponse } from "@api/types";
import { useAuth } from "@context/AuthContext";

type ErrorHandlerType = {
  title?: string;
  error: AxiosError<ServerResponse<unknown>>;
  callback?: () => void;
};

export const useErrorHandler = () => {
  // variables
  const { t } = useTranslation();
  const { resetAuth } = useAuth();

  // function
  const triggerError = ({ title, error, callback }: ErrorHandlerType) => {
    console.log("error2", error);

    if (error.code === "ERR_NETWORK") {
      resetAuth();
      toast.error(t("errors.expiredToken"));
    } else if (error.response?.status === 401) {
      resetAuth();
      toast.error(t("errors.expiredToken"));
    } else {
      toast.error(t("errors.unknowError"));
    }
  };

  return { triggerError };
};
