import { Button } from "@components/button/button";
import Heading from "@components/font/heading";
import { Icon } from "@iconify/react";
import { theme } from "@styles/themes";
import React from "react";
import { Col, Row } from "react-bootstrap";
import AccordianProfile from "./accordianProfile";
import styled from "styled-components";
import { useGetProfiles } from "@api/useGetProfiles";
import SkeletonLoader from "@components/loader/skeleton";
const HiddenBelowLgCol = styled(Col)`
  @media (max-width: 1199px) {
    display: none;
  }
`;
const DesktopProfileCard = ({
  setAddProfileOpen,
  settingOpen,
  setEditBookieOpen,
  setAddBookieOpen,
  setDrawerRecordOpen,
  handleDropdownMenuClick,
  handleArchieveDeactivateProfile,
  handleArchieveActivateProfile,
  handleBookieDropdownMenuClick,
  setPausePlay,
}: any) => {
  const { data, isLoading } = useGetProfiles({});
  return (
    <>
      <Row className="align-items-center" style={{ padding: "0 12px" }}>
        <Col lg={3} md={3} sm={3} xs={3}>
          <Heading
            title="profile.profile"
            fontSize="14px"
            fontWeight="400"
            color={theme.colors.white.dark}
          />
        </Col>
        <Col lg md sm xs>
          <Heading
            title="profile.profit"
            fontSize="14px"
            fontWeight="400"
            color={theme.colors.white.dark}
          />
        </Col>
        <HiddenBelowLgCol lg md sm xs>
          <Heading
            title="profile.state"
            fontSize="14px"
            fontWeight="400"
            color={theme.colors.white.dark}
          />
        </HiddenBelowLgCol>
        <HiddenBelowLgCol lg md sm xs>
          <Heading
            title="profile.bank"
            fontSize="14px"
            fontWeight="400"
            color={theme.colors.white.dark}
          />
        </HiddenBelowLgCol>
        <HiddenBelowLgCol lg md sm xs>
          <Heading
            title="profile.totalBank"
            fontSize="14px"
            fontWeight="400"
            color={theme.colors.white.dark}
          />
        </HiddenBelowLgCol>
        <HiddenBelowLgCol lg md sm xs>
          <Heading
            title="profile.openBets"
            fontSize="14px"
            fontWeight="400"
            color={theme.colors.white.dark}
          />
        </HiddenBelowLgCol>
        <Col lg md sm xs style={{ textAlign: "right" }}>
          <Button
            startIcon={
              <Icon icon="cuida:user-add-outline" width="20" height="20" />
            }
            title="profile.add"
            outline
            size="sm"
            style={{
              fontWeight: "700",
              fontSize: "14px",
              borderRadius: "8px",
            }}
            onClick={() => setAddProfileOpen(true)}
          />
        </Col>
      </Row>
      {isLoading ? (
        <Col lg={12} md={12} sm={12} xs={12} className="mt-2">
          <SkeletonLoader />
        </Col>
      ) : (
        data?.profiles?.map((elem: any, index: number) => (
          <AccordianProfile
            key={index}
            elem={elem}
            index={index}
            setEditBookieOpen={setEditBookieOpen}
            setAddBookieOpen={setAddBookieOpen}
            setDrawerRecordOpen={setDrawerRecordOpen}
            handleDropdownMenuClick={handleDropdownMenuClick}
            handleArchieveDeactivateProfile={handleArchieveDeactivateProfile}
            handleArchieveActivateProfile={handleArchieveActivateProfile}
            handleBookieDropdownMenuClick={handleBookieDropdownMenuClick}
            setPausePlay={setPausePlay}
          />
        ))
      )}
    </>
  );
};

export default DesktopProfileCard;
