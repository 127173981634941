import Heading from "@components/font/heading";
import { TextField } from "@components/form/textField";
import { theme } from "@styles/themes";
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import PaymentDetailInfo from "@components/paymentInfoCard";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Button } from "@components/button/button";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import search from "@assets/icons/search.svg";
import { useForm } from "react-hook-form";
import PaymentModal from "@components/modal/PaymentModal";
import CopyModal from "@components/modal/CopyModal";
import { useGetPaymentSummary } from "@api/useGetPaymentSummary";
import { paymentMethodText } from "@utils/payments";
import { PaymentsRecord } from "@api/types";
import SkeletonLoader from "@components/loader/skeleton";

const StyledSeeMore = styled.h1`
  font-size: 14px;
  font-weight: 400;
  font-family: Open Sans, sans-serif;
  color: ${theme.colors.white.normal};
  text-decoration: underline;
`;

const payments = [
  "Accepted",
  "Waiting",
  "Cancelled",
  "Accepted",
  "Waiting",
  "Cancelled",
  "Accepted",
  "Accepted",
  "Accepted",
  "Waiting",
  "Cancelled",
  "Accepted",
];
const ResponsiveDiv = styled.div`
  padding-right: 32px;

  @media (max-width: 1200px) and (min-width: 740px) {
    padding-right: 0;
  }
  @media (max-width: 739px) {
    padding-right: 0;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 30px 0;

  @media (max-width: 768px) {
    margin: 24px 0 0 0; /* Adjust margin for mobile */
  }
`;

const ModalContent = ({ firsttext, accountNumber }) => {
  const [tooltipVisible, setTooltipVisible] = React.useState(false);
  const handleCopy = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setTooltipVisible(true);
        setTimeout(() => setTooltipVisible(false), 1000);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  return (
    <div>
      <div>
        <Heading
          fontSize="14px"
          title="Use the concept below in your transfer"
          fontWeight="400"
          style={{ marginBottom: "16px" }}
          color={theme.colors.white.normal}
        />
        <div
          style={{
            borderRadius: "8px",
            backgroundColor: theme.colors.grey.dark,
            padding: "16px",
          }}
          className="d-flex align-items-center justify-content-between"
        >
          <Heading
            fontSize="20px"
            title={firsttext}
            fontWeight="700"
            color={theme.colors.white.normal}
          />
          <Icon
            icon="ph:copy"
            color={theme.colors.white.normal}
            width={24}
            style={{ cursor: "pointer" }}
            onClick={() => handleCopy(firsttext)}
          />
        </div>
      </div>
      <div style={{ marginTop: "24px" }}>
        <Heading
          fontSize="14px"
          title="Bank account"
          fontWeight="400"
          style={{ marginBottom: "16px" }}
          color={theme.colors.white.normal}
        />
        <div
          style={{
            borderRadius: "8px",
            backgroundColor: theme.colors.grey.dark,
            padding: "16px",
          }}
          className="d-flex align-items-center justify-content-between"
        >
          <Heading
            fontSize="20px"
            title={accountNumber}
            fontWeight="700"
            color={theme.colors.white.normal}
          />
          <Icon
            icon="ph:copy"
            color={theme.colors.white.normal}
            width={24}
            onClick={() => handleCopy(accountNumber)}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
    </div>
  );
};

const PaymentRecord = () => {
  const navigate = useNavigate();
  // variables
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { data } = useGetPaymentSummary();
  const [visiblePayments, setVisiblePayments] = useState<PaymentsRecord[]>([]);
  const { control, watch } = useForm({
    defaultValues: {
      search: "",
    },
  });
  const watchSearch = watch("search");

  // effects
  useEffect(() => {
    let list = isMobile
      ? data?.paymentsRecord?.slice(0, 3)
      : data?.paymentsRecord;

    if (watchSearch) {
      list = list?.filter((item) =>
        item?.paymentAmount?.toString().includes(watchSearch)
      );
    }

    setVisiblePayments(list);
  }, [watchSearch, data]);

  // returns
  return (
    <ResponsiveDiv>
      <StyledContainer>
        <div className="d-none d-md-block">
          <Heading
            fontSize="20px"
            title="Payment record"
            fontWeight="700"
            color={theme.colors.white.normal}
          />
        </div>
        <div className="d-block d-md-none">
          <Heading
            fontSize="17px"
            title="Payment record"
            fontWeight="400"
            color={theme.colors.white.dark}
          />
        </div>
        <div className="d-none d-md-block">
          <TextField
            placeholder="Search"
            name="search"
            size="sm"
            control={control}
            endIcon={
              <Icon icon="majesticons:search-line" color="#BFBFBF" width={24} />
            }
          />
        </div>
      </StyledContainer>
      <Row style={{ marginBottom: "22px" }}>
        <Col xl={3} lg={3} md={3} sm={3} className="d-none d-md-block">
          <Heading
            fontSize="14px"
            title="Credits"
            fontWeight="400"
            color={theme.colors.white.dark}
          />
        </Col>
        <Col xl={3} lg={3} md={3} sm={3} className="d-none d-md-block">
          <Heading
            fontSize="14px"
            title="Payment method"
            fontWeight="400"
            color={theme.colors.white.dark}
          />
        </Col>
        <Col xl={2} lg={2} md={2} sm={2} className="d-none d-md-block">
          <Heading
            fontSize="14px"
            title="Date"
            fontWeight="400"
            color={theme.colors.white.dark}
          />
        </Col>
        <Col xl={3} lg={3} md={3} sm={3} className="d-none d-md-block">
          <Heading
            fontSize="14px"
            title="State"
            fontWeight="400"
            color={theme.colors.white.dark}
          />
        </Col>
        <Col xl={1} lg={1} md={1} sm={1} className="d-none d-md-block"></Col>

        <Col xl={12} lg={12} md={12} sm={12}>
          {visiblePayments ? (
            visiblePayments?.map((elem, index) => (
              <PaymentDetailInfo
                key={index}
                amount={elem.paymentAmount}
                paymentMethod={paymentMethodText[elem.method]}
                timestamp={elem.uploadTimestamp}
                status={elem.status}
                invoiceUrl={elem.invoiceUrl}
              />
            ))
          ) : (
            <SkeletonLoader />
          )}
          {isMobile && visiblePayments && (
            <div className="my-3 mb-5">
              <StyledSeeMore onClick={() => navigate("records")}>
                See more
              </StyledSeeMore>
            </div>
          )}
        </Col>
      </Row>
    </ResponsiveDiv>
  );
};

export default PaymentRecord;
