import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface HeadingProps {
  fontSize?: string;
  fontWeight?: string;
  fontFamily?: string;
  color?: string;
  children?: React.ReactNode;
  title?: any;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const StyledHeading = styled.h1<HeadingProps>`
  font-size: ${(props) => props.fontSize || "24px"};
  font-weight: ${(props) => props.fontWeight || "400"};
  font-family: ${(props) => props.fontFamily || "Open Sans, sans-serif"};
  color: ${(props) => props.color || "#000"};
  margin-bottom: 0;
  word-break: break-word;
  line-height: 24px;
`;

const Heading = ({
  fontSize,
  fontWeight,
  fontFamily,
  color,
  title,
  className,
  style,
  onClick,
}: HeadingProps) => {
  const { t } = useTranslation();
  const translatedText = t(title).replace(/\n/g, "<br />");

  return (
    <StyledHeading
      fontSize={fontSize}
      fontWeight={fontWeight}
      fontFamily={fontFamily}
      color={color}
      className={className}
      style={style}
      onClick={onClick}
      dangerouslySetInnerHTML={{ __html: translatedText }}
    />
  );
};

export default Heading;
