import { useGetAddresses } from "@api/useGetAddress";
import { Button } from "@components/button/button";
import Heading from "@components/font/heading";
import FullScreenLoader from "@components/loader/Loader";
import { usePayment } from "@context/PaymentContext";
import { TabContext } from "@context/TabContext";
import { theme } from "@styles/themes";
import { paymentMethodText } from "@utils/payments";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useNavigation } from "react-router-dom";
import { PaymentFooter } from "./components/paymentFooter";

const StepThree = ({ handleClick }) => {
  const tabContext = useContext(TabContext);
  const { setActiveTab, activeTab } = tabContext;
  const { data } = useGetAddresses();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const savedBillingId = localStorage.getItem("billingId");
  const {
    amount,
    convertToCurrencyAmount,
    paymentMethod,
    currency,
    isLoading,
    credit,
    billingDetails,
  } = usePayment();

  // Log the filtered address or handle it as needed
  return (
    <div>
      <div
        className="my-4 d-block d-md-none"
        style={{
          backgroundColor: theme.colors.grey.dark,
          padding: "16px",
          borderRadius: "8px",
        }}
      >
        <Heading
          title={`${credit} ${t("payments.credits")}`}
          fontSize="24px"
          fontWeight="700"
          color={theme.colors.white.normal}
        />
      </div>

      <div>
        <Heading
          title="Currency"
          fontSize="17px"
          fontWeight="700"
          color={theme.colors.white.normal}
        />

        <Heading
          className="mt-2"
          title={currency.toUpperCase()}
          fontSize="17px"
          fontWeight="400"
          color={theme.colors.white.dark}
        />
      </div>

      <div className="mt-4">
        <Heading
          title="Billing details"
          fontSize="17px"
          fontWeight="700"
          color={theme.colors.white.normal}
        />

        <Heading
          className="mt-2"
          style={{ width: "60%" }}
          title={`${billingDetails?.name} ${billingDetails?.surname} 
            ${billingDetails?.address}, ${billingDetails?.addressExtra},
          ${billingDetails?.city} (${billingDetails?.country})
          `}
          fontSize="17px"
          fontWeight="400"
          color={theme.colors.white.dark}
        />
      </div>

      <div className="mt-4">
        <Heading
          title="Payment method"
          fontSize="17px"
          fontWeight="700"
          color={theme.colors.white.normal}
        />

        <Heading
          className="mt-2"
          style={{ width: "60%" }}
          title={paymentMethodText[paymentMethod]}
          fontSize="17px"
          fontWeight="400"
          color={theme.colors.white.dark}
        />
      </div>

      <PaymentFooter buttonTitle="Pay" onClick={handleClick} />
    </div>
  );
};

export default StepThree;
