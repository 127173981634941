import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import { Icon } from "@iconify/react";
import Tabination from "./tabination";
import { useNavigate } from "react-router-dom";
import { TabContext } from "@context/TabContext";
import CreditDetailCard from "../paymentSection/creditDetailCard";
import styled from "styled-components";
import { usePayment } from "@context/PaymentContext";
import FullScreenLoader from "@components/loader/Loader";

const ResponsiveDiv = styled.div`
  padding: 32px 20px 32px 32px;

  @media (max-width: 1200px) and (min-width: 740px) {
    padding-right: 20px;
  }
  @media (max-width: 739px) {
    padding: 20px 0 0 0px;
  }
`;

const ResponsiveHeading = styled.div`
  margin: 22px 12px 0 0px;
  @media (max-width: 1200px) {
    margin: 22px 12px 32px 0px;
  }
  @media (max-width: 930px) {
    margin: 22px 12px 0px 0px;
  }
`;

const BillingDetailSection = () => {
  const navigate = useNavigate();
  const { currency, amount, resetPayment } = usePayment();
  const tabContext = useContext(TabContext);
  const { activeTab, setActiveTab } = tabContext;
  const { isLoading } = usePayment();
  const billingRef = useRef<any>(null);
  const submitForm = () => {
    if (billingRef.current) {
      billingRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  };

  useEffect(() => {
    if (!currency || !amount) {
      return navigate("/payments");
    }
  }, [currency, amount]);

  const handleNavigate = (tab: any) => {
    if (tab === 0) {
      navigate(-1);
    } else if (tab === 1) {
      setActiveTab(0);
    } else {
      resetPayment();
      setActiveTab(1);
    }
  };

  return (
    <ResponsiveDiv>
      <ResponsiveHeading className="d-none d-md-block">
        <div className="d-flex align-items-center">
          <Icon
            className="me-3"
            icon="solar:arrow-left-outline"
            width="24"
            height="24"
            color={theme.colors.white.normal}
            onClick={() => handleNavigate(activeTab)}
          />
          <Heading
            fontSize="29px"
            title="Get Credits"
            fontWeight="700"
            color={theme.colors.white.normal}
          />
        </div>
      </ResponsiveHeading>
      <Row className="g-0">
        <Col
          sm={12}
          xs={12}
          md={12}
          xl={9}
          lg={12}
          className="order-xl-1 order-lg-1 order-md-1 order-sm-2 order-2"
        >
          <Tabination billingRef={billingRef} submitForm={submitForm} />
        </Col>
        <Col
          style={{ position: "relative" }}
          xs={12}
          sm={12}
          md={12}
          xl={3}
          lg={12}
          className="order-xl-2 order-lg-2 order-md-2 order-sm-1 order-1 d-none d-md-block mb-4"
        >
          <CreditDetailCard submitForm={submitForm} />
        </Col>
      </Row>
      {isLoading && <FullScreenLoader />}
    </ResponsiveDiv>
  );
};

export default BillingDetailSection;
