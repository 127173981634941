import Heading from "@components/font/heading";
import { usePayment } from "@context/PaymentContext";
import { TabContext } from "@context/TabContext";
import { Icon } from "@iconify/react";
import { theme } from "@styles/themes";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ProfileHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location?.pathname;

  const handleCross = () => {
    if (pathname == "/profile/billingdetails") {
      navigate("/profile/billingdetails/add");
    } else {
      navigate("/profile");
    }
  };

  return (
    <div>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{
          borderBottom: "0.8px solid",
          borderBottomColor: theme.colors.grey.dark,
          padding: "14px  20px 14px 20px",
        }}
      >
        <div className="d-flex align-items-center">
          <Icon
            className="me-3"
            icon="solar:arrow-left-outline"
            width="24"
            height="24"
            onClick={() => navigate(-1)}
            color={theme.colors.white.normal}
          />
          <Heading
            fontSize="20px"
            title={
              pathname == "/profile/mydata"
                ? "profile.myAccount"
                : pathname == "/profile/billingdetails"
                ? "profile.billingDetails"
                : pathname == "/profile/billingdetails/add"
                ? "profile.addBillingDetails"
                : pathname=="/profile/setting"?"profile.setting":"profile.contactUs"
            }
            fontWeight="700"
            color={theme.colors.white.normal}
          />
        </div>
        <div className="d-flex align-items-center">
          <Icon
            onClick={handleCross}
            icon={
              pathname == "/profile/billingdetails"
                ? "f7:plus-app"
                : "charm:cross"
            }
            width="24"
            height="24"
            color={theme.colors.white.normal}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
