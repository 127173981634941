import { TabContext } from "@context/TabContext";
import { theme } from "@styles/themes";
import React from "react";
import StepOne from "./stepOne";
import { useForm } from "react-hook-form";
import Heading from "@components/font/heading";
import { Container } from "react-bootstrap";
import { Button } from "@components/button/button";
import StepTwo from "./stepTwo";
import StepThree from "./stepThree";
import StepFour from "./stepfour";
import StepFive from "./stepFive";
import { useNavigate } from "react-router-dom";
import request from "@utils/request";
import { useTranslation } from "react-i18next";
import { useCheckEmail } from "../../api/useCheckEmail";
import { useCheckPhoneNumber } from "../../api/useCheckPhoneNumber";
import { useCheckNickname } from "../../api/useCheckNickname";
import { useErrorHandler } from "@hooks/useErrorHandler";
import { useSignUp } from "../../api/useSignUp"; // Adjust the import path as necessary
import FullScreenLoader from "@components/loader/Loader";
import AlertModal from "@components/modal/AlertModal";

const stepTitles = [
  "Tell us about yourself", // Step 1
  "Choose your nickname", // Step 2
  "Create a secure password", // Step 3
  "Receive notifications via Telegram", // Step 4
  "Finally, please take a moment to read our Terms and Conditions", // Step 5
];

const Stepper = () => {
  const { t } = useTranslation();
  const { signUpTab, setSignUpTab } = React.useContext(TabContext);
  const [completedSteps, setCompletedSteps] = React.useState<number[]>([]);
  const navigate = useNavigate();
  const {
    control,
    setValue,
    watch,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phonePrefix: null,
      phoneNumber: null,
      nickname: "",
      password: "",
      confirmPassword: "",
      radio_select: "",
      telegramUser: "",
      termsConditions: "",
    },
  });

  // register user
  const {
    mutate: startSignUp,
    isLoading: isSignUpLoading,
    isError: isSignUpError,
    isSuccess: isSignUpSuccess,
    reset: resetSignUp,
  } = useSignUp();

  // Email Check Hook
  const checkEmail = useCheckEmail({
    onSuccess: (res) => {
      if (res.success) {
        setSignUpTab(1);
      }
    },
    onError: (err) => {
      if (err.response?.status === 409) {
        setError("email", {
          type: "manual",
          message: t("signUp.emailAlreadyRegistered"), // Replace with `t` if using i18n
        });
      }
    },
  });

  // Phone Number Check Hook
  const checkPhoneNumber = useCheckPhoneNumber({
    onSuccess: (res) => {
      if (res.success) {
        setSignUpTab(1);
      }
    },
    onError: (err) => {
      if (err.response?.status === 409) {
        setError("phoneNumber", {
          type: "manual",
          message: t("signUp.phoneNumberAlreadyExists"), // Replace with `t` if using i18n
        });
      }
    },
  });

  // Nickname Check Hook
  const checkNickname = useCheckNickname({
    onSuccess: (res) => {
      if (res.success) {
        setSignUpTab(2);
      }
    },
    onError: (err) => {
      if (err.response?.status === 409) {
        setError("nickname", {
          type: "manual",
          message: t("signUp.usernameAlreadyExist"), // Replace with `t` if using i18n
        });
      }
    },
  });

  // Handler Functions
  const handleCheckEmailExist = () => {
    const email = watch("email");
    checkEmail.mutate({ email });
  };

  const handleCheckNumberExist = () => {
    const phoneNumber = watch("phoneNumber");
    const phonePrefix = watch("phonePrefix")?.value;
    checkPhoneNumber.mutate({ phoneNumber, phonePrefix });
  };

  const handleCheckNickNameExist = () => {
    const nickname = watch("nickname");
    checkNickname.mutate({ nickname });
  };

  // Function to handle form submission
  const onSubmit = async (values: any) => {
    const formData = {
      nickname: values?.nickname,
      email: values?.email,
      password: values?.password,
      firstName: values?.firstName,
      lastName: values?.lastName,
      phoneNumber: values?.phoneNumber,
      phonePrefix: values?.phonePrefix?.value,
      telegramUser: values?.telegramUser,
      termsConditions: values?.termsConditions,
    };

    startSignUp(formData);
  };

  // Function to handle validation and step navigation
  const onContinue = (data: any) => {
    // Proceed step by step if validation succeeds
    if (signUpTab === 0) {
      setCompletedSteps([...completedSteps, 0]);
      handleCheckEmailExist();
    } else if (signUpTab === 1) {
      setCompletedSteps([...completedSteps, 1]);
      handleCheckNickNameExist();
    } else if (signUpTab === 2) {
      setCompletedSteps([...completedSteps, 2]);
      setSignUpTab(3);
    } else if (signUpTab === 3) {
      setCompletedSteps([...completedSteps, 3]);
      setSignUpTab(4);
    } else if (signUpTab === 4) {
      // Step 5 reached, trigger final form submission
      setCompletedSteps([...completedSteps, 4]);
      handleSubmit(onSubmit)();
    }
  };

  const onStepChange = (index: number) => {
    if (index < signUpTab) {
      // Remove all steps ahead of the current index
      setCompletedSteps(completedSteps.filter((step) => step <= index));
    }
    setSignUpTab(index);
  };

  const handleSignUpSuccess = () => {
    resetSignUp();
    setCompletedSteps([]);
    navigate("/login");
  };

  return (
    <div style={{ position: "relative" }}>
      <div className="d-block d-md-none mt-3">
        <div className="d-flex align-items-center mb-3">
          {[...Array(5)].map((elem: any, index: number) => {
            return (
              <div
                onClick={() => setSignUpTab(index)}
                key={index}
                style={{
                  margin: "0 10px",
                  width: "100%",
                  borderBottom: `2px solid ${
                    signUpTab === index || completedSteps.includes(index)
                      ? theme.colors.main.normal
                      : theme.colors.grey.normal
                  }`,
                }}
              />
            );
          })}
        </div>
        <Container>
          <Heading
            title={stepTitles[signUpTab]}
            fontSize="20px"
            fontWeight="700"
            style={{ margin: "30px 0 10px 0" }}
            color={theme.colors.white.normal}
          />
          {signUpTab === 0 ? (
            <StepOne control={control} setValue={setValue} errors={errors} />
          ) : signUpTab === 1 ? (
            <StepTwo control={control} setValue={setValue} errors={errors} />
          ) : signUpTab === 2 ? (
            <StepThree
              control={control}
              setValue={setValue}
              errors={errors}
              watch={watch}
            />
          ) : signUpTab === 3 ? (
            <StepFour control={control} setValue={setValue} errors={errors} />
          ) : (
            <StepFive control={control} setValue={setValue} errors={errors} />
          )}
        </Container>
      </div>
      <div
        className="d-block d-md-none"
        style={{
          position: "fixed",
          borderTop: "0.5px solid",
          borderTopColor: theme.colors.grey.dark,
          zIndex: 100,
          bottom: 0,
          left: 0,
          width: "100%",
          backgroundColor: theme.colors.grey.darker,
          textAlign: "center",
          padding: "10px",
        }}
      >
        <div className="d-flex justify-content-between">
          {signUpTab === 3 && (
            <Button
              outline
              title={"payments.skip"}
              style={{
                width: "100%",
                borderRadius: "8px",
                fontWeight: "700",
                fontSize: "14px",
              }}
              onClick={() => setSignUpTab(4)}
            />
          )}
          <Button
            title={
              signUpTab === 4 ? "addEditUser.submitButton" : "payments.continue"
            }
            style={{
              width: "100%",
              borderRadius: "8px",
              fontWeight: "700",
              fontSize: "14px",
            }}
            onClick={handleSubmit(onContinue)}
            isLoading={isSignUpLoading}
          />
        </div>
      </div>

      <AlertModal
        open={isSignUpError}
        type="error"
        title={"signUp.somethingWrong"}
        description={"signUp.somethingWrongDescription"}
        onActionClick={resetSignUp}
      />
      <AlertModal
        open={isSignUpSuccess}
        type="success"
        title={"signUp.success"}
        description={"signUp.successDescription"}
        onActionClick={handleSignUpSuccess}
      />
      {isSignUpLoading && <FullScreenLoader />}
    </div>
  );
};

export default Stepper;
