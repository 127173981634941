import CountryCodePicker from "@components/form/phoneInput";
import { TextField } from "@components/form/textField";
import { theme } from "@styles/themes";
import { Country } from "country-state-city";
import React from "react";
import { Col, Row } from "react-bootstrap";

const StepFour = ({ control, setValue, errors }: any) => {
  return (
    <div>
      <Row className="g-2">
        <Col md={12} sm={12} xs={12}>
          <TextField
            control={control}
            name="telegramUser"
            bgColor={theme.colors.grey.dark}
            size="sm"
            placeholder="@Telegram"
            errors={errors.telegramUser}
            rules={{ required: false }}
          />
        </Col>
      
      </Row>
    </div>
  );
};

export default StepFour;
