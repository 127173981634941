import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import React, { useContext } from "react";
import bell from "@assets/icons/bell.svg";
import setting from "@assets/icons/setting.svg";
import { Icon } from "@iconify/react";
import AvailableCredit from "@components/creditCardBox/availableCredit";
import { Button } from "@components/button/button";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import NotificatiuonComponentDesk from "../notificationComponentDesk";
import { TabContext } from "@context/TabContext";

const HoverDiv = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 8px 8px 0 0;
  padding: 12px;
  border-bottom: 1px solid ${theme.colors.grey.darker};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${theme.colors.grey
      .darker}; // Change background color on hover
  }
`;
const HoverDivTwo = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 0 0 8px 8px;
  padding: 12px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${theme.colors.grey
      .darker}; // Change background color on hover
  }
`;
const StyledContainer = styled.div`
  margin-bottom: 28px;

  @media (max-width: 768px) {
    display: none; /* Hide the div on mobile */
  }
`;
const Head = ({ title }: { title?: string }) => {
  const navigate = useNavigate();
  const [dropDown, setDropDown] = React.useState<boolean>(false);
  const handleDropDown = () => {
    setDropDown(!dropDown);
  };
  const { notificationCardOpen, setNotificationCardOpen } =
    useContext(TabContext);

  return (
    <StyledContainer>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <Icon
            className="me-3"
            icon="solar:arrow-left-outline"
            width="30"
            height="30"
            color={theme.colors.white.normal}
            onClick={() => navigate(-1)}
          />
          <Heading
            title={title}
            fontSize="29px"
            fontWeight="700"
            color={theme.colors.white.normal}
          />
        </div>
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ position: "relative" }}
        >
          <div>
            <div style={{position:'relative'}}>
              <img
                src={bell}
                style={{ width: "1.2rem", marginRight: "15px" }}
                alt="bell icon"
                onClick={() => setNotificationCardOpen(!notificationCardOpen)}
              />
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  right:12,
                  borderRadius: "50%",
                  backgroundColor: theme.colors.red.normalHover,
                  position:'absolute',
                  top:0,
                }}
              />
            </div>

            <NotificatiuonComponentDesk />
          </div>

          <img
            src={setting}
            style={{ width: "1.2rem", marginRight: "20px" }}
            alt="bell icon"
          />
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer", position: "relative" }}
            onClick={handleDropDown}
          >
            <Heading
              title="Pedro MGV"
              fontSize="17px"
              fontWeight="400"
              className="me-1"
              color={theme.colors.white.normal}
            />
            <Icon
              icon="fe:arrow-down"
              width="18"
              height="18"
              style={{ color: theme.colors.white.normal }}
            />
            <div
              style={{
                backgroundColor: theme.colors.grey.dark,
                borderRadius: "8px",
                position: "absolute",
                display: dropDown ? "block" : "none",
                top: 36,
                right: 12,
                width: "max-content",
                minWidth: "11em",
                boxShadow: "0px 0px 3px 0px rgba(255, 255, 255, 0.5)",
              }}
            >
              <HoverDiv onClick={() => navigate("/profile/mydata")}>
                <Icon
                  icon="heroicons-outline:user"
                  width="16"
                  height="16"
                  style={{
                    color: theme.colors.white.normal,
                    marginRight: "6px",
                  }}
                />
                <Heading
                  title="profile.myData"
                  fontSize="14px"
                  fontWeight="400"
                  color={theme.colors.white.normal}
                />
              </HoverDiv>

              <HoverDivTwo onClick={() => navigate("/profile/billingDetails")}>
                <Icon
                  icon="heroicons-outline:document-text"
                  width="16"
                  height="16"
                  style={{
                    color: theme.colors.white.normal,
                    marginRight: "6px",
                  }}
                />
                <Heading
                  title="profile.billingDetails"
                  fontSize="14px"
                  fontWeight="400"
                  color={theme.colors.white.normal}
                />
              </HoverDivTwo>
            </div>
          </div>
        </div>
      </div>
    </StyledContainer>
  );
};

export default Head;
