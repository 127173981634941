// libraries
import { Icon } from "@iconify/react";
import React from "react";
import Modal from "react-bootstrap/Modal";

// components
import Heading from "@components/font/heading";
import { Button } from "@components/button/button";
import { theme } from "@styles/themes";

interface CopyModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  closeBtn?: string;
  actionBtn?: string;
  onActionClick?: () => void;
  heading: string;
  mainContent: React.ReactNode;
  mainContentStyle?: React.CSSProperties;
}

const CopyModal: React.FC<CopyModalProps> = ({
  open,
  setOpen,
  closeBtn,
  actionBtn,
  onActionClick,
  heading,
  mainContent,
  mainContentStyle,
}) => {
  return (
    <Modal show={open} aria-labelledby="contained-modal-title-vcenter" centered>
      <div
        style={{
          backgroundColor: theme.colors.grey.darker,
          padding: "0 24px 24px 24px",
          maxWidth: "565px",
          borderRadius: "8px",
          border: `0.5px solid ${theme.colors.grey.lightActive}`,
        }}
      >
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ padding: "14.5px 0" }}
        >
          <Heading
            fontSize="20px"
            title={heading}
            fontWeight="700"
            style={{ width: "100%" }}
            color={theme.colors.white.normal}
          />
          <Icon
            onClick={() => setOpen(false)}
            style={{ cursor: "pointer" }}
            icon="charm:cross"
            width="24"
            height="24"
            color={theme.colors.white.normal}
          />
        </div>
        <div style={{ padding: "24px 0", ...mainContentStyle }}>
          {mainContent}
        </div>
        <div className="justify-content-end d-flex">
          {closeBtn && (
            <Button
              outline
              onClick={() => setOpen(false)}
              title={closeBtn}
              className="me-2"
              style={{
                fontSize: "14px",
                fontWeight: "700",
                borderRadius: "8px",
              }}
            />
          )}
          {actionBtn && (
            <Button
              onClick={onActionClick}
              title={actionBtn}
              style={{
                fontSize: "14px",
                fontWeight: "700",
                borderRadius: "8px",
              }}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CopyModal;
