import { useMutation } from "react-query";
import { AxiosError } from "axios";
import { toast } from "react-toastify";

// misc
import request from "@utils/request";
import { ServerResponse } from "./types";
import { useErrorHandler } from "@hooks/useErrorHandler";
import { useTranslation } from "react-i18next";
import { MyAccountData } from "./types";

export const useCleanNotification = ({
  onError,
  onSuccess,
}: {
  onSuccess?: (res: ServerResponse<any>) => void;
  onError?: (res: AxiosError<ServerResponse<unknown>>) => void;
} = {}) => {
  // variables
  const { triggerError } = useErrorHandler();
  const { t } = useTranslation();

  // request
  const query = useMutation<
    ServerResponse<unknown>,
    ServerResponse<unknown> | undefined,
    any
  >(["useCleanNotification"], async (id: any) => {
    try {
      const res = await request<ServerResponse<unknown>>({
        method: "patch",
        url: `/notifications/${id}`,
        data: { isRead: true },
      });
      onSuccess && onSuccess(res.data);
      return res.data;
    } catch (error) {
      onError && onError(error);
      triggerError({ error });
    }
  });

  // return
  return query;
};
