import { theme } from '@styles/themes';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

const GraphDashboard = ({data}) => {
    const result = data?.xAxis.map((item, index) => ({
        x: item,
        y: data?.yAxis[index]
    })) || [];

    const series = [{
        name: 'InBetia',
        data: result
    }];

    const options = {
        chart: {
            type: 'area',
            stacked: false,
            height: 350,
            zoom: {
                type: 'x',
                enabled: false,
                autoScaleYaxis: true
            },
        },
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 0,
        },
      
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                type: 'vertical',
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100],
                gradientToColors: ['#04F1D1', 'rgba(4, 217, 188, 0.8)', 'rgba(3, 193, 167, 0.6)', 'rgba(3, 181, 157, 0.4)', 'rgba(2, 145, 125, 0.2)', 'rgba(2, 108, 94, 0.1)', 'rgba(1, 84, 73, 0)'],
                colorStops: [
                    [
                        {
                            offset: 7.39,
                            color: '#04F1D1',
                        },
                        {
                            offset: 27.6,
                            color: 'rgba(4, 217, 188, 0.8)',
                        },
                        {
                            offset: 45.52,
                            color: 'rgba(3, 193, 167, 0.6)',
                        },
                        {
                            offset: 62.58,
                            color: 'rgba(3, 181, 157, 0.4)',
                        },
                        {
                            offset: 79.1,
                            color: 'rgba(2, 145, 125, 0.2)',
                        },
                        {
                            offset: 95.24,
                            color: 'rgba(2, 108, 94, 0.1)',
                        },
                        {
                            offset: 110.88,
                            color: 'rgba(1, 84, 73, 0)',
                        }
                    ]
                ]
            },
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val.toFixed(0);
                },
            },
            title: {
                text: 'Price'
            },
        },
        xaxis: {
            type: 'datetime',
        },
       
        tooltip: {
            shared: false,
            y: {
                formatter: function (val) {
                    return val.toFixed(0);
                }
            }
        },
        grid: {
            borderColor: theme.colors.grey.dark,
            yaxis: {
                lines: {
                    show: true,
                    color: theme.colors.grey.dark,
                }
            },
            xaxis: {
                lines: {
                    show: false
                }
            }
        },
        stroke: {
            curve: 'straight',
            width: 0,
        },
    };

    return (
        <div>
            <div id="chart">
                <ReactApexChart 
                    options={options as any} 
                    series={series} 
                    type='area'
                    height={350}
                />
            </div>
        </div>
    );
};

export default GraphDashboard;
