import React, { createContext, useState, ReactNode, FC, useEffect } from "react";

// Define the shape of the toast context
interface ToastContextType {
  toastMessage: string;
  setToastMessage: (message: string) => void;
  toastVisible: boolean;
  setToastVisible: (visible: boolean) => void;
}

// Create the context with an empty default value
const ToastContext = createContext<ToastContextType | undefined>(undefined);

// Define the provider's props type
interface ToastProviderProps {
  children: ReactNode;
}

// Create the provider component
const ToastProvider: FC<ToastProviderProps> = ({ children }) => {
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastVisible, setToastVisible] = useState<boolean>(false);

  useEffect(() => {
    if (toastVisible) {
      const timer = setTimeout(() => {
        setToastVisible(false);
      }, 3000); // Toast will disappear after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [toastVisible]);

  return (
    <ToastContext.Provider
      value={{
        toastMessage,
        setToastMessage,
        toastVisible,
        setToastVisible,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};

export { ToastContext, ToastProvider }; 