// libraries
import axiosClient from "axios";
import type { InternalAxiosRequestConfig } from "axios";
import { APP_AUTH_TOKEN } from "./keys";

// misc

/**
 * Creates an initial 'axios' instance with custom settings.
 */

const request = axiosClient.create({
  baseURL: process.env.REACT_APP_BILLING_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

/**
 * add bearer token for authenticated requests
 */

request.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
  try {
    const token = localStorage.getItem(APP_AUTH_TOKEN);

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
  } catch (e) {
    // saving error
  }

  return config;
});

/**
 * Handle all responses. It is possible to add handlers
 * for requests, but it is omitted here for brevity.
 */
request.interceptors.response.use(
  async (res) => {
    return res;
  },
  async (err) => {
    return Promise.reject(err);
  }
);

export default request;
