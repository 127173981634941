import { Icon } from "@iconify/react";
import React from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import CheckedCircle from "@assets/icons/checkcircle.svg";
import CrossCircle from "@assets/icons/crossCircle.svg";
import { Button } from "@components/button/button";

const ModalContainer = styled.div`
  background-color: ${theme.colors.grey.darker};
  padding: 0 24px;
  max-width: 565px;
  border-radius: 8px;
  border: 0.5px solid ${theme.colors.grey.lightActive};
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14.5px 0;
`;

const IconStyled = styled(Icon)`
  width: 24px;
  height: 24px;
  color: ${theme.colors.white.normal};
  cursor: pointer;
`;

const ContentContainer = styled.div`
  padding: 24px 0;
  display: flex;
  align-items: center;
`;

const ImageStyled = styled.img`
  width: 24px;
  height: 24px;
  color: ${theme.colors.white.normal};
  margin-right: 8px;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 0 24px 0;
`;

const StyledButton = styled(Button)`
  font-size: 14px;
  font-weight: 700;
`;

interface AlertModalProps {
  open: boolean;
  title: string;
  description: string;
  onClose?(): void;
  closeBtnTitle?: string;
  actionBtnTitle?: string;
  onActionClick(): void;
  type?: "success" | "error";
}

const AlertModal: React.FC<AlertModalProps> = ({
  open,
  onClose,
  title,
  description,
  closeBtnTitle,
  actionBtnTitle,
  onActionClick,
  type,
}) => {
  // variables
  const { t } = useTranslation();

  // renders
  return (
    <Modal show={open} aria-labelledby="contained-modal-title-vcenter" centered>
      <ModalContainer>
        <HeaderContainer>
          <Heading
            fontSize="20px"
            title={title}
            fontWeight="700"
            style={{ width: "100%" }}
            color={theme.colors.white.normal}
          />
          <IconStyled icon="charm:cross" onClick={() => onClose()} />
        </HeaderContainer>
        <ContentContainer>
          <ImageStyled src={type === "success" ? CheckedCircle : CrossCircle} />
          <Heading
            fontSize="14px"
            title={description}
            fontWeight="400"
            style={{ width: "100%" }}
            color={theme.colors.white.normal}
          />
        </ContentContainer>
        <FooterContainer>
          {!!onClose && (
            <StyledButton
              outline
              onClick={() => onClose()}
              title={closeBtnTitle || t("actions.close")}
              className="me-2"
            />
          )}
          <StyledButton
            onClick={onActionClick}
            title={actionBtnTitle || t("actions.okay")}
          />
        </FooterContainer>
      </ModalContainer>
    </Modal>
  );
};

export default AlertModal;
