import { Button } from "@components/button/button";
import Heading from "@components/font/heading";
import { Icon } from "@iconify/react";
import { theme } from "@styles/themes";
import React, { useContext } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import Flag from "@assets/flags/ea.svg";
import avatar from "@assets/avatar.svg";
import Chip from "@components/chip";
import AccordianChip from "./AccordianChip";
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
  Type,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";
import { useNavigate } from "react-router-dom";
import { TabContext } from "@context/TabContext";
import { convertToCurrencyAmount } from "@utils/currency";
import { useUpdateBookies } from "@api/useUpdateBookies";
import { UpdateBookiesParams } from "@api/types";
import CustomToast from "@components/toastBar/CustomToast";
import { useGetProfiles } from "@api/useGetProfiles";
import { ToastContext } from "@context/ToastContext";

const LeftActions = ({
  pausePlay,
  setPausePlay,
  navigate,
  onUpdateBookie,
  bookies,
}) => (
  <TrailingActions>
    <SwipeAction destructive={false} onClick={() => {}}>
      <div className="d-flex align-items-center">
        {bookies?.isEnabled ? (
          <Icon
            style={{
              fontSize: "24px",
              color: theme.colors.white.normal,
              marginLeft: "18px",
            }}
            icon="heroicons-outline:pause"
            onClick={() => {
              setPausePlay(true);
              onUpdateBookie();
            }}
          />
        ) : (
          <Icon
            style={{
              fontSize: "24px",
              color: theme.colors.white.normal,
              marginLeft: "18px",
            }}
            icon="icon-park-outline:play"
            onClick={() => {
              setPausePlay(false);
              onUpdateBookie();
            }}
          />
        )}
        <Icon
          style={{
            fontSize: "24px",
            color: theme.colors.white.normal,
            marginLeft: "18px",
          }}
          icon="fluent:edit-28-regular"
          onClick={() =>
            navigate(`/profile/bookie/add/edit`, {
              state: {
                bookie: bookies?.bookie,
                isEnabled: bookies?.isEnabled,
                accountId: bookies?.accountId,
              },
            })
          }
        />
        <Icon
          style={{
            fontSize: "24px",
            color: theme.colors.white.normal,
            margin: "0 18px",
          }}
          icon="mage:book"
          onClick={() => navigate("/profile/bookie/record")}
        />
      </div>
    </SwipeAction>
  </TrailingActions>
);
const MobileAccordian = ({ index, bookies }: any) => {
  const {  refetch } = useGetProfiles({});

  const { profileIdSelected } = useContext(TabContext);

  const [activeKey, setActiveKey] = React.useState<any>(null);

  const navigate = useNavigate();

  const handleToggle = (key: any) => {
    setActiveKey(activeKey === key ? null : key);
  };
  const [pausePlay, setPausePlay] = React.useState<boolean>(false);

  const onClose = () => setToastVisible(false);

  const { setToastMessage, toastMessage, setToastVisible, toastVisible } =
    useContext(ToastContext);

  const { mutate: updateBookie, isLoading } = useUpdateBookies({
    onSuccess: (data) => {
      console.log("Bookie updated successfully:", data);
      refetch();
      setToastVisible(true);
    },
    onError: (error) => {
      console.error("Failed to update bookie:", error);
      setToastMessage("Failed to update bookie");
      setToastVisible(true);
    },
  });

  const handleUpdateBookie = () => {
    const updateData: UpdateBookiesParams = {
      bookieId: bookies?.accountId,
      isEnabled: !bookies?.isEnabled,
      bookie: bookies?.bookie,
      isRunning: true,
    };

    updateBookie(updateData);
    if (bookies?.isEnabled) {
      setToastMessage("Bookie paused");
    } else {
      setToastMessage("Bookie resumed");
    }
  };

  return (
    <>
      <SwipeableList
        fullSwipe={false}
        type={Type.IOS}
        style={{ marginBottom: "16px" }}
      >
        <SwipeableListItem
          listType={Type.IOS}
          fullSwipe={false}
          trailingActions={
            <LeftActions
              pausePlay={pausePlay}
              setPausePlay={setPausePlay}
              navigate={navigate}
              onUpdateBookie={handleUpdateBookie}
              bookies={bookies}
            />
          }
        >
          <Accordion activeKey={activeKey} style={{ width: "100%" }}>
            <div
              style={{
                backgroundColor: theme.colors.grey.dark,
                borderRadius: index != activeKey ? "8px" : "8px 8px 0 0",
                padding: "10px 14px",
                cursor: "pointer",
                width: "100%",
              }}
              onClick={() => handleToggle(index)}
            >
              <Row className="align-items-center">
                <Col lg={6} md={6} sm={6} xs={6}>
                  <div className="d-flex align-items-center">
                    <img
                      src={avatar}
                      style={{
                        width: "40px",
                        height: "40px",
                        marginRight: "10px",
                      }}
                    />

                    <div>
                      <Heading
                        title={bookies.username}
                        fontSize="14px"
                        fontWeight="400"
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          maxWidth: "80px",
                        }}
                        color={theme.colors.white.normal}
                      />
                      <div style={{ width: "max-content" }}>
                        <Chip
                          title={bookies?.error ? "Error" : "Active"}
                          color={
                            bookies?.error
                              ? theme.colors.red.normal
                              : theme.colors.green.normal
                          }
                          bgColor={theme.colors.grey.darker}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={4} xs={4}>
                  <Heading
                    title={convertToCurrencyAmount(bookies?.profit || 0, "eur")}
                    fontSize="14px"
                    fontWeight="700"
                    className="me-1"
                    color={theme.colors.white.normal}
                  />
                </Col>

                <Col
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  style={{ textAlign: "center" }}
                >
                  <Icon
                    icon={activeKey === index ? "fe:arrow-up" : "fe:arrow-down"} // Arrow icon changes based on state
                    width="18"
                    style={{ color: theme.colors.white.normal }}
                  />
                </Col>
              </Row>
            </div>

            <Accordion.Collapse
              eventKey={index}
              style={{
                backgroundColor: theme.colors.grey.dark,
                borderRadius: index != activeKey ? "8px" : "0px 0px 8px 8px",
              }}
            >
              <div className="mb-3" style={{ paddingLeft: "48px" }}>
                <div>
                  <Row
                    className="align-items-center"
                    style={{ padding: "0 16px" }}
                  >
                    <Col lg={6} md={6} sm={6} xs={6}>
                      <div className="d-flex align-items-center justify-content-between">
                        <Heading
                          title="profile.bank"
                          fontSize="14px"
                          fontWeight="400"
                          className="me-1"
                          color={theme.colors.white.normal}
                        />
                        <Heading
                          title={convertToCurrencyAmount(
                            bookies?.last_bank || 0,
                            "eur"
                          )}
                          fontSize="14px"
                          fontWeight="400"
                          className="me-1"
                          color={theme.colors.white.normal}
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <Heading
                          title="profile.totalBank"
                          fontSize="14px"
                          fontWeight="400"
                          className="me-1"
                          color={theme.colors.white.normal}
                        />
                        <Heading
                          title={convertToCurrencyAmount(
                            bookies?.total_bank || 0,
                            "eur"
                          )}
                          fontSize="14px"
                          fontWeight="400"
                          className="me-1"
                          color={theme.colors.white.normal}
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <Heading
                          title="profile.openBets"
                          fontSize="14px"
                          fontWeight="400"
                          className="me-1"
                          color={theme.colors.white.normal}
                        />
                        <Heading
                          title={bookies?.currentOB_number}
                          fontSize="14px"
                          fontWeight="400"
                          className="me-1"
                          color={theme.colors.white.normal}
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <Heading
                          title="profile.creditLimit"
                          fontSize="14px"
                          fontWeight="400"
                          className="me-1"
                          color={theme.colors.white.normal}
                        />
                        <Heading
                          title={`${bookies?.creditLimit}`}
                          fontSize="14px"
                          fontWeight="400"
                          className="me-1"
                          color={theme.colors.white.normal}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div style={{ padding: "6px 16px 16px 16px" }}>
                    {bookies?.error && (
                      <Heading
                        title={bookies?.errorMessage}
                        fontSize="12px"
                        fontWeight="400"
                        style={{ lineHeight: "18px" }}
                        color={theme.colors.red.normal}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Accordion.Collapse>
          </Accordion>
        </SwipeableListItem>
      </SwipeableList>
      <CustomToast
        message={toastMessage}
        onClose={onClose}
        isOpen={toastVisible}
      />
    </>
  );
};

export default MobileAccordian;
