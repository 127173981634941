import React from "react";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate } from "react-router-dom";
import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import { Button } from "@components/button/button";

const Paths = [
  { name: "Dashboard", link: "/dashboard" },
  { name: "Profiles", link: "/profile" },
  { name: "Stats", link: "/stats" },
  { name: "Credits", link: "/payments" },
];

const BottomBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location?.pathname;
  return (
    <div
      style={{
        position: "fixed",
        borderTop: "0.5px solid",
        borderTopColor: theme.colors.grey.dark,
        bottom: 0,
        left: 0,
        width: "100%",
        backgroundColor: theme.colors.grey.darker,
        textAlign: "center",
        padding: "10px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        {Paths.map((option, index) => (
          <div
            key={option.link}
            onClick={() => navigate(option.link)}
            style={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Icon
              icon={
                index === 0
                  ? "majesticons:home-line"
                  : index === 1
                  ? "heroicons-outline:user"
                  : index === 2
                  ? "heroicons-outline:chart-bar"
                  : "heroicons:circle-stack"
              }
              color={
                pathname.includes(option.link)
                  ? theme.colors.main.normal
                  : theme.colors.white.normal
              }
              width={24}
              style={{ marginBottom: "4px" }}
            />
            <Heading
              fontSize="12px"
              title={option.name}
              fontWeight="400"
              color={
                pathname.includes(option.link)
                  ? theme.colors.main.normal
                  : theme.colors.white.normal
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BottomBar;
