// libraries
import { useMutation } from "react-query";
import { AxiosError } from "axios";

// utils and hooks
import request from "@utils/request";
import { ServerResponse } from "./types";
import { useErrorHandler } from "@hooks/useErrorHandler";

// Types
export interface SignUpRequest {
  nickname: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  phonePrefix?: string;
  telegramUser?: string;
  termsConditions: boolean;
}

export interface SignUpResponse {
  message: string;
}

export type SignUpServerResponse = ServerResponse<SignUpResponse>;

// Hook
export const useSignUp = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (res: SignUpServerResponse) => void;
  onError?: (res: AxiosError<ServerResponse<unknown>>) => void;
} = {}) => {
  const { triggerError } = useErrorHandler();

  return useMutation<
    SignUpServerResponse,
    ServerResponse<unknown> | undefined,
    SignUpRequest
  >(["signUp"], async (data: SignUpRequest) => {
    try {
      const response = await request<SignUpServerResponse>({
        method: "post",
        url: "signup",
        data,
      });

      if (onSuccess) {
        onSuccess(response.data);
      }

      return response.data;
    } catch (error) {
      if (onError) {
        onError(error as AxiosError<ServerResponse<unknown>>);
      }
      triggerError({ error });
      throw error;
    }
  });
};
