import React from 'react';
import { useLocation } from 'react-router-dom';

const RedirectPay = () => {
  const location = useLocation();
  const htmlToRender = location.state == null || location.state == undefined ? <div></div> : location.state;

  return (
    <div dangerouslySetInnerHTML={{ __html: htmlToRender }} />
  );
};

export default RedirectPay;