import { theme } from "@styles/themes";
import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import search from "@assets/icons/search.svg";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

// Define the styled container for the select box
const SelectContainer = styled.div`
  position: relative;
  width: 100%;
`;

// Wrapper for input and arrow
const InputWrapper = styled.div<{
  error?: boolean;
  size: string;
  bgColor: string;
  isOpen: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${(props) =>
    props.size === "lg" ? "14px" : props.size === "sm" ? "5px 10px" : "10px"};
  border: 1px solid
    ${(props) => (props.error ? theme.colors.red.normal : props.bgColor)}; // Default border color
  border-radius: ${(props) => (props.isOpen ? "8px 8px 0 0" : "8px")};
  font-size: 16px;

  background: ${(props) => props.bgColor};
  width: 100%;
  color: ${theme.colors.white.normal};
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  ${(props) =>
    props.error &&
    `
    &::placeholder {
      color: ${theme.colors.red.normal}; // Placeholder text color
    };
    border-color: ${theme.colors.red.normal}; // Border color when there's an error
  `};
  &:focus-within {
    border-color: ${theme.colors.white
      .normalActive}; // Border color when focused
    border-bottom: none;
  }
`;

// Styled search input for the selected option
const SelectInput = styled.input<{
  error?: boolean;
}>`
  border: 0px solid ${theme.colors.grey.dark};
  background: transparent;
  color: ${theme.colors.white.normal};
  font-size: 16px;
  flex: 1;
  outline: none;
  padding-right: 24px; // Space for the arrow
  box-sizing: border-box;
  width: 100%;
  &::placeholder {
    color: ${theme.colors.white.dark};
  }
  ${(props) =>
    props.error &&
    `
    &::placeholder {
      color: ${theme.colors.red.normal}; // Placeholder text color
    };
   
  `}
`;

// Define the styled label component for the floating effect
const StyledLabel = styled.label<{ active: boolean; bgColor: string }>`
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  background: ${(props) => props.bgColor};
  padding: 0 5px;
  transition: 0.2s ease all;
  color: ${theme.colors.white.dark};
  pointer-events: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  ${({ active }) =>
    active &&
    `left:10px;
    top: 0px;
    z-index:5;
    font-size: 10px;
    color: ${theme.colors.white.normal};
  `}
`;

// Define the styled arrow component positioned to the right of the input box
const StyledArrow = styled.img<{ isOpen: boolean }>`
  position: absolute;
  right: 12px;
  width: 16px;
  height: 16px;
`;

// Styled dropdown options container
const OptionsContainer = styled.ul<{ isOpen: boolean; bgColor: string }>`
  position: absolute;
  width: 100%;
  max-height: ${(props) => (props.isOpen ? "200px" : "0px")};
  top: ${(props) => (props.isOpen ? "100%" : "85%")};
  overflow-y: auto;
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid
    ${(props) =>
      props.isOpen ? theme.colors.white.normalActive : props.bgColor};
  border-radius: ${(props) => (props.isOpen ? "0 0 8px 8px" : "8px")};
  background: ${(props) => props.bgColor};
  z-index: 100;
  transition: max-height 0.3s ease;
  box-sizing: border-box;

  border-color: ; // Border color when focused
  border-top: none;

  /* Custom scrollbar styling */
  scrollbar-width: thin;

  scrollbar-color: ${theme.colors.grey.darkActive} ${(props) => props.bgColor};

  /* For Webkit-based browsers like Chrome, Safari, etc. */
  &::-webkit-scrollbar {
    width: 8px;
    background: transparent; /* Remove the scrollbar track background */
  }

  &::-webkit-scrollbar-track {
    background: transparent; /* Remove track */
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.bgColor}; /* Thumb color */
    border-radius: 80px; /* Round thumb */
  }

  /* Remove up and down buttons (arrows) on scrollbar */
  &::-webkit-scrollbar-button {
    display: none; /* Hide the arrows */
    height: 0;
    width: 0;
  }
`;

// Styled individual option
const OptionItem = styled.li`
  padding: 10px;
  cursor: pointer;
  color: ${theme.colors.white.normal};

  &:hover {
    background-color: ${theme.colors.grey.darkActive};
  }
`;

// Define a container for the error message
const ErrorMessage = styled.div`
  color: ${theme.colors.red.normal};
  font-size: 12px;
  margin-top: 2px;
  margin-left: 8px;
`;

// Define the type for options
interface Option {
  value: string;
  label: string;
  flagSrc?: any;
}

// Define the props for SelectBox component
interface SelectBoxProps {
  options: any;
  placeholder?: string;
  size: string;
  bgColor: string;
  control?: any;
  name?: string;
  errors?: any;
  rules?: any;
  setValue?: any;
  watch?: any;
  selectLabel?: any;
  selectValue?: any;
  defaultValue?: string; // Add defaultValue prop
}

// Custom SearchSelectBox component
export const SearchSelectBox: React.FC<SelectBoxProps> = ({
  options,
  placeholder,
  size = "md",
  bgColor,
  control,
  name,
  errors,
  setValue,
  rules,
  watch,
  defaultValue
}) => {
  const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);
  const [inputValue, setInputValue] = useState<string>(defaultValue ?? ""); 
  const [error, setError] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const selectRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
useEffect(()=>{
  if(defaultValue){
    setInputValue(defaultValue)
  }
},[defaultValue])
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Filter options based on the input value
  useEffect(() => {
    setFilteredOptions(
      options.filter((option: any) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      )
    );
  }, [inputValue, options]);

  // Handle option selection
  const handleSelect = (option: Option) => {
    setValue(name, option.value); // Store the value in the form
    setInputValue(option.label); // Set the label to display
    setIsOpen(false);
    setError(false);
  };

  // Toggle dropdown on click
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={{ padding: "8px 0 0 0" }}>
      <SelectContainer ref={selectRef}>
        <StyledLabel active={true} bgColor={bgColor}>
          {placeholder}
        </StyledLabel>
        <InputWrapper
          error={errors}
          size={size}
          onClick={toggleDropdown}
          bgColor={bgColor}
          isOpen={isOpen}
        >
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <SelectInput
                  value={inputValue} // Show the input value
                  onChange={(e) => {
                    setInputValue(e.target.value); // Update input value and trigger filtering
                    setIsOpen(true); // Keep dropdown open while typing
                  }}
                  error={errors}
                  placeholder={t("actions.search")}
                />
                <StyledArrow src={search} alt="search" isOpen={isOpen} />
              </>
            )}
          />
        </InputWrapper>
        <OptionsContainer isOpen={isOpen} bgColor={bgColor}>
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <OptionItem key={index} onClick={() => handleSelect(option)}>
                {option.flagSrc && (
                  <img
                    src={option.flagSrc}
                    width={24}
                    height={24}
                    className="me-2"
                    alt="option-flag"
                  />
                )}
                {option.label}
              </OptionItem>
            ))
          ) : (
            <OptionItem>No options found</OptionItem> // Handle no options case
          )}
        </OptionsContainer>
      </SelectContainer>
      {errors && (
        <ErrorMessage>
          {errors.message || "This field is required"}
        </ErrorMessage>
      )}
    </div>
  );
};
