import Heading from "@components/font/heading";
import { Icon } from "@iconify/react";
import { theme } from "@styles/themes";
import { SwipeableListItem, Type } from "react-swipeable-list";
import { SwipeableList } from "react-swipeable-list";
import React from "react";
import { SwipeAction } from "react-swipeable-list";
import { TrailingActions } from "react-swipeable-list";
import styled from "styled-components";
import { useCleanNotification } from "@api/useCleanAllNotifications";

const Card = styled.div<{ isToday: boolean }>`
  background-color: ${({ isToday }) =>
    isToday ? theme.colors.grey.darkActive : theme.colors.grey.darker};
  border: ${({ isToday }) =>
    `1px solid ${
      isToday ? theme.colors.grey.darkActive : theme.colors.grey.normal
    }`};
  border-radius: 8px;
  padding: 16px;
  width: 100%;
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  margin-right: 10px;
  background-color: ${theme.colors.main.normal};
  border-radius: 50%;
`;

interface NotificationCardProps {
  title?: string;
  date?: string;
  content?: string;
  isIncidence?: string;
  notifyId?: string;
}

const LeftActions = ({ onDelete }) => (
  <TrailingActions>
    <SwipeAction destructive={true} onClick={onDelete}>
      <div
        className="d-flex align-items-center"
        style={{ backgroundColor: theme.colors.red.lightActive }}
      >
        <Icon
          style={{
            fontSize: "24px",
            color: theme.colors.grey.darker,
            margin: "0 18px",
          }}
          icon="mingcute:delete-2-line"
        />
      </div>
    </SwipeAction>
  </TrailingActions>
);
const NotificationCard: React.FC<NotificationCardProps> = ({
  title,
  date,
  content,
  isIncidence,
  notifyId,
}) => {
  const isContentLong = content?.split(" ")?.length > 10;
  const { mutate } = useCleanNotification();
  const handleDelete = (notifyId: string) => {
    mutate(notifyId)
  };
  const today = new Date();
  const formattedDate = today.toLocaleDateString("en-GB");
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const formattedYesterday = yesterday.toLocaleDateString("en-GB");
  return (
    <SwipeableList
      fullSwipe={false}
      type={Type.ANDROID}
      style={{ margin: "10px 0", borderRadius: "8px" }}
    >
      <SwipeableListItem
        listType={Type.IOS}
        fullSwipe={false}
        trailingActions={
          <LeftActions onDelete={() => handleDelete(notifyId)} />
        }
      >
        <Card isToday={date == formattedDate ? true : false}>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <Dot />
              <Heading
                title={`${title} ${isIncidence ? "⚠️" : ""}`}
                fontSize="14px"
                fontWeight="700"
                color={theme.colors.white.normal}
              />
            </div>
            <Heading
              title={
                date === formattedDate
                  ? "Today"
                  : date === formattedYesterday
                  ? "Yesterday"
                  : date
              }
              fontSize="12px"
              fontWeight="400"
              color={theme.colors.white.dark}
            />
          </div>
          <div>
            <StyledHeading
              title={content}
              fontSize="14px"
              fontWeight="400"
              color={theme.colors.white.dark}
            />
            {isContentLong && (
              <Heading
                title="See more"
                fontSize="12px"
                fontWeight="400"
                color={theme.colors.white.dark}
                style={{
                  textDecoration: "underline",
                }}
              />
            )}
          </div>
        </Card>
      </SwipeableListItem>
    </SwipeableList>
  );
};

const StyledHeading = styled(Heading)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  line-height: 19px;
`;

export default NotificationCard;
