import Heading from "@components/font/heading";
import { usePayment } from "@context/PaymentContext";
import { TabContext } from "@context/TabContext";
import { Icon } from "@iconify/react";
import { theme } from "@styles/themes";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

const BillingDetailHeader = () => {
  const navigate = useNavigate();
  const tabContext = useContext(TabContext);
  const { activeTab, setActiveTab } = tabContext;
  const { resetPayment } = usePayment();
  const handleNavigate = (tab: any) => {
    if (tab === 0) {
      navigate(-1);
    } else if (tab === 1) {
      setActiveTab(0);
    } else {
      resetPayment();
      setActiveTab(1);
    }
  };

  const handleCross = () => {
    navigate("/payments");
    setActiveTab(0);
  };

  return (
    <div>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{
          borderBottom: "0.8px solid",
          borderBottomColor: theme.colors.grey.dark,
          padding: "14px  20px 14px 20px",
        }}
      >
        <div className="d-flex align-items-center">
          <Icon
            className="me-3"
            icon="solar:arrow-left-outline"
            width="24"
            height="24"
            color={theme.colors.white.normal}
            onClick={() => handleNavigate(activeTab)}
          />
          <Heading
            fontSize="20px"
            title={
              activeTab == 0
                ? "Billing details"
                : activeTab == 1
                ? "Payment method"
                : "Summary"
            }
            fontWeight="700"
            color={theme.colors.white.normal}
          />
        </div>
        <div className="d-flex align-items-center">
          <Icon
            onClick={handleCross}
            icon="charm:cross"
            width="24"
            height="24"
            color={theme.colors.white.normal}
          />
        </div>
      </div>
    </div>
  );
};

export default BillingDetailHeader;
