import React from 'react'
import BillingDetailsAddSection from '../../../section/profileSection/billingDetailsAdd'

const BillingDetailsAdd = () => {
  return (
      <BillingDetailsAddSection/>
  )
}

export default BillingDetailsAdd
