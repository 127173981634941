import React from 'react'
import styled from 'styled-components';
import Head from '../myDataSection/head';
import BillingAddressForm from './form';


export const StyledDiv = styled.div`
  margin: 24px 12px 24px 24px;

  @media (max-width: 768px) {
    margin-left: 0; /* Remove left margin on mobile */
    margin-right: 0; /* Remove right margin on mobile */
  }
`;

 const BillingDetailsSection = () => {
  return (
    <StyledDiv>
      <Head title='profile.billingDetails'/>
      <BillingAddressForm/>
    </StyledDiv>
  )
}

export default BillingDetailsSection