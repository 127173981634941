import "./translations/i18n";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";

// libraries
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";
import { Tooltip } from "react-tooltip";

// components
import { MainRouter } from "@navigation/MainRouter";

// misc
import { theme } from "./styles/themes";
import { AuthProvider } from "@context/AuthContext";
import { SidebarProvider } from "@context/SidebarContext";
import { TabProvider } from "@context/TabContext";
import { PaymentProvider } from "@context/PaymentContext";
import { SettingsProvider } from "@context/SettingsContext";
import { ToastProvider } from "@context/ToastContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 15 * 60 * 1000, // Consider data stale after 5 minutes
      cacheTime: 5 * 60 * 60 * 1000, // Keep data in cache for 30 minutes
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
    },
  },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <TabProvider>
        <ToastProvider>
        <ThemeProvider theme={theme}>
          <SidebarProvider>
            <AuthProvider>
              <PaymentProvider>
                <SettingsProvider>
                  <MainRouter />
                  <ToastContainer />
                  <Tooltip id="my-tooltip" />
                </SettingsProvider>
              </PaymentProvider>
            </AuthProvider>
          </SidebarProvider>
        </ThemeProvider>
        </ToastProvider>

      </TabProvider>
    </QueryClientProvider>
  );
};

export default App;
