import Heading from "@components/font/heading";
import { theme } from "@styles/themes";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  border: 1px solid ${theme.colors.grey.normal};
  border-radius: 8px;
  padding: 16px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const BetsChartDetail = () => {
  return (
    <Container>
      <Row>
        <Heading
          title="profile.totalBets"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
        <Heading
          title="2"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
      </Row>
      <Row>
        <Heading
          title="profile.winRate"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
        <Heading
          title="2"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
      </Row>
      <Row>
        <Heading
          title="profile.averageOdds"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
        <Heading
          title="2"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
      </Row>
      <Row>
        <Heading
          title="profile.profit"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
        <Heading
          title="2"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
      </Row>
      <Row>
        <Heading
          title="profile.roi"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
        <Heading
          title="2"
          fontSize="14px"
          fontWeight="400"
          color={theme.colors.white.normal}
        />
      </Row>
    </Container>
  );
};

export default BetsChartDetail; 