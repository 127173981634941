import React from "react";
import Head from "./head";
import FormMyData from "./form";
import ForgotPasswordForm from "./forgotPasswordForm";
import styled from "styled-components";
const StyledDiv = styled.div`
  margin: 24px 12px 24px 24px;

  @media (max-width: 768px) {
    margin-left: 0; /* Remove left margin on mobile */
    margin-right: 0; /* Remove right margin on mobile */
  }
`;

const MyDataSection = () => {
  return (
    <StyledDiv >
      <Head title='profile.myData'/>
      <FormMyData/>
      <ForgotPasswordForm/>
    </StyledDiv>
  );
};

export default MyDataSection;
